import React from "react";
import { Link } from "react-router-dom";

function Help() {
  return (
    <div className="card mb-3 borderforcard paddingAboutMsbte">
      <div className="card-header backgroundColorCardHeader">
        <h1 className="fontForHeader custom-translate">HELP</h1>
      </div>
      <div className="row help table-responsive">
        <div className="card-body p-5">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th width="50%">Feedback/Suggestion</th>
                <th>Contact Us</th>
              </tr>
              <tr>
                <td>
                  You can use the
                  <u className="mx-1">
                    <strong>
                      <Link to={{ pathname: "/feedback" }}>Feedback form</Link>
                    </strong>
                  </u>
                  to submit your comments, feedback, suggestions and ideas for
                  improvements to MSBTE.
                </td>
                <td>
                  If you can't find the answer to your question here,&nbsp;
                  <u className="mx-1">
                    <strong>
                      <Link to={{ pathname: "/contactUs" }}>Contact us</Link>
                    </strong>
                  </u>
                  and we will endeavour to answer it for you.
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th width="50%">Report Technical issues/difficulties</th>
                <th>Helpful links</th>
              </tr>
              <tr>
                <td>
                  Use
                  <u className="mx-1">
                    <strong>
                      <a href="feedback">Feedback form</a>
                    </strong>
                  </u>
                  to report technical issues/difficulties experienced while
                  browsing/navigating the MSBTE website like broken
                  links/images, performance & accessibility issues etc.. as well
                  as problem with the content such as outdated OR incorrect
                  information etc..
                </td>
                <td>
                  The links within the bottom area of the footer of all the
                  pages will take you to Disclaimer,Privacy Policy,Accessibility
                  Statement,Terms and Conditions as well as Help page details.
                  Options to make the text larger OR smaller is available on all
                  the pages of our site in the top right hand corner.
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th width="50%">Site Map</th>
                <th>Navigation</th>
              </tr>
              <tr>
                <td>
                  You can visit
                  <u className="mx-1">
                    <strong>
                      <Link to={{ pathname: "/sitemap" }}>Site Map</Link>
                    </strong>
                  </u>
                  page to get an overall view of the contents of this site. You
                  can also navigate around the site by clicking on the Site Map
                  links.
                </td>
                <td>
                  The option on left and right hand navigation bar takes you to
                  a different area of our site.
                </td>
              </tr>
            </tbody>
          </table>

          <table className="table table-bordered">
            <tbody>
              <tr>
                <th>Viewing Information in Various File Formats</th>
              </tr>
              <tr>
                <td>
                  The information provided by this Web site is available in
                  various file formats The information provided by this Web site
                  is available in various file formats, such as Portable
                  Document Format (PDF), Word, Excel and PowerPoint. To view the
                  information properly, your browser needs to have the required
                  plug-ins or software. The table lists the required plug-ins
                  needed to view the information in various file formats.
                </td>
              </tr>
            </tbody>
          </table>

          <table className="table-bordered table">
            <tbody>
              <tr>
                <th>Document Type</th>
                <th>Plug-in for Download</th>
              </tr>
              <tr>
                <td>Portable Document Format (PDF) files</td>
                <td>
                  <a
                    href="javascript:void(0);"
                    target="_blank"
                    rel="noreferrer"
                    className="pe-1"
                  >
                    Adobe Acrobat Reader
                  </a>
                </td>
              </tr>
              <tr>
                <td>Word files</td>
                <td>
                  <a
                    href="javascript:void(0);"
                    target="_blank"
                    className="pe-1"
                  >
                    Word Viewer (in any version till 2003)
                  </a>
                  Microsoft Office Compatibility Pack for Word (for 2007
                  version)
                </td>
              </tr>
              <tr>
                <td>Excel files</td>
                <td>
                  <a
                    href="javascript:void(0);"
                    target="_blank"
                    rel="noreferrer"
                    className="pe-1"
                  >
                    Excel Viewer 2003 (in any version till 2003)
                  </a>
                  Microsoft Office Compatibility Pack for Excel (for 2007
                  version)
                </td>
              </tr>
              <tr>
                <td>PowerPoint presentations</td>
                <td>
                  <a
                    href="javascript:void(0);"
                    target="_blank"
                    rel="noreferrer"
                    className="pe-1"
                  >
                    PowerPoint Viewer 2003 (in any version till 2003)
                  </a>
                  Microsoft Office Compatibility Pack for PowerPoint (for 2007
                  version)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Help;
