import React from "react";

function Vision() {
  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-sm-6">
          <div className="card mb-3  borderforcard paddingAboutMsbte">
            <div className="card-header backgroundColorCardHeader">
              <h1 className="fontForHeader custom-translate">VISION</h1>
            </div>
            <div className="row g-0">
              <div className="card-body removePadding">
                <p className="p-2 fontp custom-translate">
                  To ensure that the Diploma level Technical Education
                  constantly matches the latest requirements of Technology and
                  industry and includes the all-round personal development of
                  students including social concerns and to become globally
                  competitive, technology led organization.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-sm-6">
          <div className="card mb-3  borderforcard paddingAboutMsbte">
            <div className="card-header backgroundColorCardHeader">
              <h1 className="fontForHeader custom-translate">MISSION</h1>
            </div>
            <div className="row g-0">
              <div className="card-body removePadding">
                <p className="p-2 fontp custom-translate">
                  To provide high quality technical and managerial manpower,
                  information and consultancy services to the industry and
                  community to enable the industry and community to face the
                  challenging technological & environmental challenges.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Vision;
