/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import API from "../API";
import { ShowContext } from "../App";
import { de } from "../utils/Helper";

const footer = {
  title: "Footer",
  menu_url: "",
  children: [
    { title: "Disclaimer", menu_url: "/disclaimer" },
    { title: "Policies", menu_url: "/policie" },
    {
      title: "Terms & Conditions",
      menu_url: "/terms-and-condition",
    },
    {
      title: "Accessibility Statement",
      menu_url: "/accessibility-statement",
    },
    { title: "Grievance Redressal ", menu_url: "/grievance" },
    { title: "Feedback", menu_url: "/feedback" },
    { title: "FAQ ", menu_url: "/Faq" },
    { title: "Help ", menu_url: "/help" },
    { title: "Site map", menu_url: "/sitemap" },
  ],
};

function SiteMap() {
  const { setShow, setMsg } = useContext(ShowContext);
  const [list, setList] = useState([]);

  useEffect(() => {
    getMenu(setList, setShow, setMsg);
  }, []);

  function MenuItem({ item }) {
    return item.children && item.children.length > 0 ? (
      <li className="pl-2">
        <span className="font-16">{item.title}</span>
        <ul className="" aria-labelledby="">
          {item.children.map((rec) => (
            <li>
              <a
                className="link-primary font-12 custom-translate"
                href={rec.menu_url || "#"}
                target={
                  rec.menu_url &&
                  (rec.menu_url.includes("https://") ||
                    rec.menu_url.includes("http://"))
                    ? "_blank"
                    : "_self"
                }
                rel="noreferrer"
              >
                {rec.title}
              </a>
              {rec.children && rec.children.length > 0 && (
                <ul className="pl-2">
                  {rec.children.map((i) => (
                    <li>
                      <a
                        className="link-primary custom-translate"
                        href={i.menu_url}
                        target={
                          i.menu_url &&
                          (i.menu_url.includes("https://") ||
                            i.menu_url.includes("http://"))
                            ? "_blank"
                            : "_self"
                        }
                        rel="noreferrer"
                      >
                        {i.title}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </li>
    ) : (
      <li className="">
        <a
          className="link-primary custom-translate"
          href={item.menu_url}
          target={
            item.menu_url &&
            (item.menu_url.includes("https://") ||
              item.menu_url.includes("http://"))
              ? "_blank"
              : "_self"
          }
          rel="noreferrer"
        >
          {item.title}
        </a>
      </li>
    );
  }
  return (
    <>
      <div className="card mb-3  borderforcard paddingAboutMsbte">
        <div className="card-header backgroundColorCardHeader">
          <h1 className="fontForHeader custom-translate">Site Map</h1>
        </div>
        <div className="row g-0">
          <div className="card-body removePadding">
            <div className="px-2 mx-3">
              {list &&
                list.length > 0 &&
                list.map((item, index) => (
                  <MenuItem
                    item={item}
                    last={index === list.length}
                    key={index}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function getMenu(setList, setShow, setMsg) {
  API.get("homemenu")
    .then((res) => {
      if (res.data.status === "success") {
        const arrayToTree = (arr, parent_id = 0) =>
          arr
            .filter((item) => item.parent_id === parent_id)
            .map((child) => ({
              ...child,
              children: arrayToTree(arr, child.id),
            }));
        let list = arrayToTree(JSON.parse(de(res.data.data)), 0);
        setList([...list, footer]);
      }
    })
    .catch((error) => {
      console.log(error);

      setShow(true);
      setMsg(error.message);
    });
}

export default SiteMap;
