import React, { useContext, useEffect, useState } from "react";
import API from "../API";
import { ShowContext } from "../App";
// import Carousel from "react-multi-carousel";
import EventCorousel from "../Layout/EventCorousel";
import { de } from "../utils/Helper";
import VideoSlider from "./VideoSlider";

function MediaCorner() {
  const { setShow, setMsg } = useContext(ShowContext);
  const [videos, setVideos] = useState();

  useEffect(() => {
    getVideos(setVideos, setShow, setMsg);
  }, []);

  return (
    <>
      <div class="row">
        <div className="col-sm-6">
          <div id="im-slider">
            <EventCorousel />
          </div>
        </div>

        {videos && videos.length > 0 && (
          <div className="col-sm-6">
            <div className="row">
              <VideoSlider videoList={videos} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function getVideos(setList, setShow, setMsg) {
  API.get("EventVideos")
    .then((res) => {
      if (res.data.status === "success") {
        setList(JSON.parse(de(res.data.data)));
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}

export default MediaCorner;
