import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import API from "../API";
import { ShowContext } from "../App";
import { de } from "../utils/Helper";

const TabBox = () => {
  const [activeTab, setActiveTab] = useState(0);

  const { setShow, setMsg } = useContext(ShowContext);
  const [circulars, setCirculars] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState("");

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // Fetch data once on component mount
  useEffect(() => {
    getCirculars(setCirculars, setShow, setMsg);
  }, []);

  // Define the filter criteria for each tab
  const filterCriteria = [
    (circular) => circular.category === 1 || circular.category === 0, // Circular/Office Order
    (circular) => circular.category === 2, // Extra Curricular Activities
    (circular) => circular.category === 3, // Training
    (circular) => circular.category === 4, // GR
    (circular) => circular.category === 6, // Downloads
  ];

  // Filter circulars based on active tab
  const filteredCirculars = circulars.filter(filterCriteria[activeTab]);

  return (
    <>
      <div className="card">
        <div className="card-header text-left">
          <div className="tab-list">
            {[
              "Circular/Office Order",
              "Extra Curricular Activities",
              "Training",
              "GR",
              "Downloads",
            ].map((label, index) => (
              <button
                key={index}
                className={activeTab === index ? "active" : ""}
                onClick={() => setActiveTab(index)}
              >
                <span className="custom-translate">{label}</span>
              </button>
            ))}
          </div>
        </div>
        <div className="card-body">
          <div className="tab-content">
            <div className="overflow-auto heighttableDiv2">
              <table className="table table-borderless circulars border border-light">
                <thead>
                  <tr className="text-center">
                    <th className="custom-translate">Sr.No</th>
                    <th className="custom-translate">Date</th>
                    <th className="custom-translate">News</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCirculars.length > 0 ? (
                    filteredCirculars.map((circular, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td>
                          <Moment format="DD/MM/YY">{circular.fromDate}</Moment>
                        </td>
                        <td colSpan="4">
                          <Link
                            aria-disabled="true"
                            onClick={() => {
                              handleShow();
                              setFile(circular.url);
                            }}
                            className="custom-translate"
                          >
                            {circular.heading}
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        contentClassName="modal-pdf-content"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <iframe title="myFrame" className="w-100 h-100" src={file}></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

// Fetch circulars data from API
async function getCirculars(setCirculars, setShow, setMsg) {
  API.get("circulars")
    .then((res) => {
      if (res.data.status === "success") {
        setCirculars(JSON.parse(de(res.data.data)));
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}

export default TabBox;
