import React, { useRef, useState } from "react";
import { Carousel } from "react-bootstrap";
import ReactPlayer from "react-player";

const VideoSlider = (props) => {
  const { videoList } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [playingIndex, setPlayingIndex] = useState(-1);
  const playerRefs = useRef([]);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
    stopCurrentVideo();
  };

  const togglePlayPause = (index) => {
    if (playingIndex === index) {
      // If the clicked video is already playing, pause it
      setPlayingIndex(-1);
      if (playerRefs.current[index]) {
        playerRefs.current[index].getInternalPlayer().pause();
      }
    } else {
      // If a different video is clicked, play it
      setPlayingIndex(index);
      if (playerRefs.current[index]) {
        playerRefs.current[index].getInternalPlayer().play();
      }
    }
  };

  const stopCurrentVideo = () => {
    setPlayingIndex(-1);
    playerRefs.current.forEach((player) => {
      if (player) {
        player.getInternalPlayer().pause();
      }
    });
  };

  return (
    <Carousel
      interval={playingIndex === -1 ? 5000 : null}
      activeIndex={activeIndex}
      onSelect={handleSelect}
      controls={true}
      className="customCarousal"
    >
      {videoList.map((vidObj, index) => (
        <Carousel.Item key={vidObj.id}>
          <ReactPlayer
            ref={(el) => (playerRefs.current[index] = el)}
            url={vidObj.url}
            pip={true}
            width="100%"
            controls={false}
            className="react-player"
            playing={playingIndex === index}
            style={{
              margin: "auto",
              objectFit: "cover",
              height: "100%!important",
            }}
            onPlay={() => {
              setPlayingIndex(index);
              console.log("Video Play");
            }}
            onPause={() => {
              if (playingIndex === index) {
                setPlayingIndex(-1);
              }
              console.log("Video Pause");
            }}
          />
          <div className="text-center py-3">
            <button
              className="btn btn-dark px-5 playBtn"
              onClick={() => togglePlayPause(index)}
            >
              <i
                className={`fa fa-${playingIndex === index ? "pause" : "play"}`}
              ></i>
              &nbsp; {playingIndex === index ? "Pause" : "Play"}
            </button>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default VideoSlider;
