import React, { useState } from "react";
import Circulars from "./Circulars";
import DignitoryPhotos from "./DignitoryPhotos";
import EventCorousel from "./EventCorousel";
import TabBox from "./TabBox";

function Main() {
  const textBeforeReadMore = `Maharashtra State Board of Technical Education (MSBTE) is an autonomous Board of Government of Maharashtra mandated to regulate matters pertaining to Diploma Level Technical education in the state. Curriculum development- it’s efficient implementation strategies through faculty development programs, student development initiatives, industry-institution interaction, Academic monitoring and various online evaluation activities have been the significant insignia of MSBTE.`;

  const textAfterReadMore = `In the recent past, MSBTE has made its foray into skill development activities, thus broadening its horizon and area of service. However, the revision of curricular to suit the essentially of industry as expected from diploma students had become a dire need and of late a very important parameter for employability which has been accomplished and implemented from the academic year 2017-18. Having been closely associated with the system and from the suggestions and feedback of the stakeholders, it shall be my endeavor to revamp the various processes and adopt better technological implementation & evaluation techniques for the benefit of stakeholder students.`;

  const [isReadMore, setIsReadMore] = useState(true);

  // Handle toggling between Read more and Read less
  const toggleReadMore = () => setIsReadMore(!isReadMore);

  const s3path = process.env.REACT_APP_S3_PATH;

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-lg-6 col-sm-12 my-2" id="widthfordiv">
          <div className="row">
            <EventCorousel />
          </div>
        </div>
        <div className="col-lg-6 col-sm-12">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-sm-12 m-auto mt-2 mb-1">
                <DignitoryPhotos />
              </div>

              <div className="col-lg-12 col-sm-12 directoreDesk RemoveAllpadding">
                <div className="mb-3 colornone borderforcard1">
                  <div className="card-header backgroundImpLink">
                    <h4 className="custom-translate">FROM DIRECTOR'S DESK</h4>
                  </div>

                  <div className="row g-0">
                    <div className="col-lg-4 col-sm-5">
                      <div className="Removerborder text-center mt-2">
                        <img
                          className="img-fluid"
                          src={`${s3path}/dignitaries/Photo_Shri_Pramod_A._Naik.png`}
                          alt="Shri Pramod A. Naik"
                          style={{ borderRadius: "50%", maxHeight: "150px" }}
                        />
                      </div>
                      <span className="names custom-translate">
                        Shri Pramod A. Naik
                      </span>
                      <span className="names3 mb-2 custom-translate">
                        Director MSBTE
                      </span>
                    </div>
                    <div className="col-lg-8 col-sm-7">
                      <p className="fontForDesk mt-2">
                        {/* Render the entire text */}
                        <span
                          className="custom-translate"
                          data-original-text={textBeforeReadMore}
                        >
                          {textBeforeReadMore}
                        </span>

                        {/* Initially hidden second part of the text */}
                        <span
                          className="custom-translate"
                          data-original-text={textAfterReadMore}
                          style={{ display: isReadMore ? "none" : "inline" }}
                        >
                          {textAfterReadMore}
                        </span>

                        {/* The Read more / Read less toggle button */}
                        <span
                          className="read-more-less custom-translate"
                          style={{
                            display: isReadMore ? "none" : "inline",
                            color: "#faaf3b",
                            borderRadius: "5px",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                          onClick={toggleReadMore}
                        >
                          Read Less
                        </span>
                        <span
                          className="read-more-less custom-translate"
                          style={{
                            display: isReadMore ? "inline" : "none",
                            color: "#faaf3b",
                            borderRadius: "5px",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                          onClick={toggleReadMore}
                        >
                          Read More
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Other components */}
            </div>
          </div>
        </div>

        <div className="table-section post">
          <div className="row">
            <div className="col-lg-8 col-sm-12 mb-3">
              <TabBox />
            </div>
            <div className="col-lg-4 col-sm-12 mb-3">
              <Circulars />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
