import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import headerFooterTranslations from "./Translation/headerFooterTrans";
import homePageTranslations from "./Translation/homePageTranslation";
import otherPageTranslations from "./Translation/otherTranslation";

const s3Path = process.env.REACT_APP_S3_PATH;

function ToggleMarathi() {
  const [isCustomTranslationActive, setCustomTranslationActive] =
    useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    captureOriginalTexts();
    if (isCustomTranslationActive) {
      applyCustomTranslation();
    }
  }, [location.pathname]);

  useEffect(() => {
    window.onload = () => {
      console.log("Page is fully loaded");
      setIsPageLoaded(true);
      captureOriginalTexts();
    };
  }, []);

  const captureOriginalTexts = () => {
    const customTranslateElements =
      document.querySelectorAll(".custom-translate");
    if (customTranslateElements.length === 0) {
      return;
    }

    customTranslateElements.forEach((element) => {
      if (!element.getAttribute("data-original-text")) {
        element.setAttribute("data-original-text", element.textContent.trim());
        // console.log("Captured original text:", element.textContent.trim());
      }
    });
  };

  const applyCustomTranslation = () => {
    const customTranslateElements =
      document.querySelectorAll(".custom-translate");
    const currentTranslations = getPageTranslations(); // Get the translations for the current page

    customTranslateElements.forEach((element) => {
      const originalText = element.getAttribute("data-original-text");
      const customTranslation =
        currentTranslations[originalText] ||
        headerFooterTranslations[originalText];

      if (customTranslation) {
        element.textContent = customTranslation;
      }
    });

    renameImagesForTranslation(true);
  };

  const getPageTranslations = () => {
    // Determine which translations to apply based on the current page URL
    if (location.pathname === "/") {
      return homePageTranslations; // Apply home page translations
    }
    return otherPageTranslations; // Apply other pages' translations
  };

  const revertToOriginalText = () => {
    const customTranslateElements =
      document.querySelectorAll(".custom-translate");
    customTranslateElements.forEach((element) => {
      const originalText = element.getAttribute("data-original-text");
      if (originalText && element.textContent !== originalText) {
        element.textContent = originalText; // Revert to original text
      }
    });

    renameImagesForTranslation(false);
  };

  const renameImagesForTranslation = (isTranslated) => {
    const image1 = document.querySelector("#image1");
    const image2 = document.querySelector("#image2");

    if (isTranslated) {
      if (image1) image1.src = s3Path + "/Images1/ORG_Chart_MR.png";
      if (image2) image2.src = s3Path + "/Images1/Functioning_of_MSBTE_MR.png";
    } else {
      if (image1) image1.src = s3Path + "/Images1/ORG_Chart.png";
      if (image2) image2.src = s3Path + "/Images1/Functioning_of_MSBTE.png";
    }
  };

  const toggleCustomTranslation = () => {
    setCustomTranslationActive((prevState) => {
      if (!prevState) {
        applyCustomTranslation();
      } else {
        revertToOriginalText();
      }
      return !prevState;
    });
  };

  return (
    <div className="mrTranslator">
      <span
        style={{
          padding: "2px 4px 5px",
          borderRadius: "4px",
          border: "1px solid #ccc",
          backgroundColor: isPageLoaded ? "var(--background-color)" : "gray",
          cursor: isPageLoaded ? "pointer" : "not-allowed",
          opacity: isPageLoaded ? 1 : 0.5,
        }}
        onClick={isPageLoaded ? toggleCustomTranslation : null}
        title="Custom Translation"
      >
        <img
          src={s3Path + "/Images1/toggle.jpg"}
          alt="Translate"
          height="18px"
        />
      </span>
    </div>
  );
}

export default ToggleMarathi;
