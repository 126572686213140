import React from "react";

function MumbaiRegion() {
  return (
    <div>
      <div className="card mb-3  borderforcard paddingAboutMsbte">
        <div className="card-header backgroundColorCardHeader linearimg">
          <h1 className="fontForHeader custom-translate">Mumbai Region</h1>
        </div>
        <br />
        <div className="table-responsive">
          <table className="table table-bordered region-table">
            <thead className="thead-dark">
              <tr>
                <th className="custom-translate">Sr. No.</th>
                <th className="custom-translate">Name</th>
                <th className="custom-translate">Designation</th>
                <th className="custom-translate">E-mail</th>
                {/* <th className="custom-translate">Officers Image</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td className="custom-translate">Mr. Pramod U Wayse </td>
                <td className="custom-translate">Dy. Secretary (T) </td>
                <td>
                  <a href="mailto:puwayse@msbte.com">puwayse@msbte.com</a>
                </td>
                {/* <td>
                  <img
                    src="/assets/Images1/Puwayse.png"
                    alt="Mr. Pramod U Wayse"
                  />
                </td> */}
              </tr>
              <tr>
                <td>2</td>
                <td className="custom-translate">
                  Shri. Namdev Vijayakumar Matolkar
                </td>
                <td className="custom-translate">Assistant Secretary (T)</td>
                <td>
                  <a href="mailto:nvmatolkar@msbte.com">nvmatolkar@msbte.com</a>
                </td>

                {/* <td>
                  <img
                    src="/assets/Images1/Matolkar.png"
                    alt="Mr. N. V. Matolkar"
                  />
                </td> */}
              </tr>
              <tr>
                <td>3</td>
                <td className="custom-translate">Mrs. Nisha Rupaji Vartha</td>
                <td className="custom-translate">System Analyst</td>
                <td>
                  <a href="mailto:nrwartha@msbte.com">nrwartha@msbte.com</a>
                </td>

                {/* <td>
                  <img
                    src="/assets/Images1/nishaw.jpg"
                    alt="Mrs. Nisha R. Wartha"
                  />
                </td> */}
              </tr>
              <tr>
                <td>4</td>
                <td className="custom-translate">
                  Shri. Shailesh Madhukar Gaikwad
                </td>
                <td className="custom-translate">Assistant Secretary (NT) </td>
                <td>
                  <a href="mailto:smgaikwad@msbte.com">smgaikwad@msbte.com</a>
                </td>

                {/* <td>
                  <img
                    src="/assets/Images1/Gaikwad.png"
                    alt="Mr. S.M.Gaikwad"
                  />
                </td> */}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MumbaiRegion;
