function DirectorProfile() {
  return (
    <div className="card mb-3  borderforcard paddingAboutMsbte">
      <div className="card-header backgroundColorCardHeader">
        <h1 className="fontForHeader custom-translate">Director's Profile</h1>
      </div>
      <div className="row g-0">
        <div className="card-body removePadding">
          <img
            className="img-fluid float-start me-4"
            src="https://msbte-admin.s3.ap-south-1.amazonaws.com/Images1/director.png"
            alt="Shri Pramod A. Naik"
          />
          <h2> Shri Pramod A. Naik</h2>
          <p>
            Shri Pramod A. Naik has been selected as Director, Maharashtra State
            Board of Technical Education / Joint Director, Technical Education,
            M.S. through Maharashtra Public Service Commission and has been
            appointed as Director, Maharashtra State Board of Technical
            Education by Government of Maharashtra. He has taken charge of
            Director of Maharashtra State Board of Technical Education, w.e.f.
            28th November 2017.
            <br /> He is an engineering graduate with excellent academic record
            and is a recipient of National Merit Scholarship & State
            Scholarships. His academic credentials include Bachelor’s degree in
            Civil Engineering from VRCE Nagpur, Master’s degree in Civil
            Engineering from MS University Baroda, Doctoral Degree in Civil
            Engineering from Sant Gadge Baba Amravati University and Master's
            Diploma in Business Administration from Symbiosis Institute of
            Management Studies, Pune.
            <br /> He is working in the field of Technical Education for the
            last 17 years in various capacities: as an administrator to a policy
            maker. He had been working as Secretary of Maharashtra State Board
            of Technical Education since last 5 years. He is credited to have
            worked as a Deputy Director of Technical Education. He was initially
            appointed as an Assistant Director (Technical) of Technical
            Education in 2000 through Maharashtra Public Service Commission.
            Prior to joining these Government Services, he has served as an
            Assistant Transportation Engineer in City and Industrial Development
            Corporation of Maharashtra Limited (CIDCO), Navi Mumbai for a
            decade.
            <br /> He was instrumental in the success of “Canada India Institute
            Industry Linkage Project (CIIILP)" and World Bank assisted
            “Technical Education Quality Improvement Program” Phase II
            (TEQIP-II) for the State of Maharashtra.
            <br /> He is a member of Indian Roads Congress, Indian Society for
            Technical Education (ISTE) and Institution of Engineers (India). He
            has also represented various statutory bodies/organizations as a
            member. <br />
            He has published more than 35 research papers in
            International/National Journals. He is recognized as Ph.D.
            Supervisor by Sant Gadge Baba Amravati University, Rashtrasant
            Tukdoji Maharaj Nagpur University & Veermata Jijabai Technological
            Institute (VJTI) Mumbai and as a guide for Ph.D. students in the
            field of Civil Engineering. Four students have been awarded Ph.D.
            under his able guidance. Three patents are registered in his name.
            <br />
            He has been awarded with “Sir C. V. Raman Memorial Award” for worthy
            contribution to society in the field of Science & Technology by
            Science and Kainat Society of India in 2012.
          </p>
        </div>
      </div>
    </div>
    // <div className="card-body removePadding p-5">
    //   <div className="row g-0">
    //     <div className="card-body">

    //     </div>
    //   </div>
    // </div>
  );
}

export default DirectorProfile;
