import React, { useState } from "react";

import { Accordion, Modal } from "react-bootstrap";

function Faq() {
  const s3Path = process.env.REACT_APP_S3_PATH;

  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState("");
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const faqData = [
    {
      question:
        "I have to apply for Duplicate Copies of Mark sheet/s and/or Diploma Certificate and/or verification of mark sheet/s, Diploma Certificate, How to apply?",
      answer: (
        <>
          Visit Link:
          <span
            className="linkcolor paddinfForClick"
            onClick={() => {
              handleShow();
              setFile(s3Path + "/files/Authentication_Verification.pdf");
            }}
          >
            http://msbte.ac.in (English) (36 kb) pdf where the prescribed form
            and fees and documents required are given.
          </span>
        </>
      ),
    },
    {
      question: "Where can I get the copy of examination regulations?",
      answer: (
        <>
          Visit Link:
          <span
            className="linkcolor paddinfForClick"
            onClick={() => {
              handleShow();
              setFile(
                s3Path + "/files/MSBTEExamRegulations2018_050920181614.pdf"
              );
            }}
          >
            Open PDF Link
          </span>
        </>
      ),
    },
    {
      question:
        "Status of my result is RHR/+RHR, what should I do to get the result?",
      answer: (
        <>
          <p>Contact your Institute. Your result status is as above either:</p>
          <ul className="mt-2">
            <li>
              For want of some information regarding your enrolment no., seat
              no., marks of continuous assessment, practical or term work marks
              or marks in theory paper/s
            </li>
            <li>Or there may be some enquiry pending against you.</li>
            <li>Or you may not be eligible to appear for examination.</li>
          </ul>
        </>
      ),
    },
    {
      question:
        "Status of my result is WFLS/WFLY, what should I do to get the result?",
      answer: (
        <>
          It means you are passed in the final semester/year, but your lower
          semester/s or year/s may be pending. If you have passed all previous
          semesters/years, contact the Institute with mark sheets and submit an
          application on plain paper to declare your result of the final
          semester/s or year/s (as applicable).
        </>
      ),
    },
    {
      question: "I have passed Diploma, when can I get Diploma Certificate?",
      answer: (
        <>
          Diploma Certificates are issued after the completion of the
          reassessment process. The information of the students whose
          certificates are issued to the Institute through Regional Offices is
          displayed on the website. Until then, provisional certificates are
          valid.
        </>
      ),
    },
    {
      question: "I want a photocopy of my answer books, how can I get it?",
      answer: (
        <>
          After the result is declared, the schedule for applying for obtaining
          a photocopy of the assessed answer book/s is declared on the website.
          You have to apply within the schedule only. Online application process
          has been developed. You can obtain photocopies of a maximum of two
          theory subjects only.
        </>
      ),
    },
    {
      question: "How can I apply for reassessment?",
      answer: (
        <>
          After the result is declared, the schedule for applying for
          reassessment is displayed on the website. You have to apply within the
          schedule only. You can apply for reassessment of a maximum of two
          theory subjects only of which photocopies have been obtained.
        </>
      ),
    },
    {
      question:
        "Where can I get the notification regarding procedure and fees for obtaining PHOTOCOPY of answer books and applying for REASSESSMENT?",
      answer: (
        <>
          Visit Website:&nbsp;
          <a
            className="linkcolor"
            href=" https://online.msbte.co.in/msbte23/index.php"
          >
            Open Link
          </a>
        </>
      ),
    },
    {
      question: "What name shall be printed on my mark sheet and Certificate?",
      answer: (
        <>
          Your exact name as it appears on your SSC certificate shall exactly
          appear on your mark sheet and Certificate.
        </>
      ),
    },
    {
      question:
        "I am married; I want to change my name in mark sheet and certificate?",
      answer: (
        <>
          If you were married after the last examination of the Diploma, no name
          change shall be made. If you were married during your studies and
          apply immediately, the new name shall appear on the backside of mark
          sheets and certificates duly signed by the authority.
        </>
      ),
    },
    {
      question:
        "My result status at first semester is “Fail”, am I eligible for admission to the second semester?",
      answer: (
        <>
          The eligible students studying in All ODD semesters i.e. First, Third,
          and fifth are allowed to continue in corresponding EVEN semesters i.e.
          second. Fourth and sixth provided they are eligible as per regulation
          RG4D irrespective of the status of result whether Passed, ATKT or
          Failed.
        </>
      ),
    },
    {
      question:
        "My result status at second semester is “Fail”, am I eligible for admission to Third semester?",
      answer: (
        <>
          The result status in the mark sheet of E schemes is independent of the
          status of that particular semester. Eligibility for the next odd
          semester i.e. Third here is dependent on the combined count of failure
          subjects in both semesters first and second. If you are failed in
          equal to or not more than the total allowable count of failure
          subjects together in BOTH semesters, you are eligible for admission to
          the Third semester.
        </>
      ),
    },
    {
      question:
        "My result status of Fourth semester is Fail Lsp? Am I eligible for admission to Fifth semester?",
      answer: (
        <>
          Lsp means your lower semester is pending, meaning you are failed in
          one or more subjects in either first or second or both semesters. You
          are then not eligible for admission for the fifth semester
          irrespective of your result status at the fourth semester as fail or
          pass or ATKT.
        </>
      ),
    },
    {
      question:
        "My result status at Sixth Semester i.e. final semester is WFLS/WFLY, Can I get the mark sheet?",
      answer: (
        <>
          WFLY/WFLS means your result of the final semester/year is withheld as
          you are not passed in either lower semester/s, year. You can see marks
          in the gazette of the Institute but cannot get the mark sheet.
        </>
      ),
    },
    {
      question:
        "My result status is RHR/WFLY/WFLS, can I apply for verification, photocopy, and reassessment?",
      answer: (
        <>
          Yes, normally marks are printed in the Institute’s gazette, referring
          to which you can certainly apply. You have to apply if you so desire
          because you shall not get any extension for the reason that you are
          not in receipt of your result/mark sheet.
        </>
      ),
    },
    {
      question: "What is the rule of ATKT?",
      answer: (
        <>
          If a student fails in not more than 1/3 (ONE THIRD) of subjects, their
          result status is ATKT. These subjects are passing heads, for which
          minimum passing marks are to be obtained.
        </>
      ),
    },
    {
      question:
        "I failed in Physics theory and practical too? Are these two subjects to count for ATKT?",
      answer: (
        <>
          No. One subject means either theory or practical or oral separately,
          if failure is only in one head, OR taken together if failure is in
          more than one head of the same subject.
        </>
      ),
    },
    {
      question:
        "What is the rule of awarding class for Diploma in engineering?",
      answer: (
        <>
          Normally, class for Diploma is awarded on the aggregate marks obtained
          at pre-final and final semester both taken together.
        </>
      ),
    },
    {
      question: "When can I apply for Improvement of class?",
      answer: (
        <>
          You can apply for Improvement of class only when you pass all
          semesters fully. You can apply and appear within one year i.e. in
          immediate next two examinations.
        </>
      ),
    },
  ];

  return (
    <>
      <div className="row ">
        <div className="col-sm-12">
          <div className="card RemoverBorderRadius">
            <div className="card-header backgroundColorCardHeader linearimg">
              <h1 className="fontForHeader custom-translate">
                FREQUENTLY ASKED QUESTION
              </h1>
            </div>
            <div className="card-body">
              <Accordion className="cAccordian">
                {faqData.map((item, index) => (
                  <Accordion.Item eventKey={String(index)} key={index}>
                    <Accordion.Header className="cHeader">
                      <b>{index + 1}.</b> {item.question}
                    </Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        contentClassName="modal-pdf-content"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <iframe title="myFrame" className="w-100 h-100" src={file}></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Faq;
