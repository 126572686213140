const OtherTranslation = {
  // Curriculum and Academic Activities
  "Design and develop the curriculum of Diploma, Post Diploma and Advanced Diploma Programmes..":
    "डिप्लोमा, पोस्ट डिप्लोमा आणि प्रगत डिप्लोमा प्रोग्रामच्या अभ्यासक्रमाची रचना आणि विकास करा..",
  "Grant affiliation to the institutes running MSBTE's programmes.":
    "MSBTE चे कार्यक्रम चालवणाऱ्या संस्थांना संलग्नता द्या.",
  "Conduct academic monitoring of the affiliated institutions, through Curriculum Implementation and Assessment Norms (CIAAN).":
    "अभ्यासक्रम अंमलबजावणी आणि मूल्यांकन मानदंड (CIAAN) द्वारे संलग्न संस्थांचे शैक्षणिक निरीक्षण करा.",
  "Organise Faculty Development Training Programs e.g. content updating trainings, soft skills trainings, industrial trainings and management trainings, hands on skills trainings for polytechnic teachers.":
    "कनिष्ठ तंत्रनिकेतन शिक्षकांसाठी सामग्री अद्ययावत प्रशिक्षण, सॉफ्ट स्किल्स प्रशिक्षण, औद्योगिक प्रशिक्षण आणि व्यवस्थापन प्रशिक्षण, तसेच हस्त-कौशल्य प्रशिक्षण यांसारखी शिक्षक विकास प्रशिक्षण कार्यक्रम आयोजित करा.",
  "Grant equivalence to the programmes offered by autonomous polytechnics & other organisations.":
    "स्वायत्त तंत्रनिकेतन आणि इतर संस्थांनी दिलेल्या कार्यक्रमांना समतुल्यता द्या.",
  "Conduct examinations, declare results and award certificates.":
    "परीक्षा आयोजित करा, निकाल जाहीर करा आणि प्रमाणपत्रे प्रदान करा.",
  "Felicitate the State level rank holders and award certificates of Merit.":
    "राज्य पातळीवरील क्रमांकधारकांचा सत्कार करा आणि गुणवत्तेची प्रमाणपत्रे प्रदान करा.",
  "Prescribe standards for curriculum, infrastructure and other resources.":
    "अभ्यासक्रम, पायाभूत सुविधा आणि इतर साधनांसाठी मानके निर्धारित करा.",
  "Generate question papers through Question Banks.":
    "प्रश्न बँकांद्वारे प्रश्नपत्रिका तयार करा.",
  "Design and develop curriculae of need based Diploma programmes.":
    "गरजेनुसार डिप्लोमा अभ्यासक्रम तयार करा आणि विकसित करा.",
  "Develop Lab Manuals, CAI Packages, Text books and other Learning resource packages for effective teaching learning process.":
    "प्रभावी शिक्षण प्रक्रियेसाठी लॅब मॅन्युअल्स, CAI पॅकेजेस, पाठ्यपुस्तके आणि इतर शिक्षण संसाधन पॅकेजेस तयार करा.",
  "Revise the curriculae of the existing programmes periodically on scientific basis by conducting search conferences, studying technological advancements, latest market requirements, job analysis etc.":
    "शोध परिषद, तंत्रज्ञानातील प्रगती, बाजारातील मागण्या, नोकरी विश्लेषण यांसारख्या वैज्ञानिक दृष्टिकोनातून विद्यमान कार्यक्रमांचा अभ्यासक्रम नियमितपणे पुनरावलोकन करा.",
  "Undertake controlled academic autonomy projects of the Polytechnics outside Maharashtra State.":
    "महाराष्ट्र राज्याबाहेरील तंत्रनिकेतनसाठी नियंत्रित शैक्षणिक स्वायत्तता प्रकल्प हाती घ्या.",
  "Organise Career Fairs to promote technical education especially in remote places.":
    "दूरवरच्या ठिकाणी तांत्रिक शिक्षणाला प्रोत्साहन देण्यासाठी करिअर फेअर आयोजित करा.",
  "Organise MSBTE State level technical competitions e.g. Students Paper Presentation, Technical Quiz etc.":
    "MSBTE राज्यस्तरीय तांत्रिक स्पर्धा जसे की विद्यार्थ्यांचे पेपर सादरीकरण, तांत्रिक प्रश्नमंजूषा इत्यादींचे आयोजन करा.",
  "Issue Scholarships to the needy and meritorious students.":
    "गरजू आणि गुणवंत विद्यार्थ्यांना शिष्यवृत्ती प्रदान करा.",
  "Conducts Online MSCIT Examinations.": "MSCIT ऑनलाइन परीक्षा आयोजित करा.",
  // Miscellaneous
  STUDENT: "विद्यार्थी",
  ACTIVITIES: "क्रियाकलाप",
  Archive: "संग्रह",
  "STUDENT CAREER FAIR": "विद्यार्थी करिअर मेळावा",
  "Chatrapati Shambhajinagar Region": "छत्रपती संभाजीनगर प्रदेश",
  "Contact Us": "आमच्याशी संपर्क साधा",
  DISCLAIMER: "अस्वीकृती",
  "EQUIVALENCE (OTHER BOARD)": "समानता (इतर मंडळ)",
  "FREQUENTLY ASKED QUESTION": "नेहमी विचारले जाणारे प्रश्न",
  HELP: "मदत",
  "Importance of Diploma": "डिप्लोमाचे महत्त्व",
  "Mumbai Region": "मुंबई प्रदेश",
  "Nagpur Region": "नागपूर प्रदेश",
  "ONRS STUDENTS": "ONRS विद्यार्थी",
  "FUNCTIONING OF MSBTE": "MSBTE ची कार्यप्रणाली",
  "Privacy Policy": "गोपनीयता धोरण",
  "Pune Region": "पुणे प्रदेश",
  "Screen Reader Access": "स्क्रीन रीडर प्रवेश",
  "Search Results for": "साठी शोध परिणाम",
  Services: "सेवा",
  "Site Map": "साइट नकाशा",
  "TERMS AND CONDITION": "अटी आणि शर्ती",
  VISION: "दृष्टिकोन",
  MISSION: "ध्येय",
  // Legal Information
  "MAHARASHTRA ACT No. XXXVIII OF 1997, THE MAHARASHTRA STATE BOARD OF TECHNICAL EDUCATION ACT, 1997. (English)(64 kb)":
    "महाराष्ट्र अधिनियम क्र. XXXVIII, 1997, महाराष्ट्र राज्य तंत्रशिक्षण मंडळ अधिनियम, 1997. (इंग्रजी)(64 kb)",
  "Maharashtra State Board of Technical Education (Amendment) Act, 2023 (Mah. Act No. XXII of 2023). (English)(199 kb)":
    "महाराष्ट्र राज्य तंत्रशिक्षण मंडळ (दुरुस्ती) अधिनियम, 2023 (महा. अधिनियम क्र. XXII, 2023). (इंग्रजी)(199 kb)",
  // Skills and Industry
  "Skill development in line with industry requirements":
    "उद्योगाच्या आवश्यकतांनुसार कौशल्य विकास",
  "Industry readiness and improved employability of Diploma holders":
    "उद्योग सुसज्जता आणि डिप्लोमा धारकांच्या रोजगारक्षमता सुधारणा",
  "Synergistic relationship with industry": "उद्योगासोबत समन्वयात्मक संबंध",
  "Collective and Cooperative development of all stake holders":
    "सर्व भागधारकांचा सामूहिक आणि सहकारी विकास",
  "Technological interventions in societal development":
    "समाजाच्या विकासामध्ये तांत्रिक हस्तक्षेप",
  "Access to uniform quality technical education":
    "सर्वांना समान दर्जाचे तांत्रिक शिक्षण उपलब्धता",
  // Abbreviations and General Terms
  Abbreviations: "संक्षेप",
  "Full Form": "संपूर्ण रूप",
  Link: "दुवा",
  // Table headersName: "नाव",
  Post: "पद",
  "E-mail": "ई-मेल",
  "Officers Image": "अधिकाऱ्यांचे चित्र",
  // Vision and Mission
  "To ensure that the Diploma level Technical Education constantly matches the latest requirements of Technology and industry and includes the all-round personal development of students including social concerns and to become globally competitive, technology led organization.":
    "डिप्लोमा स्तरावरील तांत्रिक शिक्षण हे नेहमीच तंत्रज्ञान आणि उद्योगाच्या ताज्या आवश्यकता पूर्ण करत राहील, तसेच विद्यार्थ्यांच्या सर्वांगीण वैयक्तिक विकासामध्ये सामाजिक जाणीवांचाही समावेश असेल आणि हे जागतिक स्तरावर स्पर्धात्मक, तंत्रज्ञान संचालित संस्था बनविण्यासाठी कार्य करेल.",
  "To provide high quality technical and managerial manpower, information and consultancy services to the industry and community to enable the industry and community to face the challenging technological & environmental challenges.":
    "उद्योग आणि समुदायाला तांत्रिक आणि व्यवस्थापकीय उच्च गुणवत्तेचे मनुष्यबळ, माहिती आणि सल्ला सेवा प्रदान करून तांत्रिक आणि पर्यावरणीय आव्हानांना तोंड देण्यासाठी सक्षम बनविणे.",
  "We, at MSBTE are committed to offer the best in class academic services to the students and institutes to enhance the delight of industry and society. This will be achieved through continual improvement in management practices adopted in the process of curriculum design, development, implementation, evaluation and monitoring system along with adequate faculty development programmes.":
    "आम्ही, MSBTE येथे, विद्यार्थ्यांना आणि संस्थांना उद्योग आणि समाजाच्या समाधानासाठी सर्वोत्तम दर्जाचे शैक्षणिक सेवा प्रदान करण्यास वचनबद्ध आहोत. हे अभ्यासक्रमाच्या रचना, विकास, अंमलबजावणी, मूल्यमापन आणि निरीक्षण प्रणालीच्या प्रक्रियेत अवलंबलेल्या व्यवस्थापन पद्धतींमध्ये सतत सुधारणा आणि पुरेसे शिक्षक विकास कार्यक्रमांच्या माध्यमातून साध्य केले जाईल.",
  "Download Quality Policy as a PDF file (English)(1,653 kb)":
    "गुणवत्ता धोरण PDF स्वरूपात डाउनलोड करा (इंग्रजी)(1,653 kb)",
  // Board Members
  "Hon. Shri. Chadarakant (Dada) Patil": "आ. श्री. चंद्रकांत (दादा) पाटील",
  "Minister of Higher and Technical Education, Maharashtra State":
    "उच्च आणि तंत्रशिक्षण मंत्री, महाराष्ट्र राज्य",
  President: "अध्यक्ष",
  "Shri Vikas Chandra Rastogi": "श्री विकास चंद्र रस्तोगी",
  "Principal Secretary Higher & Technical Education Dept. Maharashtra State":
    "मुख्य सचिव, उच्च व तंत्रशिक्षण विभाग, महाराष्ट्र राज्य",
  Member: "सदस्य",
  "Shri Bharat Dattatraya Amalkar": "श्री भरत दत्तात्रय आमलकर",
  "Dr. Rajendra G. Hiremath": "डॉ. राजेंद्र जी. हिरेमठ",
  "Shri Vilas Tawde": "श्री विलास तावडे",
  "Dr. Vinod M. Mohitkar": "डॉ. विनोद एम. मोहितकर",
  "Director of Technical Education, Maharashtra State":
    "संचालक, तंत्रशिक्षण, महाराष्ट्र राज्य",
  "Dr. Mahendra R. Chitlange": "डॉ. महेंद्र आर. चितलांगे",
  "Secretary, Maharashtra State Board of Technical Education":
    "सचिव, महाराष्ट्र राज्य तंत्रशिक्षण मंडळ",
  "Dr. Pramod A. Naik": "डॉ. प्रमोद ए. नाईक",
  "Director, Maharashtra State Board of Technical Education":
    "संचालक, महाराष्ट्र राज्य तंत्रशिक्षण मंडळ",
  "Member Secretary": "सदस्य सचिव",
  "Director, Directorate of Technical Education, Maharashtra State, Mumbai":
    "संचालक, तंत्रशिक्षण संचालनालय, महाराष्ट्र राज्य, मुंबई",
  Chairman: "अध्यक्ष",
  "Director, Maharashtra State Board of Technical Education, Mumbai":
    "संचालक, महाराष्ट्र राज्य तंत्रशिक्षण मंडळ, मुंबई",
  "Shri Shriram Panzade": "श्री श्रीराम पानझाडे",
  "Divisional Chairman, M.S. Board of Secondary & Higher Secondary Education, Divisional Office, Mumbai":
    "विभागीय अध्यक्ष, महाराष्ट्र राज्य माध्यमिक व उच्च माध्यमिक शिक्षण मंडळ, विभागीय कार्यालय, मुंबई",
  "Smt. Poonam Darade": "सौ. पूनम दाराडे",
  "Jt. Director of Industries, Office of the Jt. Director of Industries Mumbai Region, Mumbai":
    "सह संचालक, उद्योग, सह संचालक कार्यालय, मुंबई विभाग, मुंबई",
  "Dr. Ajeet Singh": "डॉ. अजीत सिंग",
  "Regional Officer, All India Council for Technical Education, Western Region, Mumbai":
    "क्षेत्रीय अधिकारी, अखिल भारतीय तांत्रिक शिक्षण परिषद, पश्चिम विभाग, मुंबई",
  "Shri Pramod Jumle": "श्री प्रमोद जुमले",
  "Director, Board of Apprenticeship Training, Western Region, Mumbai":
    "संचालक, अपरेंटिसशिप प्रशिक्षण मंडळ, पश्चिम विभाग, मुंबई",
  "Shri Satish Tidke": "श्री सतीश तिडके",
  "Dy. Secretary, Higher & Technical Education, Maharashtra State, Mumbai":
    "उप सचिव, उच्च व तंत्रशिक्षण, महाराष्ट्र राज्य, मुंबई",
  "Dr. Suhas S. Gajre": "डॉ. सुहास एस. गजरे",
  "Principal, Government College of Engineering, Jalgaon":
    "प्राचार्य, शासकीय अभियांत्रिकी महाविद्यालय, जळगाव",
  "Smt. Padmaja Bhanu B.": "सौ. पद्मजा भानु बी.",
  "Principal, K.J. Somaiya Polytechnic, Mumbai":
    "प्राचार्य, के.जे. सोमैया पॉलिटेक्निक, मुंबई",
  "Shri Ashish Anant Ukidve": "श्री आशीष अनंत उकिडवे",
  "Principal, Vidyalankar Polytechnic Wadala, Mumbai":
    "प्राचार्य, विद्यानंकर पॉलिटेक्निक वडाळा, मुंबई",
  "Smt. Sandhya Vishnu Karande (Kolhe)": "सौ. संध्या विष्णु करांडे (कोल्हे)",
  "Head of the Department, Electronic & Telecommunication Engg. Guru Gobind Singh Polytechnic, Nashik":
    "विभागप्रमुख, इलेक्ट्रॉनिक व दूरसंचार अभियांत्रिकी, गुरु गोविंदसिंग पॉलिटेक्निक, नाशिक",
  "Smt. Jyoti Ramrao Hange": "सौ. ज्योती रामराव हंगे",
  "Head of Department, Computer Engg. Government Polytechnic, Pune":
    "विभागप्रमुख, संगणक अभियांत्रिकी, शासकीय तंत्रनिकेतन, पुणे",
  "Shri Dhananjay Balwant Bapat": "श्री धनंजय बळवंत बापट",
  "Industrialist, Nagpur": "उद्योगपती, नागपूर",
  Industrialist: "उद्योगपती",
  "Shri Rajesh Solanki": "श्री राजेश सोलंकी",
  "Managing Director, Miraj Engineering Services Pvt. Ltd. Thane":
    "व्यवस्थापकीय संचालक, मिराज इंजिनिअरिंग सर्व्हिसेस प्रा. लि., ठाणे",
  "Shri Vivek Naik": "श्री विवेक नाईक",
  "Managing Director, Apple Chemie India Pvt. Ltd., Nagpur":
    "व्यवस्थापकीय संचालक, अॅपल केमी इंडिया प्रा. लि., नागपूर",
  "Shri Manoj Deshmukh": "श्री मनोज देशमुख",
  "Founder and Director, Eteva Consulting Pvt. Ltd., Pune":
    "संस्थापक व संचालक, इटेवा कन्सल्टिंग प्रा. लि., पुणे",
  "Shri Hemant Chafale": "श्री हेमंत चाफळे",
  "CEO, Trust Fintech Limited, Nagpur":
    "मुख्य कार्यकारी अधिकारी, ट्रस्ट फिनटेक लिमिटेड, नागपूर",
  "Shri Umesh T. Nagdeve": "श्री उमेश टी. नागदेवे",
  "Secretary, Maharashtra State Board of Technical Education, Mumbai":
    "सचिव, महाराष्ट्र राज्य तंत्रशिक्षण मंडळ, मुंबई",
  "Dr. Pramod Ambadasji Naik": "डॉ. प्रमोद अंबादासजी नाईक",
  "Shri. Umesh Teniprasad Nagdeve": "श्री. उमेश तेनीप्रसाद नागदेवे",
  "Dr. Chandrakant Digambar Kapse": "डॉ. चंद्रकांत दिगंबर कापसे",
  "Mrs. Mrunal Umesh Kokate": "सौ. मृणाल उमेश कोकाटे",
  "Mrs. Vandana Pandhari Wankhede": "सौ. वंदना पांडरी वानखेडे",
  "Shri. Sagar Papaso Patil": "श्री. सागर पापासो पाटील",
  "Assistant Secretary (T.)": "सहायक सचिव (तांत्रिक)",
  "Mrs. Darshana Deepak Chande": "सौ. दर्शना दीपक चांदे",
  "Smt Urmila Manohar Hire": "सौ. उर्मिला मनोहर हिरे",
  "Dr. Anjali Atul Bhandakkar": "डॉ. अंजली अतुल भंडारकर",
  "Mrs. Kanchan Yashwant Kale": "सौ. कांचन यशवंत काले",
  "Mrs. Pooja Shivaji Chame": "सौ. पूजा शिवाजी चामे",
  "Shri Parmeshwar Sham Solanki": "श्री. परमेश्वर शाम सोलंकी",
  "Shri Abhinay Brijesh Sahu": "श्री. अभिनय बृजेश साहू",
  "Shri. Sudarshan Suresh Kumbhar": "श्री. सुदर्शन सुरेश कुंभार",
  "Smt. Smita Maruti Gade": "सौ. स्मिता मारुती गाडे",
  "Mrs. Sadhana Bhagwan Bhosale": "सौ. साधना भगवान भोसलें",
  "Shri. Nitin Madhukar Khadse": "श्री. नितीन माधवराव खडसे",
  "Mrs.Vijaya Vidyadhar Sawant": "सौ. विजया विद्यासागर सावंत",
  "Mrs. Nita Sharad Nanavare": "सौ. नीता शरद नानावरे",
  "Shri. Pramod Uttam Wayse": "श्री. प्रमोद उत्तम वायसे",
  "Shri. Namdev Vijayakumar Matolkar": "श्री. नामदेव विजयकुमार माटोलकर",
  "Mrs. Nisha Rupaji Vartha": "सौ. निशा रुपाजी वारथा",
  "Shri. Shailesh Madhukar Gaikwad": "श्री. शैलेश मधुकर गायकवाड",
  "Assistant Secretary (NT)": "सहायक सचिव (गैर तांत्रिक)",
  "Accounts Officer": "लेखा अधिकारी",
  Director: "संचालक",
  Secretary: "सचिव",
  "Deputy Secretary (T.)": "उपसचिव (तांत्रिक)",
  "Dy. Secretary (T)": "उपसचिव (तांत्रिक)",
  "System Analyst": "सिस्टम विश्लेषक",
  "Deputy Secretary (T)": "उपसचिव (तांत्रिक)",
  "Special Task Officer": "विशेष कार्य अधिकारी",
  "Assistant Secretary (T)": "सहायक सचिव (तांत्रिक)",
  "Mr. Pramod U Wayse": "श्री. प्रमोद यू. वायसे",
  "Shri. Vijay Marutrao Kolhe": "श्री. विजय मारुत्राव कोल्हे",
  "Mrs. Shital S. Fartade": "सौ. शीतल एस. फरताडे",
  "Mrs. Amita Rajendra Kaigude": "सौ. अमिता राजेंद्र कैगुडे",
  "Shri. Usmani Mohammad Shaheed Afzal Ahmad":
    "श्री. उस्मानी मोहम्मद शाहिद अफजल अहमद",
  "Shri. Dilip Kumar Hulasram Lilhare": "श्री. दिलीप कुमार हुलासराम लिल्हारे",
  "Mrs. Vaishali Anirudh Raje": "सौ. वैशाली अनिरुद्ध राजे",
  "Shri. Devendra Ramesh Dandagawal": "श्री. देवेंद्र रमेश दंडगवळ",
  "Shri. Jagdish Gangadhar Ahirrao": "श्री. जगदीश गंगाधर आहिरराव",
  "Dr. Deepali Garibdas Ganakwar": "डॉ. दीपाली गरीबदास गणकवार",
  "Shri. Ashish Appasaheb Nikam": "श्री. आशिष अप्पासाहेब निकम",
  "Sr. No.": "अ.क्र.",
  Subject: "विषय",
  Description: "वर्णन",
  Video: "व्हिडिओ",
  "Applied Maths": "अनुप्रयुक्त गणित",
  "DIP M2 U1 01 Functions": "DIP M2 U1 01 फंक्शन्स",
  "DIP M2 U1 01 Limits": "DIP M2 U1 01 सीमाएँ",
  "DIP M2 U1 03 Derivatives I": "DIP M2 U1 03 अवकलन I",
  "DIP M2 U1 03 Derivatives II": "DIP M2 U1 03 अवकलन II",
  "DIP M2 U1 03 Derivatives III": "DIP M2 U1 03 अवकलन III",
  "DIP M2 U1 03 Derivatives IV": "DIP M2 U1 03 अवकलन IV",
  "DIP M2 U1 04 Application of Derivetives": "DIP M2 U1 04 अवकलनांचा वापर",
  "DIP M2 U2 01 Simple Integration Part 01": "DIP M2 U2 01 साधे समाकलन भाग 01",
  "DIP M2 U2 01 Simple Integration Part 02": "DIP M2 U2 01 साधे समाकलन भाग 02",
  "DIP M2 U2 03 Methods of Integration I": "DIP M2 U2 03 समाकलनाच्या पद्धती I",
  "DIP M2 U2 04 Methods of Integration II":
    "DIP M2 U2 04 समाकलनाच्या पद्धती II",
  "Online Procedure for Transcript Certificate":
    "ट्रान्सक्रिप्ट प्रमाणपत्रासाठी ऑनलाइन प्रक्रिया",
  "Apply Online": "ऑनलाइन अर्ज करा",
  "Important Note:": "महत्त्वाची टीप:",
  "Transcript Certificate contains the name of the student as per final Board Certificate, total marks of final year or last two semesters total marks & Teaching Examination schemes of the course.":
    "ट्रान्सक्रिप्ट प्रमाणपत्रामध्ये अंतिम मंडळ प्रमाणपत्रानुसार विद्यार्थ्याचे नाव, अंतिम वर्षाचे किंवा अंतिम दोन सत्रांचे एकूण गुण, आणि कोर्सचे अध्यापन व परीक्षा योजना यांचा समावेश असतो.",
  "If a student wants both Transcript & Authentication Certificate, She/he needs to apply in two different sections i.e. CDC and Exam Section of MSBTE separately. It is advised to process both certificates simultaneously.":
    "जर विद्यार्थ्याला ट्रान्सक्रिप्ट आणि प्रमाणीकरण प्रमाणपत्र दोन्ही हवे असतील, तर त्याने/तिने MSBTE च्या CDC आणि परीक्षा विभागात स्वतंत्रपणे अर्ज करावा. दोन्ही प्रमाणपत्रे एकाच वेळी प्रक्रिया करणे सल्ला दिला जातो.",
  "For the sample Transcript Certificate, please":
    "ट्रान्सक्रिप्ट प्रमाणपत्राच्या नमुन्यासाठी, कृपया",
  "Click here": "येथे क्लिक करा",
  "For Queries related to Transcript Certificate, Please write us on,":
    "ट्रान्सक्रिप्ट प्रमाणपत्राशी संबंधित चौकशीसाठी, कृपया आम्हाला लिहा:",
  "Online Procedure for Migration certificate":
    "मायग्रेशन प्रमाणपत्रासाठी ऑनलाइन प्रक्रिया",
  Back: "मागे",
  "IMPORTANT NOTE FOR STUDENTS OF AUTONOMOUS INSTITUTE:":
    "स्वायत्त संस्थांच्या विद्यार्थ्यांसाठी महत्त्वाची सूचना:",
  "Migration Certificate will be issued to students from MSBTE affiliated institutes only. Students of Autonomous Institutes should contact their respective institute for Migration Certificate.":
    "मायग्रेशन प्रमाणपत्र फक्त MSBTE संलग्न संस्थांच्या विद्यार्थ्यांना दिले जाईल. स्वायत्त संस्थांच्या विद्यार्थ्यांनी मायग्रेशन प्रमाणपत्रासाठी त्यांच्या संबंधित संस्थेशी संपर्क साधावा.",
  "Online Procedure for Migration certificate Apply Online":
    "मायग्रेशन प्रमाणपत्रासाठी ऑनलाइन प्रक्रिया अर्ज करा",
  "Procedure for Reapply (Click here)":
    "पुनः अर्ज करण्याची प्रक्रिया (येथे क्लिक करा)",
  "For Queries related to Migration Certificate, Please write us on,":
    "मायग्रेशन प्रमाणपत्राशी संबंधित चौकशीसाठी, कृपया आम्हाला लिहा:",
  "Non AICTE Courses": "नॉन AICTE कोर्सेस",
  "Course Code": "कोर्स कोड",
  "Course Name": "कोर्सचे नाव",
  Region: "प्रदेश",
  "Inst Code": "संस्था कोड",
  "Inst Name": "संस्थेचे नाव",
  "Non AICTE Institutes": "नॉन AICTE संस्था",
  "Diploma in Beauty Culture And Hair Dressing":
    "डिप्लोमा इन ब्युटी कल्चर आणि हेअर ड्रेसिंग",
  "Diploma in Bamboo Technology": "बॅम्बू तंत्रज्ञानामध्ये डिप्लोमा",
  "Advance Diploma in Cyber Security Management":
    "सायबर सुरक्षा व्यवस्थापनामध्ये प्रगत डिप्लोमा",
  "Diploma in CNC Machine Techniques": "CNC मशीन तंत्रांमध्ये डिप्लोमा",
  "Diploma in 3D Animation & Graphics":
    "3D अॅनिमेशन आणि ग्राफिक्समध्ये डिप्लोमा",
  "Diploma in Animation & Graphics": "अॅनिमेशन आणि ग्राफिक्समध्ये डिप्लोमा",
  "Diploma in Dress Designing & Manufacturing":
    "ड्रेस डिझाइनिंग आणि मॅन्युफॅक्चरिंगमध्ये डिप्लोमा",
  "Advanced Diploma in Dietetics": "आहारशास्त्रामध्ये प्रगत डिप्लोमा",
  "Advance Diploma in Energy Management & Audit":
    "ऊर्जा व्यवस्थापन आणि लेखापरीक्षणामध्ये प्रगत डिप्लोमा",
  "Advance Diploma in Embedded Systems": "एंबेडेड सिस्टम्समध्ये प्रगत डिप्लोमा",
  "Advanced Diploma in Environmental Engineering":
    "पर्यावरण अभियांत्रिकीमध्ये प्रगत डिप्लोमा",
  "Advance Diploma in Fire Safety and Industrial Environmental Engineering":
    "फायर सुरक्षा आणि औद्योगिक पर्यावरण अभियांत्रिकीमध्ये प्रगत डिप्लोमा",
  "Advance Diploma in Industrial Safety and Security Management":
    "औद्योगिक सुरक्षा आणि सुरक्षा व्यवस्थापनामध्ये प्रगत डिप्लोमा",
  "Post Diploma in Fire Engineering": "फायर अभियांत्रिकीमध्ये पोस्ट डिप्लोमा",
  "Diploma in Fashion and Textile Designing":
    "फॅशन आणि वस्त्र डिझाइनिंगमध्ये डिप्लोमा",
  "Diploma in Fire Service Engineering": "फायर सेवा अभियांत्रिकीमध्ये डिप्लोमा",
  "Advance Diploma in Fire Safety Engineering":
    "फायर सुरक्षा अभियांत्रिकीमध्ये प्रगत डिप्लोमा",
  "Advance Diploma in Fire and Security Management":
    "फायर आणि सुरक्षा व्यवस्थापनामध्ये प्रगत डिप्लोमा",
  "Diploma in Fashion & Textile Designing":
    "फॅशन आणि वस्त्र डिझाइनिंगमध्ये डिप्लोमा",
  "Diploma in Hemodialysis Technician": "हेमोडायलिसिस तंत्रज्ञामध्ये डिप्लोमा",
  "Diploma in Hotel Operation": "हॉटेल संचालनामध्ये डिप्लोमा",
  "Diploma in Interior Designing and Decoration":
    "इंटेरियर डिझाइनिंग आणि सजावटामध्ये डिप्लोमा",
  "Advance Diploma in Internet of Things (IoT)":
    "इंटरनेट ऑफ थिंग्स (IoT) मध्ये प्रगत डिप्लोमा",
  "Post Diploma in Interior Designing & Decoration":
    "इंटेरियर डिझाइनिंग आणि सजावटामध्ये पोस्ट डिप्लोमा",
  "Advance Diploma in Industrial Safety":
    "औद्योगिक सुरक्षेमध्ये प्रगत डिप्लोमा",
  "Advance Diploma in Medical Laboratory Technology":
    "वैद्यकीय प्रयोगशाळा तंत्रज्ञानामध्ये प्रगत डिप्लोमा",
  "Advance Diploma in Medical Imaging Technology":
    "वैद्यकीय प्रतिमा तंत्रज्ञानामध्ये प्रगत डिप्लोमा",
  "Diploma in Medical Equipment Maintenance":
    "वैद्यकीय उपकरण देखभालामध्ये डिप्लोमा",
  "Diploma in Operation Theatre Technician":
    "ऑपरेशन थिएटर तंत्रज्ञामध्ये डिप्लोमा",
  "Advance Diploma in Project Management in Building Construction":
    "बिल्डिंग कन्स्ट्रक्शनमध्ये प्रकल्प व्यवस्थापनाचा प्रगत डिप्लोमा",
  "Diploma in Photography and Videography":
    "फोटोग्राफी आणि वीडियोग्राफीमध्ये डिप्लोमा",
  "Advance Diploma in X-ray and Radiography and Ultra Sonography Techniques":
    "X-ray आणि रेडियोग्राफी व अल्ट्रासोनोग्राफी तंत्रामध्ये प्रगत डिप्लोमा",
  "Advance Diploma in Radiotherapy Technology":
    "रेडियोथेरेपी तंत्रज्ञानामध्ये प्रगत डिप्लोमा",
  "Advanced Diploma in X-ray Radiography":
    "X-ray रेडियोग्राफीमध्ये प्रगत डिप्लोमा",
  "Diploma in Logistics and Supply Chain Management":
    "लॉजिस्टिक्स आणि पुरवठा साखळी व्यवस्थापनामध्ये डिप्लोमा",
  "Diploma in Stenography & Secretarial Practice":
    "स्टेनोग्राफी आणि सचिवीय प्रथांमध्ये डिप्लोमा",
  "Diploma in Sound Recording & Video Editing":
    "साउंड रेकॉर्डिंग आणि व्हिडिओ एडिटिंगमध्ये डिप्लोमा",
  "Advance Diploma in Sugar Chemical Control":
    "साखर रासायनिक नियंत्रणामध्ये प्रगत डिप्लोमा",
  "Advance Diploma in Travel & Tourism":
    "प्रवास आणि पर्यटनामध्ये प्रगत डिप्लोमा",
  "Advance Diploma in Unmanned Aerial Vehicles (UVA) and Drone Technology":
    "मानवरहित हवाई वाहने (UVA) आणि ड्रोन तंत्रज्ञानामध्ये प्रगत डिप्लोमा",
  Mumbai: "मुंबई",
  Pune: "पुणे",
  Nagpur: "नागपूर",
  CSN: "CSN",
  "M.H. SABOO SIDDIK": "एम.एच. साबू सिद्दीक",
  "V.E.S. POLYTECHNIC": "वी.ई.एस. पॉलिटेक्निक",
  "VIDYA PRASARAK MANDALS POLYTECHNIC": "विद्या प्रसारक मंडळ पॉलिटेक्निक",
  "GOVERNMENT POLYTECHNIC": "शासकीय पॉलिटेक्निक",
  "BHARATI VIDYAPEETH INST.OF TECHNOLOGY NAVI MUMBAI":
    "भारती विद्यापीठ तंत्रज्ञान संस्थान नवी मुंबई",
  "P. DR V.V.P. INSTITUTE OF TECHNOLOGY & ENGINEERING (POLYTECHNIC), PRAVARANAGAR":
    "पी. डॉ. वी.वी.पी. तंत्रज्ञान आणि अभियांत्रिकी संस्था (पॉलिटेक्निक), प्रवरा नगर",
  "SHRI.PRINCE SHIVAJI MARATHA BOARDING HOUSE'S NEW INSTITUTE OF TECHNOLOGY, KOLHAPUR":
    "श्री.प्रिन्स शिवाजी मराठा बोर्डिंग हाऊसचे नवे तंत्रज्ञान संस्थान, कोल्हापूर",
  "GRAMIN TECHNICAL AND MANGEMENT CAMPUS":
    "ग्रामीण तांत्रिक आणि व्यवस्थापन कॅम्पस",
  "KARMAVEER KAKASAHEB WAGH POLYTECHINC": "कर्मवीर काकासाहेब वाघ पॉलिटेक्निक",
  "SNJB'S SHRI HIRALAL HASTIMAL(JAIN BROTHERS,JALGAON) POLYTECHNIC":
    "एसएनजेबीचे श्री हिरालाल हस्तीमल (जैन ब्रदर्स, जळगाव) पॉलिटेक्निक",
  "G H RAISONI COLLEGE OF ENGINEERING & MANAGEMENT":
    "जी एच रायसोनी अभियांत्रिकी आणि व्यवस्थापन महाविद्यालय",
  "Sahyadri Polytechnic": "सह्याद्री पॉलिटेक्निक",
  "SHRI SAI POLYTECHNIC": "श्री साई पॉलिटेक्निक",
  "Navyug Vidyapeeth Trust's Pharmacy College":
    "नवयुग विद्यापीठ ट्रस्टचे फार्मसी महाविद्यालय",
  "INDIAN INSTITUTE OF FIRE ENGINEERING": "भारतीय अग्निशमन अभियांत्रिकी संस्था",
  "MAHARASHTRA INSTITUTE OF MEDICAL SCIENCE & TECHNOLOGY":
    "महाराष्ट्र वैद्यकीय विज्ञान आणि तंत्रज्ञान संस्था",
  "JBSPS Instt. of Industrial Safety": "जेबीएसपीएस औद्योगिक सुरक्षा संस्थान",
  "Tarapur Mngt.Assoc.Instt.Ofmngt.Studiestvmschool&jr.College":
    "तारापूर व्यवस्थापन असोसिएशन तंत्रज्ञान संस्थान व माध्यमिक विद्यालय",
  "KALA VIDYA SANKUL": "कला विद्या संकुल",
  "THANE BELAPUR INDUSTRIES ASSOCIATION": "ठाणे-बेलापूर औद्योगिक असोसिएशन",
  "Academy Of Applied Art": "अॅकॅडमी ऑफ अप्लाईड आर्ट",
  "Mahila Institute Of Garment Technology": "महिला वस्त्र तंत्रज्ञान संस्था",
  "SCHOOL OF INTERIOR DESIGN": "इंटेरियर डिझाइन शाळा",
  "CENTRAL LABOUR INSTITUTE": "केंद्रीय श्रमिक संस्था",
  "L.S.Raheja School Of Architecture": "एल.एस.रायेजा शाळा आर्किटेक्चर",
  "MARATHWADA MITRA MANDAL'S MARATHWADA MITRA MANDAL SCHOOL OF INTERIOR DESIGN":
    "मराठवाडा मित्र मंडळाचे इंटेरियर डिझाइन शाळा",
  "NATIONAL SAFETY ORGANISATION": "राष्ट्रीय सुरक्षा संस्था",
  "DECCAN EDUCATION SOCIETY'S TECHNICAL INSTITUTE, PUNE":
    "डेक्कन एज्युकेशन सोसायटीचे तांत्रिक संस्थान, पुणे",
  "W. TECH. ED. & RESEARCH SMT.R. PUROHIT INSTITUTE":
    "डब्ल्यू.टेक.शिक्षण आणि संशोधन श्रीमती.आर. पुरोहित संस्थान",
  "Smt. S.C Nanavati Institute of Polytechnic":
    "श्रीमती एस.सी. नानावटी पॉलिटेक्निक संस्था",
  "PATALDHAMAL WADHWANI PHARMACY COLLEGE":
    "पाटलधामल वाधवानी फार्मसी महाविद्यालय",
  "M.M.A. Technical Institute": "एम.एम.ए. तांत्रिक संस्थान",
  "DEVI MAHALAXMI COLLEGE": "देवी महालक्ष्मी महाविद्यालय",
  "Tata Memorial Hospital": "टाटा मेमोरियल हॉस्पिटल",
  "SURYADATTA EDUCATION FOUNDATION'S PUNE INSTITUTE OF APPLIED TECHNOLOGY":
    "सूर्यदत्त एज्युकेशन फाउंडेशनचे पुणे इन्स्टिट्यूट ऑफ अप्लाईड टेक्नॉलॉजी",
  "SHREE SAINATH INSTT. OF LABORATORY MEDICINE":
    "श्री साईनाथ प्रयोगशाळा औषध संस्था",
  "S.S.G.V.S'S NATIONAL INSTITUTE OF FIRE ENGINEERING":
    "एस.एस.जी.व्ही.एस राष्ट्रीय अग्निशमन अभियांत्रिकी संस्था",
  "SANTOSH N. DARADE POLYTECHNIC": "संतोष एन. दराडे पॉलिटेक्निक",
  "ANJUMAN-I-ISLAM'S POLYTECHNIC FOR GIRLS":
    "अंजुमन-ए-इस्लामचे मुलींसाठी पॉलिटेक्निक",
  "MAHARSHI KARVE STREE SHIKSHAN SANSTHA'S SHRI MANILAL NANAVATI VOCATIONAL TRAINING INSTITUTE FOR WOMEN":
    "महर्षी कर्वे स्त्री शिक्षण संस्थेचे श्री मणिलाल नानावटी महिलांसाठी व्यावसायिक प्रशिक्षण संस्थान",
  "CANCER RELIEF SOC.'S RASHTRASANT TUKDOJIREGIONAL CANCER HOSP":
    "कॅन्सर रिलीफ सोसायटीचे राष्ट्रसंत तुकडोजी क्षेत्रीय कॅन्सर हॉस्पिटल",
  "S.I.E.S. COLLEGE OF ARTS, SCIENCE &COMMERCE":
    "एस.आय.ई.एस. कला, विज्ञान आणि वाणिज्य महाविद्यालय",
  "MODERN COLLEGE": "मॉडर्न कॉलेज",
  "B. K. BIRLA COLLEGE OF ARTS, SCIENCE AND COMMERCE":
    "बी.के. बिर्ला कला, विज्ञान आणि वाणिज्य महाविद्यालय",
  "NSCBMT'S NETAJI SUBHASH CHANDRA INSTITUTE":
    "एन.एस.सी.बी.एम.टी.चे नेताजी सुभाषचंद्र इन्स्टिट्यूट",
  "SWAMI VIVEKANAND OF PARAMEDICAL SCIENCE":
    "स्वामी विवेकानंद पॅरामेडिकल विज्ञान",
  "All India Instt. of Local Self Government":
    "अखिल भारतीय स्थानिक स्वराज्य संस्था",
  "DECCAN TECHNOLOGY TRAINING INSTITUTE": "डेक्कन तंत्रज्ञान प्रशिक्षण संस्था",
  "Shri Vitthalrao Joshi Chairtable Trust's College Of Advanced Studies":
    "श्री विठ्ठलराव जोशी चॅरिटेबल ट्रस्टचे उच्च शिक्षण महाविद्यालय",
  "JEEVAN SHIKSHAN SANSTHA 'S MAHADEO ANANDA PATIL PGDMLT COLLEGE":
    "जीवन शिक्षण संस्था महादेव आनंद पाटील पी.जी.डी.एम.एल.टी. महाविद्यालय",
  "BHAVANA EDUCATION SOCIETY'S BHAVANA SCHOOL OF INTERIOR DESIGN & DECORATION":
    "भावना शिक्षण सोसायटीची भावना इंटेरियर डिझाइन आणि सजावट शाळा",
  "INSTITUTE OF PARAMEDICAL SCIENCE PGDMLT COLLEGE":
    "इन्स्टिट्यूट ऑफ पॅरामेडिकल सायन्स पी.जी.डी.एम.एल.टी. कॉलेज",
  "SHRI VASANTRAO BANDUJI PATIL TRUST'S APPASAHEB BIRNALE COLLEGE OF ARCHITECTURE":
    "श्री वसंतराव बंदूजी पाटील ट्रस्टचे अप्पासाहेब बिरनाले आर्किटेक्चर कॉलेज",
  "COLLEGE OF FIRE ENGG. & SAFETY MANAGEMENT":
    "फायर अभियांत्रिकी आणि सुरक्षा व्यवस्थापन महाविद्यालय",
  "RAJARSHI SHAHU INSTITUTE OF MANAGEMENT & TECHNOLOGY":
    "राजर्षी शाहू व्यवस्थापन आणि तंत्रज्ञान संस्था",
  "Vidyasagar Post Graduate Institute":
    "विद्यासागर पोस्ट ग्रॅज्युएट इन्स्टिट्यूट",
  "Bhartiya Edu Society Inst of Technical Edu":
    "भारतीय शिक्षण सोसायटी तांत्रिक शिक्षण संस्थान",
  "Gajanan Technical Instt": "गजानन तांत्रिक संस्थान",
  "DHANWANTARI COLLEGE OF ENGINEERING": "धन्वंतरी अभियांत्रिकी महाविद्यालय",
  "WALWA TALUKA EDUCATION SOCIETY'S S. D. PATIL INSTITUTE OF PHARMACY":
    "वलवा तालुका शिक्षण सोसायटीचे एस.डी.पाटील फार्मसी संस्थान",
  "MAHARASHTRA INSTITUTE OF PHARMACY": "महाराष्ट्र फार्मसी संस्थान",
  "SHRI DHANLAXMI FOUNDATION'S LATE DADASAHEB CHAVAN MEMORIAL INSTITUTE OF PHARMACY":
    "श्री धनलक्ष्मी फाउंडेशनचे स्व.दादासाहेब चव्हाण स्मारक फार्मसी महाविद्यालय",
  "POONA DISTRICT EDUCATION ASSOCIATION'S SHANKARRAO URSAL COLLEGE OF PHARMACY":
    "पुणे जिल्हा शिक्षण संघटनेचे शंकरराव उर्सल फार्मसी महाविद्यालय",
  "SANKALP BAHU UDDESHIYA JANAKALYAN MANDALS SAKRI INSTITUTE OF TECHNOLOGY":
    "संकल्प बहुउद्देशीय जनकल्याण मंडळाचे साक्री तंत्रज्ञान संस्थान",
  "WAINGANGA TECHNICAL INSTITUTE": "वैनगंगा तांत्रिक संस्था",
  "SHARWANI COLLEGE OF FIRE ENGG": "शारवानी फायर अभियांत्रिकी महाविद्यालय",
  "Dr. Bhanuben Mahendra Nanavati College of Home Science":
    "डॉ. भानुबेन महेंद्र नानावटी होम सायन्स महाविद्यालय",
  "MOHANLALJI WALCHALE PGDMLT COLLEGE":
    "मोहनलालजी वालचले पी.जी.डी.एम.एल.टी. महाविद्यालय",
  "INSTITUTE OF FIRE & SAFETY MANAGEMENT":
    "अग्निशमन आणि सुरक्षा व्यवस्थापन संस्थान",
  "St. John's Institute of Technology & Management":
    "सेंट जॉन इन्स्टिट्यूट ऑफ टेक्नॉलॉजी आणि मॅनेजमेंट",
  "INDUTAI MEMORIAL DIPLOMA IN FIRE SERVICE ENGINEERING & DIPLOMA IN DENTAL TECHNOLOGY":
    "इंदुताई मेमोरियल फायर सेवा अभियांत्रिकी आणि दंत तंत्रज्ञानातील डिप्लोमा",
  "SHIRISH ACADEMY FOR KNOWLEDGE OF HEALTH INVESTIGATION":
    "शिरीष अकॅडमी फॉर नॉलेज ऑफ हेल्थ इन्व्हेस्टिगेशन",
  "ZEAL EDUCATION SOCIETY'S ZEAL POLYTECHNIC":
    "झील एज्युकेशन सोसायटीचे झील पॉलिटेक्निक",
  "INDIA FIRE SAFETY EDUCATION SOCIETY'S COLLEGE OF FIRE ENGINEERING & SAFETY MANAGEMENT":
    "इंडिया फायर सेफ्टी एज्युकेशन सोसायटीचे फायर अभियांत्रिकी आणि सुरक्षा व्यवस्थापन महाविद्यालय",
  "SAHYADRI COLLEGE OF FIRE ENGINEERING AND SAFETY MANAGEMENT":
    "सह्याद्री फायर अभियांत्रिकी आणि सुरक्षा व्यवस्थापन महाविद्यालय",
  "D.D. BHOYAR TECHNICAL INSTITUTE": "डी.डी.भोयर तांत्रिक संस्थान",
  "DHULE INSTT. OF TECH COLLEGE OF FIRE AND SAFETY ENGG.":
    "धुळे तांत्रिक संस्थान फायर आणि सुरक्षा अभियांत्रिकी महाविद्यालय",
  "NATIONAL FIRE SAFETY MANAGEMENT COLLEGE":
    "राष्ट्रीय अग्निसुरक्षा व्यवस्थापन महाविद्यालय",
  "Dnyanganga Institute of Technology": "ज्ञानगंगा तंत्रज्ञान संस्थान",
  "SONIYA GANDHI POLYTECHNIC": "सोनिया गांधी पॉलिटेक्निक",
  "MATOSHRI INSTITUTE OF TECHNOLOGY": "मातोश्री तंत्रज्ञान संस्थान",
  "SHRI.SAI TAJ POLYTECHNIC": "श्री साई ताज पॉलिटेक्निक",
  "SOU.SUSHILA D.GHODAWAT CHARITABLE TRUST'S LATE SOU SUSHILA DANCHAND GHODAWAT":
    "सौ.सुषिला डी.घोडावत चॅरिटेबल ट्रस्टचे स्व.सौ. सुषिला दांचंद घोडावत",
  "SHANTINIKETAN COLLEGE OF FIRE AND SAFETY ENGINEERING":
    "शांतिनिकेतन फायर आणि सुरक्षा अभियांत्रिकी महाविद्यालय",
  "NEW ENGLISH INSTITUTE OF TECHNOLOGY": "न्यू इंग्लिश तंत्रज्ञान संस्थान",
  "Vishwa Bahuudeshiay Sevabhavi Santha": "विश्व बहुउद्देशीय सेवाभावी संस्था",
  "ALLANA MOHD.TAHER EDU & CAREER FOUNDN INST OF PARAMEDICAL SCIENCES & TECH":
    "अल्लाना मोहम्मद ताहेर एज्युकेशन आणि करिअर फाउंडेशन पॅरामेडिकल सायन्स आणि तंत्रज्ञान संस्था",
  "AJITDADA PAWAR COLLEGE OF POLYTECHNIC":
    "अजितदादा पवार पॉलिटेक्निक महाविद्यालय",
  "MATA MAHAKALI COLLEGE OF FIRE ENGINEERING":
    "माता महाकाली फायर अभियांत्रिकी महाविद्यालय",
  "TCT'S SMT.GEETA D. TATKARE POLYTECHNIC":
    "टीसीटीचे श्रीमती गीता डी.तटकरे पॉलिटेक्निक",
  "ABSS'S INSTITUTE OF FIRE & SAFETY ENGINEERING":
    "एबीएसएसचे अग्निशमन आणि सुरक्षा अभियांत्रिकी संस्थान",
  "SANJAY GHODAWAT INSTITUTE": "संजय घोडावत इन्स्टिट्यूट",
  "DEVI MAHALAXMI POLYTECHNIC COLLEGE":
    "देवी महालक्ष्मी पॉलिटेक्निक महाविद्यालय",
  "DAYANAND INSTITUTE OF TECHNICAL EDUCATION, LATUR":
    "दयानंद तांत्रिक शिक्षण संस्था, लातूर",
  "MUMBAI CARRER ACADEMY": "मुंबई करिअर अकॅडमी",
  "MAHARASHTRA POLYTECHNIC,MANDANGAD": "महाराष्ट्र पॉलिटेक्निक, मांडणगड",
  "KALYANI CHARITABLE TRUST'S R.G.SAPKAL INSTITUTE OF PHARMACY":
    "कल्याणी चॅरिटेबल ट्रस्टचे आर.जी.सपकाळ फार्मसी संस्थान",
  "GAWANDE COLLEGE OF PHARMACY": "गवांडे फार्मसी महाविद्यालय",
  "SHRI RAJSHRI SHAHU INSTITUTE OF MANAGEMENT":
    "श्री राजर्षी शाहू व्यवस्थापन संस्थान",
  "NATIONAL HEALTH LAW RESEARCH INSTITUTE'S NOOTAN COLLEGE OF PHARMACY":
    "नॅशनल हेल्थ लॉ रिसर्च इन्स्टिट्यूटचे नूतन फार्मसी महाविद्यालय",
  "SAHYADRI CHARITABLE TRUST'S EKLAVYA COLLEGE OF PHARMACY":
    "सह्याद्री चॅरिटेबल ट्रस्टचे एकलव्य फार्मसी महाविद्यालय",
  "ANANDI SHIKSHAN PRASARAK MANDAL'S ANANDI PHARMACY COLLEGE":
    "आनंदी शिक्षण प्रसारक मंडळाचे आनंदी फार्मसी महाविद्यालय",
  "BAMBOO RESEARCH AND TRAINING CENTER CHINCHPALI":
    "बांबू संशोधन आणि प्रशिक्षण केंद्र चिंचपाली",
  "VSS INSTITUTE OF PHARMACY": "व्ही.एस.एस फार्मसी संस्थान",
  "MES COLLEGE OF PHARMACY": "एम.ई.एस फार्मसी महाविद्यालय",
  "SHRI RAMKRISHANA PARAMHANS COLLEGE OF PHARMACY (D-PHARM)":
    "श्री रामकृष्ण परमहंस डी-फार्मसी महाविद्यालय",
  "YAVATMAL ZILLA VIKAS SAMITI'S PATALDHAMAL WADHWANI COLLEGE OF PHARMACY":
    "यवतमाळ जिल्हा विकास समितीचे पाटलधामल वाधवानी फार्मसी महाविद्यालय",
  "SWAMI VIVEKANAND COLLEGE OF PHARMACY":
    "स्वामी विवेकानंद फार्मसी महाविद्यालय",
  "FABTECH EDUCATION SOCIETY'S FABTECH COLLEGE OF PHARMACY":
    "फॅबटेक एज्युकेशन सोसायटीचे फॅबटेक फार्मसी महाविद्यालय",
  "MRS. SARASWATI WANI COLLEGE OF D.PHARMACY":
    "श्रीमती सरस्वती वाणी डी.फार्मसी महाविद्यालय",
  "VAKRATUND EDUCATION SOCIETY'S GENESIS INSTITUTE OF PHARMACY":
    "वक्रतुंड एज्युकेशन सोसायटीचे जिनेसिस फार्मसी संस्थान",
  "JGVVSS'S SUYASH COLLEGE OF PHARMACY":
    "जे.जी.व्ही.व्ही.एस.एस चे सुयश फार्मसी महाविद्यालय",
  "ASHVIN COLLEGE OF PHARMACY MANCHI HILL":
    "अश्विन फार्मसी महाविद्यालय, मंची हिल",
  "DR. R. G. BHOYAR INSTITUTE OF PHARMACEUTICAL EDUCATION AND RESEARCH":
    "डॉ.आर.जी.भोयर औषध शिक्षण आणि संशोधन संस्थान",
  "DR. C.H. KAWCHALE CHARITABLE TRUST'S MODERN INSTITUTE OF PARAMEDICAL TECHNOLOGY":
    "डॉ.सी.एच.कवचले चॅरिटेबल ट्रस्टचे मॉडर्न पॅरामेडिकल तंत्रज्ञान संस्थान",
  "APEX TECHNICAL INSTITUTE PANGRI": "एपेक्स तांत्रिक संस्थान पांगरि",
  "MAHESH PARAMEDICAL COLLEGE": "महेष पॅरामेडिकल महाविद्यालय",
  "DR. SUBHASHRAO DHAKNE COLLEGE OF DMLT":
    "डॉ.सुभाषराव ढाकणे डी.एम.एल.टी. महाविद्यालय",
  "BHASKARRAO DESHPANDE INSTITUTE OF MEDICAL SCIENCE AND TECHNOLOGY":
    "भास्करराव देशपांडे वैद्यकीय विज्ञान आणि तंत्रज्ञान संस्थान",
  "Jalgaon Institute of Technology": "जळगाव तंत्रज्ञान संस्थान",
  "Shri Saibaba Paramedical Institute , Darewadi":
    "श्री साईबाबा पॅरामेडिकल संस्थान, दरेवाडी",
  "Vidyadhan DMLT College Valwadi Dhule Sharda Shaikshanik And Samajik Sanstha":
    "विद्याधन डी.एम.एल.टी. महाविद्यालय वालवाडी धुळे शारदा शैक्षणिक आणि सामाजिक संस्था",
  "BHOJANE'S ADVANCED PARAMEDICAL INSTITUTE":
    "भोजानेचे अॅडव्हान्स्ड पॅरामेडिकल संस्थान",
  "ADARSH PARAMEDICAL VOCATIONAL TRAINING INSTITUTE":
    "आदर्श पॅरामेडिकल व्यावसायिक प्रशिक्षण संस्थान",
  "Gramin Polytechnics Shivramji Pawar Technical Institute":
    "ग्रामीण पॉलिटेक्निक्स शिवरामजी पवार तांत्रिक संस्थान",
  "AMTEC FOUNDATION'S INSTITUTE OF PARAMEDICAL SCIENCE & TECHNOLOGY":
    "अम्टेक फाउंडेशनचे पॅरामेडिकल सायन्स आणि तंत्रज्ञान संस्थान",
  "JIJAMATA HEALTH AND TECHNICAL EDUCATION INSTITUTE":
    "जिजामाता आरोग्य आणि तांत्रिक शिक्षण संस्थान",
  "SHIVAROGYA PARAMEDICAL INSTITUTE GEORAI":
    "शिवारोग्य पॅरामेडिकल संस्थान, गेवराई",
  "S P TECHNICAL INSTITUTE": "एस.पी. तांत्रिक संस्थान",
  "SHREE CHHATRAPATI SHIVAJI SHIKSHAN & SANSHODHAN SANSTHA'S ADARSH PARAMEDICAL INSTITUTE":
    "श्री छत्रपती शिवाजी शिक्षण आणि संशोधन संस्थानचे आदर्श पॅरामेडिकल महाविद्यालय",
  "CHANDRALOP EDUCATIONAL AND RESEARCH CENTER CHANDRALOP COLLEGE OF FIRE ENGINEERING AND SAFETY MANAGEMENT":
    "चंद्रलोप शिक्षण आणि संशोधन केंद्र चंद्रलोप फायर अभियांत्रिकी आणि सुरक्षा व्यवस्थापन महाविद्यालय",
  "VIVEKANAND EDUCATION SOCIETY'S COLLEGE OF ARCHITECTURE SCHOOL OF DESIGN":
    "विवेकानंद शिक्षण सोसायटीचे आर्किटेक्चर शाळा ऑफ डिझाईन",
  "SIDDHI PARAMEDICAL INSTITUTE": "सिद्धी पॅरामेडिकल संस्थान",
  "RAJARSHI SHAHU COLLEGE OF PARAMEDICAL SCIENCE":
    "राजर्षी शाहू पॅरामेडिकल विज्ञान महाविद्यालय",
  "SAMARTH RURAL EDUCATIONAL INSTITUTE'S SAMARTH INSTITUTE OF PARAMEDICAL SCIENCES":
    "समर्थ ग्रामीण शिक्षण संस्थेचे समर्थ पॅरामेडिकल विज्ञान महाविद्यालय",
  "MANJU TECHNICAL INSTITUTE AND COMPUTER CENTER'S MANJU INSTITUTE OF TECHNOLOGY":
    "मंजू तांत्रिक संस्थान आणि संगणक केंद्राचे मंजू तंत्रज्ञान महाविद्यालय",
  "YESHWANTRAO CHAVAN COLLEGE OF POLYTECHNIC BHOKARDAN":
    "यशवंतराव चव्हाण पॉलिटेक्निक महाविद्यालय, भोकर्डन",
  "RAJESH BHAIYYA TOPE INSTITUTE OF PARAMEDICAL SCIENCE AND FIRE SAFETY MANAGEMENT AURANGABAD":
    "राजेश भैय्या टोपे पॅरामेडिकल विज्ञान आणि अग्निसुरक्षा व्यवस्थापन संस्थान औरंगाबाद",
  "SWAMI VIVEKANAND INSTITUTE OF PARAMEDICAL SCIENCE AND FIRE SAFETY MANAGEMENT AURANGABAD":
    "स्वामी विवेकानंद पॅरामेडिकल विज्ञान आणि अग्निसुरक्षा व्यवस्थापन संस्थान औरंगाबाद",
  "DNYANGANGOTRI INSTITUTE OF TECHNOLOGY":
    "ज्ञानगंगोत्री तंत्रज्ञान महाविद्यालय",
  "SHREE GANPATI TECHNICAL INSTITUTION": "श्री गणपती तांत्रिक संस्थान",
  "SHIVAJIRAO PAWAR INSTITUTE OF PARAMEDICAL TECHNOLOGY":
    "शिवाजीराव पवार पॅरामेडिकल तंत्रज्ञान महाविद्यालय",
  "YEOLA COLLEGE OF PARAMEDICAL SCIENCE YEOLA":
    "येवला पॅरामेडिकल विज्ञान महाविद्यालय, येवला",
  "Dindori College of Paramedical Science":
    "दिंडोरी पॅरामेडिकल विज्ञान महाविद्यालय",
  "V R PARAMEDICAL COLLEGE": "व्ही.आर. पॅरामेडिकल महाविद्यालय",
  "BALWANTRAO CHAVAN COLLEGE OF PARA MEDICAL AND HEALTH TECHNOLOGY":
    "बाळवंतराव चव्हाण पॅरामेडिकल आणि आरोग्य तंत्रज्ञान महाविद्यालय",
  "SHREE PARAM SADGURU COLLEGE": "श्री परम सद्गुरू महाविद्यालय",
  "SHIVAJI COLLEGE": "शिवाजी महाविद्यालय",
  "SAI CARE PARAMEDICAL INSTITUTE NASHIK": "साई केअर पॅरामेडिकल संस्थान, नाशिक",
  "SAI CARE COLLAGE OF FIRE & INDUSTRIAL SAFETY NASHIK":
    "साई केअर फायर आणि औद्योगिक सुरक्षा महाविद्यालय, नाशिक",
  "DNYANRAJ MAULI INSTITUTE OF MEDICAL SCIENCE & TECHNOLOGY":
    "ज्ञानराज माऊली वैद्यकीय विज्ञान आणि तंत्रज्ञान महाविद्यालय",
  "AYUSHMANS MEDICAL PARAMEDICAL COLLEGE":
    "आयुष्मान वैद्यकीय आणि पॅरामेडिकल महाविद्यालय",
  "Nirmal Institute of Paramedicals": "निर्मल पॅरामेडिकल संस्थान",
  "INSTITUTE OF FIRE ENGINEERS NAGPUR": "नागपूर अग्निशमन अभियांत्रिकी संस्थान",
  "SKILL PARAMEDICAL COLLEGE BULDANA": "स्किल पॅरामेडिकल महाविद्यालय, बुलडाणा",
  "ANURADHA PARAMEDICAL COLLEGE": "अनुराधा पॅरामेडिकल महाविद्यालय",
  "SANMATI PARAMEDICAL COLLEGE": "सन्मती पॅरामेडिकल महाविद्यालय",
  "MAULI INSTITUTE OF PARAMEDICAL COLLEGE": "माऊली पॅरामेडिकल महाविद्यालय",
  "PURANDHAR TECHNICAL EDUCATION SOCIETY'S PUNE CAMBRIDGE TECHNICAL INSTITUTE":
    "पुरंदर तांत्रिक शिक्षण सोसायटीचे पुणे कॅम्ब्रिज तांत्रिक संस्थान",
  "SIDDHAM INNOVATION & BUSINESS INCUBATION CENTER'S SIBIC INSTITUTE OF TECHNOLOGY":
    "सिद्धम इनोव्हेशन आणि बिझनेस इन्क्युबेशन सेंटरचे सिबिक तंत्रज्ञान संस्थान",
  "SHRI VITTHAL DNYANPEETH AROGYA SHAIKSHANIK PRASARAK SANSTHA'S V. R. PARAMEDICAL COLLEGE":
    "श्री विठ्ठल ज्ञानपीठ आरोग्य शैक्षणिक प्रसारक संस्थेचे व्ही.आर. पॅरामेडिकल महाविद्यालय",
  "RAMLING VIGHNE TECHNICAL INSTITUTE": "रामलिंग विघने तांत्रिक संस्थान",
  "DNYANPRAKASH COLLEGE": "ज्ञानप्रकाश महाविद्यालय",
  "PARMANAND COLLEGE OF FIRE ENG. & SAFETY MANAGEMENT":
    "परमानंद फायर अभियांत्रिकी आणि सुरक्षा व्यवस्थापन महाविद्यालय",
  "RAMRAJE INSTITUTE OF FIRE AND SAFETY": "रामराजे फायर आणि सुरक्षा संस्थान",
  "AVG INSTITUTE OF SKILL DEVELOPMENT": "ए.व्ही.जी. स्किल डेव्हलपमेंट संस्थान",
  "PRAGATI EDUCATIONAL INSTITUTE": "प्रगती शैक्षणिक संस्था",
  "MG PARAMEDICAL INSTITUTE": "एमजी पॅरामेडिकल संस्थान",
  "ASIAN INSTITUTE OF FIRE SAFETY": "एशियन फायर सेफ्टी संस्थान",
  "NATIONAL COLLEGE OF FIRE AND SAFETY":
    "राष्ट्रीय अग्निशमन आणि सुरक्षा महाविद्यालय",
  "MAULI EDUCATION SOCIETY MANGAON INSTITUTE OF TECHNOLOGY":
    "माऊली शिक्षण सोसायटी माणगाव तंत्रज्ञान महाविद्यालय",
  "RUSHIKESH INSTITUTE OF MANAGEMENT AND TECHNOLOGY":
    "ऋषिकेश व्यवस्थापन आणि तंत्रज्ञान संस्थान",
  "OM INSTITUTE OF MANAGEMENT AND TECHNOLOGY":
    "ओम व्यवस्थापन आणि तंत्रज्ञान संस्थान",
  "UJJWAL TECHNICAL INSTITUTE": "उज्ज्वल तांत्रिक संस्थान",
  "SIDDHI INSTITUTE OF PARAMEDICAL": "सिद्धी पॅरामेडिकल संस्थान",
  "SAMARTH INSTITUTE OF PARAMEDICAL SCIENCE SANGAMNER":
    "समर्थ पॅरामेडिकल विज्ञान संस्थान, संगमनेर",
  "RENUKA TECHNICAL INSTITUTE": "रेणुका तांत्रिक संस्थान",
  "DMLT FIRE SAFETY COLLEGE": "डी.एम.एल.टी. फायर सेफ्टी महाविद्यालय",
  "ARYAVRAT PYARAMEDICAL VYAVSAIK SHIKSHAN SYAMEOJAGAR SHIKSHAN MANDAL":
    "आर्याव्रत पॅरामेडिकल व्यवसायिक शिक्षण आणि स्वयमोजगार शिक्षण मंडळ",
  "VIDHARBHA TECHNICAL COLLEGE": "विदर्भ तांत्रिक महाविद्यालय",
  "MATOSHRI DR KANCHAN SHANTILALJI DESARDA PARAMEDICAL COLLEGE":
    "मातोश्री डॉ.कांचन शांतीलालजी देसारदा पॅरामेडिकल महाविद्यालय",
  "MAHATMA PHULE PARAMEDICAL COLLEGE": "महात्मा फुले पॅरामेडिकल महाविद्यालय",
  "LATE SHRI S B PURUSHOTTAM COLLEGE OF FIRE ENGINEERING":
    "स्व.श्री एस.बी.पुरुषोत्तम फायर अभियांत्रिकी महाविद्यालय",
  "Institute of Safety and Environment, Sindhi Social Service Society":
    "सुरक्षा आणि पर्यावरण संस्थान, सिंधी सोशल सर्व्हिस सोसायटी",
  "Aaron Institute of Fire Safety, Rajiv Gandhi Kanya Mahavidyalaya":
    "आरोन फायर सेफ्टी संस्थान, राजीव गांधी कन्या महाविद्यालय",
  "SAMARTH PARAMEDICAL SCIENCES, SAMARTH PARAMEDICAL SCIENCES":
    "समर्थ पॅरामेडिकल विज्ञान, समर्थ पॅरामेडिकल विज्ञान",
  "R G TECHNICAL INSTITUTE, BALEPIR, NAGAR ROAD BEED TQ & DIST BEED":
    "आर.जी. तांत्रिक संस्थान, बाळेपिर, नगर रोड बीड तालुका आणि जिल्हा बीड",
  "DR A P J ABDUL KALAM SKILL DEVELOPMENT AND RESEARCH":
    "डॉ. ए.पी.जे. अब्दुल कलाम स्किल डेव्हलपमेंट आणि रिसर्च",
  "RAJARSHI SHAHU INSTITUTE OF FIRE AND SAFETY MANAGEMENT":
    "राजर्षी शाहू अग्निशमन आणि सुरक्षा व्यवस्थापन संस्थान",
  "Indira Institute of Paramedical Sciences, Indira Hospital,Khade Nagar":
    "इंदिरा पॅरामेडिकल विज्ञान संस्थान, इंदिरा हॉस्पिटल, खडे नगर",
  "AARAMBH COLLEGE OF FIRE AND SAFETY MANAGEMENT NASHIK":
    "आरंभ फायर आणि सुरक्षा व्यवस्थापन महाविद्यालय, नाशिक",
  "Yash Institute of Paramedical Science and Technology":
    "यश पॅरामेडिकल विज्ञान आणि तंत्रज्ञान संस्थान",
  "Nashik College of Paramedical Science and Fire Industrial Safety Nashik":
    "नाशिक पॅरामेडिकल विज्ञान आणि औद्योगिक सुरक्षा महाविद्यालय, नाशिक",
  "ASIAN INSTITUTE OF FIRE SAFETY, PANVEL": "एशियन फायर सेफ्टी संस्थान, पनवेल",
  "ALL INDIA INSTITUTE OF LOCAL SELF GOVERNMENT":
    "अखिल भारतीय स्थानिक स्वराज्य संस्था",
  "ASK FOUNDATION'S SUDA INSTITUTE OF FIRE AND SAFETY":
    "ए.एस.के फाउंडेशनचे सुदा फायर आणि सुरक्षा संस्थान",
  "MODERN INSTITUTE OF TECHNOLOGY(MIT) DNYANDEEP PARAMEDICAL INSTITUTE":
    "मॉडर्न तंत्रज्ञान संस्था (एम.आय.टी.) ज्ञानदीप पॅरामेडिकल संस्थान",
  "SANKALP PRATISHTHAN'S SANKALP EDUCATIONAL INSTITUTE":
    "संकल्प प्रतिष्ठानचे संकल्प शैक्षणिक संस्थान",
  "SHRIBSS ATHARVA PG DMLT TECHNICAL COLLEGE KOROCHI":
    "श्रीबीएसएस अथर्व पीजी डी.एम.एल.टी. तांत्रिक महाविद्यालय कोरोची",
  "SARHAD INSTITUTE OF MANAGEMENT AND TECHNOLOGY":
    "सरहद व्यवस्थापन आणि तंत्रज्ञान संस्थान",
  "VISION HEALTH CARE EDUCATION FOUNDATION'S VISION PARAMEDICAL INSTITUTE":
    "व्हिजन हेल्थ केअर एज्युकेशन फाउंडेशनचे व्हिजन पॅरामेडिकल संस्थान",
  "SHAHID SHIKSHAN PRASARAK MANDAL'S SHAHID SITARAM PATIL COLLEGE OF PG TITAVE":
    "शहीद शिक्षण प्रसारक मंडळाचे शहीद सिताराम पाटील पी.जी.टी.आय.टी.ए.व्ही.ई महाविद्यालय",
  "KHATAV TALUKA SHIVAJI UDAY MANDAL'S SHRI TANAJIRAO DESHMUKH PGDMLT COLLEGE":
    "खटाव तालुका शिवाजी उदय मंडळाचे श्री तनाजीराव देशमुख पी.जी.डी.एम.एल.टी. महाविद्यालय",
  "DESHMUKH SATRE CHARITABLE MEDICAL RESEARCH CENTRE TRUST'S KEDARNATH PARAMEDICAL COLLEGE, ISLAMPUR":
    "देशमुख सात्रे चॅरिटेबल मेडिकल रिसर्च सेंटर ट्रस्टचे केदारनाथ पॅरामेडिकल महाविद्यालय, इस्लामपूर",
  "SANT GAJANAN MAHARAJ TECHNICAL AND PARAMEDICAL COLLEGE":
    "संत गजानन महाराज तांत्रिक आणि पॅरामेडिकल महाविद्यालय",
  "BIOGEN PARAMEDICAL COLLEGE": "बायोजेन पॅरामेडिकल महाविद्यालय",
  "SCHOLARS COLLEGE OF FIRE AND SAFETY ENGINEERING":
    "स्कॉलर्स फायर आणि सुरक्षा अभियांत्रिकी महाविद्यालय",
  "NAYRA TECHNICAL AND PARAMEDICAL INSTITUTE":
    "नायरा तांत्रिक आणि पॅरामेडिकल संस्थान",
  "NATIONAL INSTITUTE OF FIRE AND SAFETY ENGINEERING":
    "राष्ट्रीय अग्निशमन आणि सुरक्षा अभियांत्रिकी संस्थान",
  "NATIONAL FIRE AND SAFETY COLLEGE": "राष्ट्रीय अग्निसुरक्षा महाविद्यालय",
  "YANTRA INSTITUTE OF TECHNOLOGY AND MANAEMENT":
    "यंत्रा तंत्रज्ञान आणि व्यवस्थापन संस्थान",
  "BHANDARA PARAMEDICAL COLLEGE": "भंडारा पॅरामेडिकल महाविद्यालय",
  "GADCHIROLI PARAMEDICAL COLLEGE": "गडचिरोली पॅरामेडिकल महाविद्यालय",
  "ADITYA PARAMEDICAL COLLEGE": "आदित्य पॅरामेडिकल महाविद्यालय",
  "SHREE SAI TECHNICAL AND PARAMEDICAL COLLEGE":
    "श्री साई तांत्रिक आणि पॅरामेडिकल महाविद्यालय",
  "BADAL GADPALE PATHOLOGY COLLEGE": "बादल गडपाले पॅथॉलॉजी महाविद्यालय",
  "SHRI CHATRAPATI SHIVAJI MAHARAJ PARAMEDICAL COLLEGE":
    "श्री छत्रपती शिवाजी महाराज पॅरामेडिकल महाविद्यालय",
  "SANMARG SHIKSHAN SANSTH'S SHRI K. R. PANDAV AYURVED COLLEGE AND HOSPITAL":
    "सनमार्ग शिक्षण संस्थेचे श्री के.आर. पांडव आयुर्वेद महाविद्यालय आणि हॉस्पिटल",
  "AARON INSTITUTE OF FIRE SAFETY": "आरोन फायर सेफ्टी संस्थान",
  "THAKUR PG DMLT COLLEGE": "ठाकूर पी.जी. डी.एम.एल.टी. महाविद्यालय",
  "DR. S. S. GORDE PGDMLT COLLEGE, PATHROT":
    "डॉ.एस.एस.गोरडे पी.जी.डी.एम.एल.टी. महाविद्यालय, पाथ्रोट",
  "VIDARBHA INSTITUTE OF SCIENCE": "विदर्भ विज्ञान महाविद्यालय",
  "SHRI. SHIVAJI COLLEGE OF PARAMEDICAL SCIENCE MAREGAON":
    "श्री. शिवाजी पॅरामेडिकल विज्ञान महाविद्यालय मारेगाव",
  "KHAMGAON PARAMEDICAL COLLEGE": "खामगाव पॅरामेडिकल महाविद्यालय",
  "SHIVKANYA PARAMEDICAL COLLEGE": "शिवकन्या पॅरामेडिकल महाविद्यालय",
  "NATIONAL INSTITUTE OF MEDICAL AND PARAMEDICAL SCIENCES":
    "राष्ट्रीय वैद्यकीय आणि पॅरामेडिकल विज्ञान संस्थान",
  "SAMBHAJI RAJE MANAGEMENT SCIENCE COLLEGE TECHNICAL INSTITUTE":
    "संभाजी राजे व्यवस्थापन विज्ञान महाविद्यालय तांत्रिक संस्थान",
  "MATA VAISHNAV DEVI TECHNICAL INSTITUTE": "माता वैष्णव देवी तांत्रिक संस्थान",
  "SAHYDRI COLLEGE OF PARAMEDICAL SCIENCES":
    "सह्याद्री पॅरामेडिकल विज्ञान महाविद्यालय",
  "SHRI RAVSAHEB DANVE COLLEGE OF ADVANCE DMLT, RAJUR":
    "श्री रावसाहेब दानवे अॅडव्हान्स डी.एम.एल.टी. महाविद्यालय, राजूर",
  "SHASHI FIRE AND SAFETY COLLEGE": "शशी फायर आणि सुरक्षा महाविद्यालय",
  "EKLAVYA VIDYALAYA": "एकलव्य विद्यालय",
  "MAHALAXMI TECHNICAL INSTITUTE PARADH BK":
    "महालक्ष्मी तांत्रिक संस्थान, पाराध बी.के.",
  "SIDDHANT PARAMEDICAL INSTITUTE": "सिद्धांत पॅरामेडिकल संस्थान",
  "SHREE GANESH PARAMEDICAL SCIENCES COLLEGE":
    "श्री गणेश पॅरामेडिकल विज्ञान महाविद्यालय",
  "AMOLAK SKILL DEVELOPMENT CENTER, KADA": "अमोलक स्किल डेव्हलपमेंट सेंटर, कडा",
  "VITHAI PARAMEDICAL TECHNICAL INSTITUTE": "विठाई पॅरामेडिकल तांत्रिक संस्थान",
  "SAHARA DMLT INSTITUTE PARBHANI": "सहारा डी.एम.एल.टी. संस्थान, परभणी",
  "SAI PARAMEDICAL COLLEGE": "साई पॅरामेडिकल महाविद्यालय",
  "UTKARSH TECHNICAL INSTITUTE": "उत्कर्ष तांत्रिक संस्थान",
  "GURUMAULI TECHNICAL INSTITUTE": "गुरुमाऊली तांत्रिक संस्थान",
  "AARAMBH COLLEGE OF FIRE AND SAFETY MANAGEMENT":
    "आरंभ फायर आणि सुरक्षा व्यवस्थापन महाविद्यालय",
  "SHREE GAJANAN MAHARAJ PARAMEDICAL COLLEGE":
    "श्री गजानन महाराज पॅरामेडिकल महाविद्यालय",
  "K K PATIL PARAMEDICAL COLLEGE": "के.के.पाटील पॅरामेडिकल महाविद्यालय",
  "SHRI NAGESHWAR INSTITUTE OF TECHNICAL AND PARAMEDICAL EDUCATION":
    "श्री नागेश्वर तांत्रिक आणि पॅरामेडिकल शिक्षण संस्थान",
  "SHRI SWAMI SMARTH INSTITUTE OF PARAMEDICAL":
    "श्री स्वामी समर्थ पॅरामेडिकल संस्थान",
  "RELIABLE COLLEGE": "रिलायबल महाविद्यालय",
  "DNYANJYOT COLLEGE OF PARAMEDICAL": "ज्ञानज्योत पॅरामेडिकल महाविद्यालय",
  "P G COLLEGE OF PHARMACEUTICAL SCIENCE AND RESEARCH":
    "पी.जी. फार्मास्युटिकल विज्ञान आणि संशोधन महाविद्यालय",
  "INSTITUTE OF PARAMEDICAL SCIENCES": "पॅरामेडिकल विज्ञान संस्थान",
  "SIDDHI INSTITUTE OF PARAMEDICAL SCIENCE AND TECHNOLOGY":
    "सिद्धी पॅरामेडिकल विज्ञान आणि तंत्रज्ञान संस्थान",

  "MAHARASHTRA STATE BOARD OF TECHNICAL EDUCATION":
    "महाराष्ट्र राज्य तंत्रशिक्षण मंडळ",
  "(Autonomous) (ISO 9001:2015) (ISO/IEC 27001:2013)":
    "(स्वायत्त) (ISO 9001:2015) (ISO/IEC 27001:2013)",
  Address: "पत्ता",
  "4th floor, Govt. Polytechnic Building, 49, Kherwadi, Bandra (E), Mumbai- 400 051":
    "चौथा मजला, शासकीय पॉलिटेक्निक इमारत, 49, खेरवाडी, बँड्रा (पूर्व), मुंबई - 400051",
  Phone: "फोन",

  "REGIONAL OFFICES": "प्रादेशिक कार्यालये",
  "Dy.Secretary, Maharashtra State Board of Technical Education, Region Office, 2nd Floor, Govt. Polytechnic Building, 49, Kherwadi, Bandra (East), Mumbai:400051":
    "उपसचिव, महाराष्ट्र राज्य तंत्रशिक्षण मंडळ, प्रादेशिक कार्यालय, दुसरा मजला, शासकीय पॉलिटेक्निक इमारत, 49, खेरवाडी, बँड्रा (पूर्व), मुंबई:400051",

  "Dy.Secretary, Maharashtra State Board of Technical Education, Region Office, 412-E, Shivaji Nager, Bahirat Patil Chowk, Pune-411016":
    "उपसचिव, महाराष्ट्र राज्य तंत्रशिक्षण मंडळ, प्रादेशिक कार्यालय, 412-E, शिवाजी नगर, बहिरट पाटील चौक, पुणे-411016",

  "Dy.Secretary, Maharashtra State Board of Technical Education, Region Office, Sadar, Nagpur-440001":
    "उपसचिव, महाराष्ट्र राज्य तंत्रशिक्षण मंडळ, प्रादेशिक कार्यालय, सदर, नागपूर-440001",

  "Dy.Secretary, Maharashtra State Board of Technical Education, Region Office, Osmanapura, Chatrapati Shambhajinagar-431005":
    "उपसचिव, महाराष्ट्र राज्य तंत्रशिक्षण मंडळ, प्रादेशिक कार्यालय, उस्मानपुरा, छत्रपती संभाजीनगर-431005",

  "Screen Reader Access": "स्क्रीन रीडर प्रवेश",
  "Screen Reader": "स्क्रीन रीडर",
  Website: "वेबसाईट",
  "Free / Commercial": "मोफत / व्यावसायिक",
  "Screen Access For All (SAFA)": "सर्वांसाठी स्क्रीन प्रवेश (SAFA)",
  "Non Visual Desktop Access (NVDA)": "नॉन व्हिज्युअल डेस्कटॉप ऍक्सेस (NVDA)",
  "System Access To Go": "सिस्टम ऍक्सेस टू गो",
  Thunder: "थंडर",
  WebAnywhere: "वेबएनीव्हेयर",
  Hal: "हाल",
  JAWS: "JAWS",
  Supernova: "सुपरनोव्हा",
  "Window-Eyes": "विंडो-आयज",
  Free: "मोफत",
  Commercial: "व्यावसायिक",
};

export default OtherTranslation;
