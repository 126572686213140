import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LatestUpdates from "../Components/HeaderComponent/LatestUpdates";
import TopMenu from "../Components/HeaderComponent/TopMenu";

function Header() {
  const s3Path = process.env.REACT_APP_S3_PATH;
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const headerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const stickyPoint = headerRef.current.offsetTop;

      if (window.pageYOffset > stickyPoint) {
        headerRef.current.classList.add("sticky");
      } else {
        headerRef.current.classList.remove("sticky");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="fixed-header">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-8 col-lg-6 col-sm-12 logoImg">
              <a href="/" className="my-3">
                <img
                  src={s3Path + "/Images1/mainlogo.png"}
                  className="rounded-circle img-fluid"
                  alt="Logo"
                />
                <h3 className="notranslate">
                  Maharashtra State Board of Technical Education, Mumbai <br />
                  <small>महाराष्ट्र राज्य तंत्र शिक्षण मंडळ, मुंबई</small>
                </h3>
                {/* <img
                  src={s3Path + "/Images1/main_secondlogo.png"
                  className="img-fluid px-2 my-auto"
                  alt="Logo"
                /> */}
              </a>
            </div>
            <div className="col-xl-4 col-lg-6 col-sm-12 m-none">
              <div className="row">
                <div className="col-lg-5 col-sm-5 col-7 d-flex twologo logoImg3 justify-content-center">
                  <img
                    src={s3Path + "/Images1/image.png"}
                    className=""
                    alt="Logo"
                  />
                  {/* <img
                    src={s3Path + "/Images1/image1.png"
                    className=""
                    alt="logo3"
                  /> */}
                </div>
                <div className="col-lg-7 col-sm-7 col-5 removePaddingicons">
                  <div className="lastDiv">
                    <div className="pt-3 pb-2 Positionlanguage">
                      <div className="search d-flex mt-2 m-hide">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          onChange={(e) => {
                            setQuery(e.target.value);
                          }}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              navigate(`/search?query=${query}`);
                            }
                          }}
                        />
                        <i
                          className="fa fa-search fa-flip-horizontal serachbarbackground "
                          aria-hidden="true"
                          onClick={() => {
                            navigate(`/search?query=${query}`);
                          }}
                        ></i>
                      </div>
                      <div className="social-media d-flex paddingSocialMedia">
                        <ul className="marginUl d-flex">
                          <li>
                            <a
                              href="https://www.facebook.com/MSBTEMumbai"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={s3Path + "/Images1/facebbok.png"}
                                className="rounded-circle"
                                alt="socialM"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://twitter.com/Cmsbte"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={s3Path + "/Images1/twitter.png"}
                                className="rounded-circle"
                                alt="socialM"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/msbte.mumbai.official"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={s3Path + "/Images1/insta.png"}
                                className="rounded-circle"
                                alt="socialM"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.youtube.com/channel/UCHA-iPS6U4deuneAkNznDfg"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={s3Path + "/Images1/Ytube.png"}
                                className="rounded-circle"
                                alt="socialM"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="headerfix" ref={headerRef}>
          <TopMenu />
          <LatestUpdates />
        </div>
      </div>
    </>
  );
}

export default Header;
