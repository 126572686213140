import React from "react";

function TermsAndCondition() {
  return (
    <div className="card mb-3  borderforcard paddingAboutMsbte">
      <div className="card-header backgroundColorCardHeader">
        <h1 className="fontForHeader custom-translate">TERMS AND CONDITION</h1>
      </div>
      <div className="row g-0">
        <div className="card-body removePadding p-5">
          <p>
            This website is designed, developed and maintained by Maharashtra
            State Board of Technical Education, Mumbai, Government of India.
          </p>

          <p>
            Thought all efforts have been made to ensure the accuracy and
            currency of the content on this website, the same should not be
            construed as a statement of law or used for any legal purposes.
            Incase of any ambiguity or doubts, users are advised to verify/check
            with the Department(s) and/or other source(s), and to obtain
            appropriate professional advice.
          </p>

          <p>
            Under no circumstances will this Department be liable for any
            expense, loss or damage, including without limitation, indirect or
            consequential loss or damage, or any expense, loss or damage
            whatsoever arising from use, or loss of use, of data, arising out of
            or in connection with the use of this website.
          </p>

          <p>
            These terms and condition shall be governed by construed in
            accordance with the Indian laws. Any dispute arising under this
            terms and conditions shall be subject to the jurisdiction of the
            courts of India.
          </p>

          <p>
            The information posted on this website could include hypertext links
            or pointer to information created and maintained by non-Government/
            private organizations. Maharashtra State Board of Technical
            Education, Mumbai , India, is providing these links and pointers
            solely for your Information and convenience. When you select a link
            to an outside website, you are leaving Maharashtra State Board of
            Technical Education, Mumbai , India, website and are subject to the
            privacy and security policies of the owners/sponsors of the outside
            website.
          </p>

          <p>
            Maharashtra State Board of Technical Education, Mumbai, India, does
            not guarantee the availability such a linked pages at all times.
          </p>

          <p>
            Maharashtra State Board of Technical Education, Mumbai , India,
            cannot authorize the use of copyrighted materials Contained in
            linked website. Users are advised to request such authorization from
            the owner of the linked website.
          </p>
          <p>
            Maharashtra State Board of Technical Education, Mumbai , India, does
            not guarantee that linked websites comply with Indian Government Web
            Guidelines.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndCondition;
