/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

function EquivalenceOtherBoard() {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState("");
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const s3Path = process.env.REACT_APP_S3_PATH;

  return (
    <>
      <div className="row ">
        <div className="col-sm-12">
          <div className="card border border-light shadow-0 mb-3 RemoverBorderRadius">
            <div className="card-header backgroundColorCardHeader">
              <h1 className="fontForHeader custom-translate">
                EQUIVALENCE (OTHER BOARD)
              </h1>
            </div>
            <div className="card-body removePadding">
              <div className="row p-2">
                <p>
                  <Link
                    className="link-primary"
                    aria-disabled="true"
                    onClick={() => {
                      handleShow();
                      setFile(s3Path + "/files/Equivalence.pdf");
                    }}
                  >
                    Application for Equivalence Certificate (Other Board) pdf
                  </Link>
                </p>
                <br />
                <p>
                  <Link
                    className="link-primary"
                    aria-disabled="true"
                    onClick={() => {
                      handleShow();
                      setFile(
                        s3Path + "/files/EquivalenceOtherBoard105927052022.pdf"
                      );
                    }}
                  >
                    Procedure for Equivalence (Other Board) pdf
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        contentClassName="modal-pdf-content"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body closeButton>
          <iframe title="myFrame" className="w-100 h-100" src={file}></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EquivalenceOtherBoard;
