const Institutes = [
  { region: "Mumbai", instcode: "2", instName: "M.H. SABOO SIDDIK" },
  { region: "Mumbai", instcode: "4", instName: "V.E.S. POLYTECHNIC" },
  {
    region: "Mumbai",
    instcode: "7",
    instName: "VIDYA PRASARAK MANDALS POLYTECHNIC",
  },
  { region: "Pune", instcode: "11", instName: "GOVERNMENT POLYTECHNIC" },
  {
    region: "Mumbai",
    instcode: "27",
    instName: "BHARATI VIDYAPEETH INST.OF TECHNOLOGY NAVI MUMBAI",
  },
  {
    region: "CSN",
    instcode: "30",
    instName:
      "P. DR V.V.P. INSTITUTE OF TECHNOLOGY & ENGINEERING (POLYTECHNIC), PRAVARANAGAR",
  },
  {
    region: "Pune",
    instcode: "47",
    instName:
      "SHRI.PRINCE SHIVAJI MARATHA BOARDING HOUSE'S NEW INSTITUTE OF TECHNOLOGY, KOLHAPUR",
  },
  {
    region: "CSN",
    instcode: "69",
    instName: "GRAMIN TECHNICAL AND MANGEMENT CAMPUS",
  },
  {
    region: "CSN",
    instcode: "78",
    instName: "KARMAVEER KAKASAHEB WAGH POLYTECHINC",
  },
  {
    region: "CSN",
    instcode: "79",
    instName: "SNJB'S SHRI HIRALAL HASTIMAL(JAIN BROTHERS,JALGAON) POLYTECHNIC",
  },
  {
    region: "Nagpur",
    instcode: "107",
    instName: "G H RAISONI COLLEGE OF ENGINEERING & MANAGEMENT",
  },
  { region: "Mumbai", instcode: "108", instName: "Sahyadri Polytechnic" },
  { region: "Nagpur", instcode: "138", instName: "SHRI SAI POLYTECHNIC" },
  {
    region: "Mumbai",
    instcode: "225",
    instName: "Navyug Vidyapeeth Trust's Pharmacy College",
  },
  {
    region: "Nagpur",
    instcode: "353",
    instName: "INDIAN INSTITUTE OF FIRE ENGINEERING",
  },
  {
    region: "Mumbai",
    instcode: "356",
    instName: "MAHARASHTRA INSTITUTE OF MEDICAL SCIENCE & TECHNOLOGY",
  },
  {
    region: "Mumbai",
    instcode: "358",
    instName: "JBSPS Instt. of Industrial Safety",
  },
  {
    region: "Mumbai",
    instcode: "372",
    instName: "Tarapur Mngt.Assoc.Instt.Ofmngt.Studiestvmschool&jr.College",
  },
  { region: "Mumbai", instcode: "405", instName: "KALA VIDYA SANKUL" },
  {
    region: "Mumbai",
    instcode: "408",
    instName: "THANE BELAPUR INDUSTRIES ASSOCIATION",
  },
  { region: "Mumbai", instcode: "410", instName: "Academy Of Applied Art" },
  {
    region: "Nagpur",
    instcode: "411",
    instName: "Mahila Institute Of Garment Technology",
  },
  { region: "Mumbai", instcode: "413", instName: "SCHOOL OF INTERIOR DESIGN" },
  { region: "Mumbai", instcode: "414", instName: "CENTRAL LABOUR INSTITUTE" },
  {
    region: "Mumbai",
    instcode: "420",
    instName: "L.S.Raheja School Of Architecture",
  },
  {
    region: "Pune",
    instcode: "427",
    instName:
      "MARATHWADA MITRA MANDAL'S MARATHWADA MITRA MANDAL SCHOOL OF INTERIOR DESIGN",
  },
  { region: "Pune", instcode: "434", instName: "NATIONAL SAFETY ORGANISATION" },
  {
    region: "Pune",
    instcode: "436",
    instName: "DECCAN EDUCATION SOCIETY'S TECHNICAL INSTITUTE, PUNE",
  },
  {
    region: "Nagpur",
    instcode: "447",
    instName: "W. TECH. ED. & RESEARCH SMT.R. PUROHIT INSTITUTE",
  },
  {
    region: "Mumbai",
    instcode: "493",
    instName: "Smt. S.C Nanavati Institute of Polytechnic",
  },
  {
    region: "Nagpur",
    instcode: "499",
    instName: "PATALDHAMAL WADHWANI PHARMACY COLLEGE",
  },
  { region: "Mumbai", instcode: "543", instName: "M.M.A. Technical Institute" },
  { region: "Mumbai", instcode: "564", instName: "DEVI MAHALAXMI COLLEGE" },
  { region: "Mumbai", instcode: "565", instName: "Tata Memorial Hospital" },
  {
    region: "Pune",
    instcode: "574",
    instName:
      "SURYADATTA EDUCATION FOUNDATION'S PUNE INSTITUTE OF APPLIED TECHNOLOGY",
  },
  {
    region: "Nagpur",
    instcode: "575",
    instName: "SHREE SAINATH INSTT. OF LABORATORY MEDICINE",
  },
  {
    region: "Nagpur",
    instcode: "577",
    instName: "S.S.G.V.S'S NATIONAL INSTITUTE OF FIRE ENGINEERING",
  },
  { region: "Nagpur", instcode: "579", instName: "DEVI MAHALAXMI COLLEGE" },
  { region: "CSN", instcode: "583", instName: "SANTOSH N. DARADE POLYTECHNIC" },
  {
    region: "Pune",
    instcode: "589",
    instName: "ANJUMAN-I-ISLAM'S POLYTECHNIC FOR GIRLS",
  },
  {
    region: "Pune",
    instcode: "590",
    instName:
      "MAHARSHI KARVE STREE SHIKSHAN SANSTHA'S SHRI MANILAL NANAVATI VOCATIONAL TRAINING INSTITUTE FOR WOMEN",
  },
  {
    region: "Nagpur",
    instcode: "592",
    instName: "CANCER RELIEF SOC.'S RASHTRASANT TUKDOJIREGIONAL CANCER HOSP",
  },
  {
    region: "Mumbai",
    instcode: "593",
    instName: "S.I.E.S. COLLEGE OF ARTS, SCIENCE &COMMERCE",
  },
  { region: "Mumbai", instcode: "594", instName: "MODERN COLLEGE" },
  {
    region: "Mumbai",
    instcode: "597",
    instName: "B. K. BIRLA COLLEGE OF ARTS, SCIENCE AND COMMERCE",
  },
  {
    region: "Mumbai",
    instcode: "598",
    instName: "NSCBMT'S NETAJI SUBHASH CHANDRA INSTITUTE",
  },
  {
    region: "CSN",
    instcode: "599",
    instName: "SWAMI VIVEKANAND OF PARAMEDICAL SCIENCE",
  },
  {
    region: "Mumbai",
    instcode: "600",
    instName: "All India Instt. of Local Self Government",
  },
  {
    region: "Pune",
    instcode: "604",
    instName: "DECCAN TECHNOLOGY TRAINING INSTITUTE",
  },
  {
    region: "Mumbai",
    instcode: "616",
    instName:
      "Shri Vitthalrao Joshi Chairtable Trust's College Of Advanced Studies",
  },
  {
    region: "Pune",
    instcode: "617",
    instName: "JEEVAN SHIKSHAN SANSTHA 'S MAHADEO ANANDA PATIL PGDMLT COLLEGE",
  },
  {
    region: "Pune",
    instcode: "668",
    instName:
      "BHAVANA EDUCATION SOCIETY'S BHAVANA SCHOOL OF INTERIOR DESIGN & DECORATION",
  },
  {
    region: "CSN",
    instcode: "678",
    instName: "INSTITUTE OF PARAMEDICAL SCIENCE PGDMLT COLLEGE",
  },
  {
    region: "Pune",
    instcode: "687",
    instName:
      "SHRI VASANTRAO BANDUJI PATIL TRUST'S APPASAHEB BIRNALE COLLEGE OF ARCHITECTURE",
  },
  {
    region: "CSN",
    instcode: "704",
    instName: "COLLEGE OF FIRE ENGG. & SAFETY MANAGEMENT",
  },
  {
    region: "CSN",
    instcode: "705",
    instName: "RAJARSHI SHAHU INSTITUTE OF MANAGEMENT & TECHNOLOGY",
  },
  {
    region: "CSN",
    instcode: "713",
    instName: "Vidyasagar Post Graduate Institute",
  },
  {
    region: "Mumbai",
    instcode: "722",
    instName: "Bhartiya Edu Society Inst of Technical Edu",
  },
  { region: "CSN", instcode: "733", instName: "Gajanan Technical Instt" },
  {
    region: "CSN",
    instcode: "736",
    instName: "DHANWANTARI COLLEGE OF ENGINEERING",
  },
  {
    region: "Pune",
    instcode: "737",
    instName:
      "WALWA TALUKA EDUCATION SOCIETY'S S. D. PATIL INSTITUTE OF PHARMACY",
  },
  {
    region: "Nagpur",
    instcode: "743",
    instName: "MAHARASHTRA INSTITUTE OF PHARMACY",
  },
  {
    region: "Pune",
    instcode: "746",
    instName:
      "SHRI DHANLAXMI FOUNDATION'S LATE DADASAHEB CHAVAN MEMORIAL INSTITUTE OF PHARMACY",
  },
  {
    region: "Pune",
    instcode: "749",
    instName:
      "POONA DISTRICT EDUCATION ASSOCIATION'S SHANKARRAO URSAL COLLEGE OF PHARMACY",
  },
  {
    region: "CSN",
    instcode: "801",
    instName:
      "SANKALP BAHU UDDESHIYA JANAKALYAN MANDALS SAKRI INSTITUTE OF TECHNOLOGY",
  },
  {
    region: "Nagpur",
    instcode: "802",
    instName: "WAINGANGA TECHNICAL INSTITUTE",
  },
  {
    region: "Nagpur",
    instcode: "806",
    instName: "SHARWANI COLLEGE OF FIRE ENGG",
  },
  {
    region: "Mumbai",
    instcode: "835",
    instName: "Dr. Bhanuben Mahendra Nanavati College of Home Science",
  },
  {
    region: "Nagpur",
    instcode: "859",
    instName: "MOHANLALJI WALCHALE PGDMLT COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "934",
    instName: "INSTITUTE OF FIRE & SAFETY MANAGEMENT",
  },
  {
    region: "Mumbai",
    instcode: "937",
    instName: "St. John's Institute of Technology & Management",
  },
  {
    region: "Nagpur",
    instcode: "976",
    instName:
      "INDUTAI MEMORIAL DIPLOMA IN FIRE SERVICE ENGINEERING & DIPLOMA IN DENTAL TECHNOLOGY",
  },
  {
    region: "Pune",
    instcode: "985",
    instName: "SHIRISH ACADEMY FOR KNOWLEDGE OF HEALTH INVESTIGATION",
  },
  {
    region: "Pune",
    instcode: "988",
    instName: "ZEAL EDUCATION SOCIETY'S ZEAL POLYTECHNIC",
  },
  {
    region: "Pune",
    instcode: "997",
    instName:
      "INDIA FIRE SAFETY EDUCATION SOCIETY'S COLLEGE OF FIRE ENGINEERING & SAFETY MANAGEMENT",
  },
  {
    region: "CSN",
    instcode: "1007",
    instName: "SAHYADRI COLLEGE OF FIRE ENGINEERING AND SAFETY MANAGEMENT",
  },
  {
    region: "Nagpur",
    instcode: "1042",
    instName: "D.D. BHOYAR TECHNICAL INSTITUTE",
  },
  {
    region: "CSN",
    instcode: "1097",
    instName: "DHULE INSTT. OF TECH COLLEGE OF FIRE AND SAFETY ENGG.",
  },
  {
    region: "CSN",
    instcode: "1099",
    instName: "NATIONAL FIRE SAFETY MANAGEMENT COLLEGE",
  },
  {
    region: "CSN",
    instcode: "1108",
    instName: "Dnyanganga Institute of Technology",
  },
  { region: "CSN", instcode: "1164", instName: "SONIYA GANDHI POLYTECHNIC" },
  {
    region: "CSN",
    instcode: "1171",
    instName: "MATOSHRI INSTITUTE OF TECHNOLOGY",
  },
  { region: "Nagpur", instcode: "1194", instName: "SHRI.SAI TAJ POLYTECHNIC" },
  {
    region: "Pune",
    instcode: "1229",
    instName:
      "SOU.SUSHILA D.GHODAWAT CHARITABLE TRUST'S LATE SOU SUSHILA DANCHAND GHODAWAT",
  },
  {
    region: "Nagpur",
    instcode: "1362",
    instName: "SHANTINIKETAN COLLEGE OF FIRE AND SAFETY ENGINEERING",
  },
  {
    region: "Nagpur",
    instcode: "1366",
    instName: "NEW ENGLISH INSTITUTE OF TECHNOLOGY",
  },
  {
    region: "CSN",
    instcode: "1401",
    instName: "Vishwa Bahuudeshiay Sevabhavi Santha",
  },
  {
    region: "CSN",
    instcode: "1434",
    instName:
      "ALLANA MOHD.TAHER EDU & CAREER FOUNDN INST OF PARAMEDICAL SCIENCES & TECH",
  },
  {
    region: "CSN",
    instcode: "1472",
    instName: "AJITDADA PAWAR COLLEGE OF POLYTECHNIC",
  },
  {
    region: "Nagpur",
    instcode: "1539",
    instName: "MATA MAHAKALI COLLEGE OF FIRE ENGINEERING",
  },
  {
    region: "Mumbai",
    instcode: "1581",
    instName: "TCT'S SMT.GEETA D. TATKARE POLYTECHNIC",
  },
  {
    region: "Nagpur",
    instcode: "1582",
    instName: "ABSS'S INSTITUTE OF FIRE & SAFETY ENGINEERING",
  },
  { region: "Pune", instcode: "1644", instName: "SANJAY GHODAWAT INSTITUTE" },
  {
    region: "Mumbai",
    instcode: "1680",
    instName: "DEVI MAHALAXMI POLYTECHNIC COLLEGE",
  },
  {
    region: "CSN",
    instcode: "1701",
    instName: "DAYANAND INSTITUTE OF TECHNICAL EDUCATION, LATUR",
  },
  { region: "Mumbai", instcode: "1732", instName: "MUMBAI CARRER ACADEMY" },
  {
    region: "Mumbai",
    instcode: "1736",
    instName: "MAHARASHTRA POLYTECHNIC,MANDANGAD",
  },
  {
    region: "CSN",
    instcode: "1776",
    instName: "KALYANI CHARITABLE TRUST'S R.G.SAPKAL INSTITUTE OF PHARMACY",
  },
  {
    region: "Nagpur",
    instcode: "1783",
    instName: "GAWANDE COLLEGE OF PHARMACY",
  },
  {
    region: "CSN",
    instcode: "1791",
    instName: "SHRI RAJSHRI SHAHU INSTITUTE OF MANAGEMENT",
  },
  {
    region: "Pune",
    instcode: "1799",
    instName:
      "NATIONAL HEALTH LAW RESEARCH INSTITUTE'S NOOTAN COLLEGE OF PHARMACY",
  },
  {
    region: "Pune",
    instcode: "1813",
    instName: "SAHYADRI CHARITABLE TRUST'S EKLAVYA COLLEGE OF PHARMACY",
  },
  {
    region: "Pune",
    instcode: "1824",
    instName: "ANANDI SHIKSHAN PRASARAK MANDAL'S ANANDI PHARMACY COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "1827",
    instName: "BAMBOO RESEARCH AND TRAINING CENTER CHINCHPALI",
  },
  { region: "CSN", instcode: "1832", instName: "VSS INSTITUTE OF PHARMACY" },
  { region: "Nagpur", instcode: "1833", instName: "MES COLLEGE OF PHARMACY" },
  {
    region: "CSN",
    instcode: "1845",
    instName: "SHRI RAMKRISHANA PARAMHANS COLLEGE OF PHARMACY (D-PHARM)",
  },
  {
    region: "Nagpur",
    instcode: "1851",
    instName:
      "YAVATMAL ZILLA VIKAS SAMITI'S PATALDHAMAL WADHWANI COLLEGE OF PHARMACY",
  },
  {
    region: "CSN",
    instcode: "1868",
    instName: "SWAMI VIVEKANAND COLLEGE OF PHARMACY",
  },
  {
    region: "Pune",
    instcode: "1903",
    instName: "FABTECH EDUCATION SOCIETY'S FABTECH COLLEGE OF PHARMACY",
  },
  {
    region: "CSN",
    instcode: "1924",
    instName: "MRS. SARASWATI WANI COLLEGE OF D.PHARMACY",
  },
  {
    region: "Pune",
    instcode: "1929",
    instName: "VAKRATUND EDUCATION SOCIETY'S GENESIS INSTITUTE OF PHARMACY",
  },
  {
    region: "CSN",
    instcode: "1932",
    instName: "JGVVSS'S SUYASH COLLEGE OF PHARMACY",
  },
  {
    region: "Nagpur",
    instcode: "1987",
    instName: "MAHARASHTRA INSTITUTE OF PHARMACY",
  },
  {
    region: "CSN",
    instcode: "1991",
    instName: "ASHVIN COLLEGE OF PHARMACY MANCHI HILL",
  },
  {
    region: "Nagpur",
    instcode: "2005",
    instName:
      "DR. R. G. BHOYAR INSTITUTE OF PHARMACEUTICAL EDUCATION AND RESEARCH",
  },
  {
    region: "Pune",
    instcode: "2015",
    instName:
      "DR. C.H. KAWCHALE CHARITABLE TRUST'S MODERN INSTITUTE OF PARAMEDICAL TECHNOLOGY",
  },
  {
    region: "CSN",
    instcode: "2046",
    instName: "APEX TECHNICAL INSTITUTE PANGRI",
  },
  { region: "CSN", instcode: "2047", instName: "MAHESH PARAMEDICAL COLLEGE" },
  {
    region: "CSN",
    instcode: "2048",
    instName: "DR. SUBHASHRAO DHAKNE COLLEGE OF DMLT",
  },
  {
    region: "CSN",
    instcode: "2049",
    instName:
      "BHASKARRAO DESHPANDE INSTITUTE OF MEDICAL SCIENCE AND TECHNOLOGY",
  },
  {
    region: "CSN",
    instcode: "2050",
    instName: "Jalgaon Institute of Technology",
  },
  {
    region: "CSN",
    instcode: "2051",
    instName: "Shri Saibaba Paramedical Institute , Darewadi",
  },
  {
    region: "CSN",
    instcode: "2053",
    instName:
      "Vidyadhan DMLT College Valwadi Dhule Sharda Shaikshanik And Samajik Sanstha",
  },
  {
    region: "CSN",
    instcode: "2054",
    instName: "BHOJANE'S ADVANCED PARAMEDICAL INSTITUTE",
  },
  {
    region: "CSN",
    instcode: "2055",
    instName: "ADARSH PARAMEDICAL VOCATIONAL TRAINING INSTITUTE",
  },
  {
    region: "CSN",
    instcode: "2056",
    instName: "Gramin Polytechnics Shivramji Pawar Technical Institute",
  },
  {
    region: "CSN",
    instcode: "2057",
    instName:
      "AMTEC FOUNDATION'S INSTITUTE OF PARAMEDICAL SCIENCE & TECHNOLOGY",
  },
  {
    region: "CSN",
    instcode: "2058",
    instName: "JIJAMATA HEALTH AND TECHNICAL EDUCATION INSTITUTE",
  },
  {
    region: "CSN",
    instcode: "2059",
    instName: "SHIVAROGYA PARAMEDICAL INSTITUTE GEORAI",
  },
  { region: "CSN", instcode: "2060", instName: "S P TECHNICAL INSTITUTE" },
  {
    region: "Pune",
    instcode: "2061",
    instName:
      "SHREE CHHATRAPATI SHIVAJI SHIKSHAN & SANSHODHAN SANSTHA'S ADARSH PARAMEDICAL INSTITUTE",
  },
  {
    region: "Pune",
    instcode: "2062",
    instName:
      "CHANDRALOP EDUCATIONAL AND RESEARCH CENTER CHANDRALOP COLLEGE OF FIRE ENGINEERING AND SAFETY MANAGEMENT",
  },
  {
    region: "Mumbai",
    instcode: "2064",
    instName:
      "VIVEKANAND EDUCATION SOCIETY'S COLLEGE OF ARCHITECTURE SCHOOL OF DESIGN",
  },
  { region: "CSN", instcode: "2066", instName: "SIDDHI PARAMEDICAL INSTITUTE" },
  {
    region: "CSN",
    instcode: "2068",
    instName: "RAJARSHI SHAHU COLLEGE OF PARAMEDICAL SCIENCE",
  },
  {
    region: "Pune",
    instcode: "2069",
    instName:
      "SAMARTH RURAL EDUCATIONAL INSTITUTE'S SAMARTH INSTITUTE OF PARAMEDICAL SCIENCES",
  },
  {
    region: "Pune",
    instcode: "2071",
    instName:
      "MANJU TECHNICAL INSTITUTE AND COMPUTER CENTER'S MANJU INSTITUTE OF TECHNOLOGY",
  },
  {
    region: "CSN",
    instcode: "2073",
    instName: "YESHWANTRAO CHAVAN COLLEGE OF POLYTECHNIC BHOKARDAN",
  },
  {
    region: "CSN",
    instcode: "2074",
    instName:
      "RAJESH BHAIYYA TOPE INSTITUTE OF PARAMEDICAL SCIENCE AND FIRE SAFETY MANAGEMENT AURANGABAD",
  },
  {
    region: "CSN",
    instcode: "2075",
    instName:
      "SWAMI VIVEKANAND INSTITUTE OF PARAMEDICAL SCIENCE AND FIRE SAFETY MANAGEMENT AURANGABAD",
  },
  {
    region: "CSN",
    instcode: "2076",
    instName: "DNYANGANGOTRI INSTITUTE OF TECHNOLOGY",
  },
  {
    region: "CSN",
    instcode: "2077",
    instName: "SHREE GANPATI TECHNICAL INSTITUTION",
  },
  {
    region: "CSN",
    instcode: "2078",
    instName: "SHIVAJIRAO PAWAR INSTITUTE OF PARAMEDICAL TECHNOLOGY",
  },
  {
    region: "CSN",
    instcode: "2079",
    instName: "YEOLA COLLEGE OF PARAMEDICAL SCIENCE YEOLA",
  },
  {
    region: "CSN",
    instcode: "2080",
    instName: "Dindori College of Paramedical Science",
  },
  { region: "CSN", instcode: "2081", instName: "V R PARAMEDICAL COLLEGE" },
  {
    region: "CSN",
    instcode: "2082",
    instName: "BALWANTRAO CHAVAN COLLEGE OF PARA MEDICAL AND HEALTH TECHNOLOGY",
  },
  { region: "CSN", instcode: "2083", instName: "SHREE PARAM SADGURU COLLEGE" },
  { region: "CSN", instcode: "2084", instName: "SHIVAJI COLLEGE" },
  {
    region: "CSN",
    instcode: "2085",
    instName: "SAI CARE PARAMEDICAL INSTITUTE NASHIK",
  },
  {
    region: "CSN",
    instcode: "2086",
    instName: "SAI CARE COLLAGE OF FIRE & INDUSTRIAL SAFETY NASHIK",
  },
  {
    region: "Nagpur",
    instcode: "2087",
    instName: "DNYANRAJ MAULI INSTITUTE OF MEDICAL SCIENCE & TECHNOLOGY",
  },
  {
    region: "Nagpur",
    instcode: "2088",
    instName: "AYUSHMANS MEDICAL PARAMEDICAL COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "2089",
    instName: "Nirmal Institute of Paramedicals",
  },
  {
    region: "Nagpur",
    instcode: "2090",
    instName: "INSTITUTE OF FIRE ENGINEERS NAGPUR",
  },
  {
    region: "Nagpur",
    instcode: "2091",
    instName: "SKILL PARAMEDICAL COLLEGE BULDANA",
  },
  {
    region: "Nagpur",
    instcode: "2092",
    instName: "ANURADHA PARAMEDICAL COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "2093",
    instName: "SANMATI PARAMEDICAL COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "2094",
    instName: "MAULI INSTITUTE OF PARAMEDICAL COLLEGE",
  },
  {
    region: "Pune",
    instcode: "2095",
    instName:
      "PURANDHAR TECHNICAL EDUCATION SOCIETY'S PUNE CAMBRIDGE TECHNICAL INSTITUTE",
  },
  {
    region: "Pune",
    instcode: "2096",
    instName:
      "SIDDHAM INNOVATION & BUSINESS INCUBATION CENTER'S SIBIC INSTITUTE OF TECHNOLOGY",
  },
  {
    region: "Pune",
    instcode: "2099",
    instName:
      "SHRI VITTHAL DNYANPEETH AROGYA SHAIKSHANIK PRASARAK SANSTHA'S V. R. PARAMEDICAL COLLEGE",
  },
  {
    region: "CSN",
    instcode: "2100",
    instName: "RAMLING VIGHNE TECHNICAL INSTITUTE",
  },
  { region: "CSN", instcode: "2101", instName: "DNYANPRAKASH COLLEGE" },
  {
    region: "Mumbai",
    instcode: "2102",
    instName: "PARMANAND COLLEGE OF FIRE ENG. & SAFETY MANAGEMENT",
  },
  {
    region: "Mumbai",
    instcode: "2103",
    instName: "RAMRAJE INSTITUTE OF FIRE AND SAFETY",
  },
  {
    region: "Mumbai",
    instcode: "2104",
    instName: "AVG INSTITUTE OF SKILL DEVELOPMENT",
  },
  {
    region: "Mumbai",
    instcode: "2105",
    instName: "PRAGATI EDUCATIONAL INSTITUTE",
  },
  { region: "Nagpur", instcode: "2106", instName: "MG PARAMEDICAL INSTITUTE" },
  {
    region: "Nagpur",
    instcode: "2107",
    instName: "ASIAN INSTITUTE OF FIRE SAFETY",
  },
  {
    region: "Nagpur",
    instcode: "2108",
    instName: "NATIONAL COLLEGE OF FIRE AND SAFETY",
  },
  {
    region: "Mumbai",
    instcode: "2109",
    instName: "MAULI EDUCATION SOCIETY MANGAON INSTITUTE OF TECHNOLOGY",
  },
  {
    region: "CSN",
    instcode: "2110",
    instName: "RUSHIKESH INSTITUTE OF MANAGEMENT AND TECHNOLOGY",
  },
  {
    region: "CSN",
    instcode: "2112",
    instName: "OM INSTITUTE OF MANAGEMENT AND TECHNOLOGY",
  },
  {
    region: "Nagpur",
    instcode: "2113",
    instName: "UJJWAL TECHNICAL INSTITUTE",
  },
  {
    region: "CSN",
    instcode: "2115",
    instName: "SIDDHI INSTITUTE OF PARAMEDICAL",
  },
  {
    region: "CSN",
    instcode: "2116",
    instName: "SAMARTH INSTITUTE OF PARAMEDICAL SCIENCE SANGAMNER",
  },
  { region: "CSN", instcode: "2117", instName: "RENUKA TECHNICAL INSTITUTE" },
  { region: "CSN", instcode: "2118", instName: "DMLT FIRE SAFETY COLLEGE" },
  {
    region: "Nagpur",
    instcode: "2119",
    instName:
      "ARYAVRAT PYARAMEDICAL VYAVSAIK SHIKSHAN SYAMEOJAGAR SHIKSHAN MANDAL",
  },
  {
    region: "Nagpur",
    instcode: "2120",
    instName: "VIDHARBHA TECHNICAL COLLEGE",
  },
  {
    region: "CSN",
    instcode: "2121",
    instName: "MATOSHRI DR KANCHAN SHANTILALJI DESARDA PARAMEDICAL COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "2122",
    instName: "MAHATMA PHULE PARAMEDICAL COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "2123",
    instName: "LATE SHRI S B PURUSHOTTAM COLLEGE OF FIRE ENGINEERING",
  },
  {
    region: "Nagpur",
    instcode: "2124",
    instName:
      "Institute of Safety and Environment, Sindhi Social Service Society",
  },
  {
    region: "Nagpur",
    instcode: "2125",
    instName:
      "Aaron Institute of Fire Safety, Rajiv Gandhi Kanya Mahavidyalaya",
  },
  {
    region: "Nagpur",
    instcode: "2126",
    instName:
      "Aaron Institute of Fire Safety, Rajiv Gandhi Kanya Mahavidyalaya",
  },
  {
    region: "CSN",
    instcode: "2128",
    instName: "SAMARTH PARAMEDICAL SCIENCES, SAMARTH PARAMEDICAL SCIENCES",
  },
  {
    region: "CSN",
    instcode: "2131",
    instName:
      "R G TECHNICAL INSTITUTE, BALEPIR, NAGAR ROAD BEED TQ & DIST BEED",
  },
  {
    region: "CSN",
    instcode: "2132",
    instName: "DR A P J ABDUL KALAM SKILL DEVELOPMENT AND RESEARCH",
  },
  {
    region: "CSN",
    instcode: "2133",
    instName: "RAJARSHI SHAHU INSTITUTE OF FIRE AND SAFETY MANAGEMENT",
  },
  {
    region: "CSN",
    instcode: "2134",
    instName:
      "Indira Institute of Paramedical Sciences, Indira Hospital,Khade Nagar",
  },
  {
    region: "CSN",
    instcode: "2135",
    instName: "AARAMBH COLLEGE OF FIRE AND SAFETY MANAGEMENT NASHIK",
  },
  {
    region: "CSN",
    instcode: "2136",
    instName: "Yash Institute of Paramedical Science and Technology",
  },
  {
    region: "CSN",
    instcode: "2137",
    instName:
      "Nashik College of Paramedical Science and Fire Industrial Safety Nashik",
  },
  {
    region: "Mumbai",
    instcode: "13298",
    instName: "ASIAN INSTITUTE OF FIRE SAFETY, PANVEL",
  },
  {
    region: "Mumbai",
    instcode: "13299",
    instName: "ALL INDIA INSTITUTE OF LOCAL SELF GOVERNMENT",
  },
  {
    region: "Pune",
    instcode: "23442",
    instName: "ASK FOUNDATION'S SUDA INSTITUTE OF FIRE AND SAFETY",
  },
  {
    region: "Pune",
    instcode: "23443",
    instName:
      "MODERN INSTITUTE OF TECHNOLOGY(MIT) DNYANDEEP PARAMEDICAL INSTITUTE",
  },
  {
    region: "Pune",
    instcode: "23444",
    instName: "SANKALP PRATISHTHAN'S SANKALP EDUCATIONAL INSTITUTE",
  },
  {
    region: "Pune",
    instcode: "23445",
    instName: "SHRIBSS ATHARVA PG DMLT TECHNICAL COLLEGE KOROCHI",
  },
  {
    region: "Pune",
    instcode: "23446",
    instName: "SARHAD INSTITUTE OF MANAGEMENT AND TECHNOLOGY",
  },
  {
    region: "Pune",
    instcode: "23447",
    instName:
      "VISION HEALTH CARE EDUCATION FOUNDATION'S VISION PARAMEDICAL INSTITUTE",
  },
  {
    region: "Pune",
    instcode: "23448",
    instName:
      "SHAHID SHIKSHAN PRASARAK MANDAL'S SHAHID SITARAM PATIL COLLEGE OF PG TITAVE",
  },
  {
    region: "Pune",
    instcode: "23449",
    instName:
      "KHATAV TALUKA SHIVAJI UDAY MANDAL'S SHRI TANAJIRAO DESHMUKH PGDMLT COLLEGE",
  },
  {
    region: "Pune",
    instcode: "23450",
    instName:
      "DESHMUKH SATRE CHARITABLE MEDICAL RESEARCH CENTRE TRUST'S KEDARNATH PARAMEDICAL COLLEGE, ISLAMPUR",
  },
  {
    region: "Nagpur",
    instcode: "33559",
    instName: "SANT GAJANAN MAHARAJ TECHNICAL AND PARAMEDICAL COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "33560",
    instName: "BIOGEN PARAMEDICAL COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "33561",
    instName: "SCHOLARS COLLEGE OF FIRE AND SAFETY ENGINEERING",
  },
  {
    region: "Nagpur",
    instcode: "33564",
    instName: "NAYRA TECHNICAL AND PARAMEDICAL INSTITUTE",
  },
  {
    region: "Nagpur",
    instcode: "33565",
    instName: "NATIONAL INSTITUTE OF FIRE AND SAFETY ENGINEERING",
  },
  {
    region: "Nagpur",
    instcode: "33566",
    instName: "NATIONAL FIRE AND SAFETY COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "33567",
    instName: "YANTRA INSTITUTE OF TECHNOLOGY AND MANAEMENT",
  },
  {
    region: "Nagpur",
    instcode: "33569",
    instName: "BHANDARA PARAMEDICAL COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "33570",
    instName: "GADCHIROLI PARAMEDICAL COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "33571",
    instName: "ADITYA PARAMEDICAL COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "33572",
    instName: "SHREE SAI TECHNICAL AND PARAMEDICAL COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "33574",
    instName: "BADAL GADPALE PATHOLOGY COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "33575",
    instName: "SHRI CHATRAPATI SHIVAJI MAHARAJ PARAMEDICAL COLLEGE",
  },
  {
    region: "Nagpur",
    instcode: "33576",
    instName:
      "SANMARG SHIKSHAN SANSTH'S SHRI K. R. PANDAV AYURVED COLLEGE AND HOSPITAL",
  },
  {
    region: "Nagpur",
    instcode: "33577",
    instName: "AARON INSTITUTE OF FIRE SAFETY",
  },
  { region: "Nagpur", instcode: "43228", instName: "THAKUR PG DMLT COLLEGE" },
  {
    region: "Nagpur",
    instcode: "43229",
    instName: "DR. S. S. GORDE PGDMLT COLLEGE, PATHROT",
  },
  {
    region: "Nagpur",
    instcode: "43230",
    instName: "VIDARBHA INSTITUTE OF SCIENCE",
  },
  {
    region: "Nagpur",
    instcode: "43231",
    instName: "SHRI. SHIVAJI COLLEGE OF PARAMEDICAL SCIENCE MAREGAON",
  },
  {
    region: "Nagpur",
    instcode: "43232",
    instName: "KHAMGAON PARAMEDICAL COLLEGE",
  },
  { region: "CSN", instcode: "53336", instName: "SHIVAJI COLLEGE" },
  {
    region: "CSN",
    instcode: "53337",
    instName: "SHIVKANYA PARAMEDICAL COLLEGE",
  },
  {
    region: "CSN",
    instcode: "53338",
    instName: "NATIONAL INSTITUTE OF MEDICAL AND PARAMEDICAL SCIENCES",
  },
  {
    region: "CSN",
    instcode: "53339",
    instName: "SAMBHAJI RAJE MANAGEMENT SCIENCE COLLEGE TECHNICAL INSTITUTE",
  },
  {
    region: "CSN",
    instcode: "53340",
    instName: "MATA VAISHNAV DEVI TECHNICAL INSTITUTE",
  },
  {
    region: "CSN",
    instcode: "53341",
    instName: "SAHYDRI COLLEGE OF PARAMEDICAL SCIENCES",
  },
  {
    region: "CSN",
    instcode: "53342",
    instName: "SHRI RAVSAHEB DANVE COLLEGE OF ADVANCE DMLT, RAJUR",
  },
  {
    region: "CSN",
    instcode: "53343",
    instName: "SHASHI FIRE AND SAFETY COLLEGE",
  },
  { region: "CSN", instcode: "53345", instName: "EKLAVYA VIDYALAYA" },
  {
    region: "CSN",
    instcode: "53346",
    instName: "MAHALAXMI TECHNICAL INSTITUTE PARADH BK",
  },
  {
    region: "CSN",
    instcode: "53347",
    instName: "SIDDHANT PARAMEDICAL INSTITUTE",
  },
  {
    region: "CSN",
    instcode: "53349",
    instName: "SHREE GANESH PARAMEDICAL SCIENCES COLLEGE",
  },
  {
    region: "CSN",
    instcode: "53350",
    instName: "AMOLAK SKILL DEVELOPMENT CENTER, KADA",
  },
  {
    region: "CSN",
    instcode: "53351",
    instName: "VITHAI PARAMEDICAL TECHNICAL INSTITUTE",
  },
  {
    region: "CSN",
    instcode: "53352",
    instName: "SAHARA DMLT INSTITUTE PARBHANI",
  },
  { region: "CSN", instcode: "53354", instName: "SAI PARAMEDICAL COLLEGE" },
  { region: "CSN", instcode: "53355", instName: "UTKARSH TECHNICAL INSTITUTE" },
  {
    region: "CSN",
    instcode: "53356",
    instName: "GURUMAULI TECHNICAL INSTITUTE",
  },
  {
    region: "CSN",
    instcode: "63341",
    instName: "AARAMBH COLLEGE OF FIRE AND SAFETY MANAGEMENT",
  },
  {
    region: "CSN",
    instcode: "63342",
    instName: "SHREE GAJANAN MAHARAJ PARAMEDICAL COLLEGE",
  },
  {
    region: "CSN",
    instcode: "63343",
    instName: "K K PATIL PARAMEDICAL COLLEGE",
  },
  { region: "CSN", instcode: "63344", instName: "SAI PARAMEDICAL COLLEGE" },
  {
    region: "CSN",
    instcode: "63345",
    instName: "SHRI NAGESHWAR INSTITUTE OF TECHNICAL AND PARAMEDICAL EDUCATION",
  },
  {
    region: "CSN",
    instcode: "63346",
    instName: "SHRI SWAMI SMARTH INSTITUTE OF PARAMEDICAL",
  },
  { region: "CSN", instcode: "63347", instName: "RELIABLE COLLEGE" },
  {
    region: "CSN",
    instcode: "63348",
    instName: "DNYANJYOT COLLEGE OF PARAMEDICAL",
  },
  {
    region: "CSN",
    instcode: "63349",
    instName: "P G COLLEGE OF PHARMACEUTICAL SCIENCE AND RESEARCH",
  },
  {
    region: "CSN",
    instcode: "63350",
    instName: "INSTITUTE OF PARAMEDICAL SCIENCES",
  },
  {
    region: "CSN",
    instcode: "63351",
    instName: "SIDDHI INSTITUTE OF PARAMEDICAL SCIENCE AND TECHNOLOGY",
  },
];

function NonAICTE_Institutes() {
  return (
    <div className="card mb-3 borderforcard paddingAboutMsbte">
      <div className="card-header backgroundColorCardHeader">
        <h1 className="fontForHeader custom-translate">Non AICTE Insitutes</h1>
      </div>
      <div className="row g-0">
        <div className="card-body table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr className="text-center">
                <th scope="col" className="custom-translate" width="100px">
                  Sr. No.
                </th>
                <th scope="col" className="custom-translate">
                  Region
                </th>
                <th scope="col" className="custom-translate">
                  Inst Code
                </th>
                <th scope="col" className="custom-translate">
                  Inst Name
                </th>
              </tr>
            </thead>
            <tbody>
              {Institutes.length > 0 &&
                Institutes.map((item, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}.</td>
                    <td className="text-center custom-translate">
                      {item.region}
                    </td>
                    <td className="text-center">
                      {item.instcode.padStart(4, "0")}
                    </td>
                    <td className="custom-translate">{item.instName}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default NonAICTE_Institutes;
