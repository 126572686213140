import React from "react";

function OurAssociation() {
  return (
    <>
      <div className="card mb-3  borderforcard paddingAboutMsbte">
        <div className="card-header backgroundColorCardHeader ">
          <h1 className="fontForHeader custom-translate">OUR ASSOCIATION</h1>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered  widthTable">
              <thead>
                <tr>
                  <th className="custom-translate">Abbreviations</th>
                  <th className="custom-translate">Full Form</th>
                  <th className="custom-translate">Link</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>DTE</td>
                  <td>Directorate of Technical Education, Maharashtra</td>
                  <td>
                    <a
                      href="https://dte.maharashtra.gov.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://dte.maharashtra.gov.in/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>GOVTMH</td>
                  <td>Government of Maharashtra</td>
                  <td>
                    <a
                      href="https://www.maharashtra.gov.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.maharashtra.gov.in/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>MHRD</td>
                  <td>Ministry of Human Resource Development</td>
                  <td>
                    <a
                      href="https://www.education.gov.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.education.gov.in/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>AICTE</td>
                  <td>All India Council for Technical Education</td>
                  <td>
                    <a
                      href="https://www.aicte-india.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.aicte-india.org/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>PCI</td>
                  <td className="widthForSecondcolumn">
                    Pharmacy Council of india
                  </td>
                  <td>
                    <a
                      href="https://www.pci.nic.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.pci.nic.in/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>ISTE</td>
                  <td>Indian Society for Technical Education</td>
                  <td>
                    <a
                      href="http://www.isteonline.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://www.isteonline.in/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>DGM & MS</td>
                  <td>Director General of Mines and Mine Safety (Dhanbad)</td>
                  <td>
                    <a
                      href="https://www.dgms.gov.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.dgms.gov.in/
                    </a>
                  </td>
                </tr>

                <tr>
                  <td>BOAT</td>
                  <td>Board of Apprenticeship Training</td>
                  <td>
                    <a
                      href="https://www.education.gov.in/boards-apprenticeship-training"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.education.gov.in/boards-apprenticeship-training
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>ACI</td>
                  <td>Architecture Council of India</td>
                  <td>
                    <a
                      href="https://www.coa.gov.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.coa.gov.in/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>MEDC</td>
                  <td>Maharashtra Economic Development Council</td>
                  <td>
                    <a
                      href="https://www.medcindia.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.medcindia.com/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>MKCL</td>
                  <td>Maharashtra Knowledge Corporation Limited</td>
                  <td>
                    <a
                      href="https://www.mkcl.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.mkcl.org/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>MPC</td>
                  <td>Maharashtra Paramedical Council</td>
                  <td>
                    <a
                      href="https://www.maharashtraparamedicalcouncil.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.maharashtraparamedicalcouncil.org/
                    </a>
                  </td>
                </tr>

                <tr>
                  <td>NITTR</td>
                  <td>
                    National Institute of Technical Teacher's Training &
                    Research
                  </td>
                  <td>
                    <a
                      href="https://nitttrbpl.ac.in/new/extension-center-pune.php"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://nitttrbpl.ac.in/new/extension-center-pune.php
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>CII</td>
                  <td>Confederation of Indian Industry</td>
                  <td>
                    <a
                      href="https://www.cii.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.cii.in/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>VIA</td>
                  <td>Vidarbha Industries Association</td>
                  <td>
                    <a
                      href="https://www.via-india.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.via-india.com/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>MIA</td>
                  <td>Marathwada Industries Association</td>
                  <td></td>
                </tr>
                <tr>
                  <td>TBIA</td>
                  <td>Thane Belapur Industries Association</td>
                  <td>
                    <a href="https://tbia.in/" target="_blank" rel="noreferrer">
                      https://tbia.in/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>MCC</td>
                  <td>Maharashtra Chamber of Commerce</td>
                  <td>
                    <a
                      href="https://maccia.org.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://maccia.org.in/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>H&TE</td>
                  <td>
                    Higher and Technical Education Department Government of
                    Maharashtra
                  </td>
                  <td>
                    <a
                      href="https://htedu.maharashtra.gov.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://htedu.maharashtra.gov.in/
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurAssociation;
