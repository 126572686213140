import React, { useContext, useEffect, useState } from "react";
import { ShowContext } from "../App";

import Modal from "react-bootstrap/Modal";
import Moment from "react-moment";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import API from "../API";
import { de } from "../utils/Helper";

function Footer() {
  const s3Path = process.env.REACT_APP_S3_PATH;

  const { setShow, setMsg } = useContext(ShowContext);
  const [visitor, setVisitor] = useState(0);
  const [lastUpdatedDate, setLastUpdatedDate] = useState();

  useEffect(() => {
    AddVisitor(setMsg, setShow);
    GetVisitor(setMsg, setShow, setVisitor);
  }, [setVisitor, setMsg, setShow]);

  useEffect(() => {
    getLastUpdateDate(setMsg, setShow, setLastUpdatedDate);
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState("");
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  return (
    <>
      <div
        className="text-center text-white fixed-footer footerclor"
        // id="fixfooter"
      >
        <div className="container-fluid footerContainerwidth">
          <div className="row">
            <ul className="">
              <li>
                <Link
                  to={{ pathname: "/disclaimer" }}
                  target="_top"
                  className="custom-translate"
                >
                  Disclaimer
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "/policie" }}
                  target="_top"
                  className="custom-translate"
                >
                  Policies
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "/terms-and-condition" }}
                  target="_top"
                  className="custom-translate"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "/accessibility-statement" }}
                  target="_top"
                  className="custom-translate"
                >
                  Accessibility Statement
                </Link>
              </li>
              <li>
                <a
                  href="https://grievance.msbte.edu.in/grievance/"
                  target="_blank"
                  rel="noreferrer"
                  className="custom-translate"
                >
                  Grievance & Redressal
                </a>
              </li>
              <li>
                <Link
                  to={{ pathname: "/feedback" }}
                  target="_top"
                  className="custom-translate"
                >
                  Feedback
                </Link>
              </li>

              <li>
                <Link
                  to={{ pathname: "/Faq" }}
                  target="_top"
                  className="custom-translate"
                >
                  FAQ
                </Link>
              </li>
              <li>
                <span
                  onClick={() => {
                    handleShow();
                    setFile(s3Path + "/files/RTI.pdf");
                  }}
                  target={"_blank"}
                  rel="noreferrer"
                  className="custom-translate"
                >
                  RTI
                </span>
              </li>
              <li>
                <Link
                  to={{ pathname: "/help" }}
                  target="_top"
                  className="custom-translate"
                >
                  Help
                </Link>
              </li>

              <li>
                <Link
                  to={{ pathname: "/sitemap" }}
                  target="_top"
                  className="custom-translate"
                >
                  Site map
                </Link>
              </li>
            </ul>
          </div>
          <p className="footertext my-1 custom-translate">
            Maharashtra State Board of Technical Education, Mumbai. All Rights
            Reserved.
          </p>
          <div className=" marginDiv py-1 my-2">
            <Link
              target={"_blank"}
              rel="noreferrer"
              title="Explanation of WCAG 2.0 Level Double-A Conformance"
              className="footerImg"
              onClick={() => {
                if (
                  window.confirm(
                    "This link will take you to an external website.यह लिंक आपको बाहरी वेबसाइट पर ले जाएगा।  हा दुवा आपल्याला एका बाह्य वेबसाइटवर घेऊन जाईल."
                  )
                ) {
                  window.open(
                    "https://www.w3.org/WAI/WCAG2AA-Conformance",
                    "_blank"
                  );
                }
              }}
            >
              <img
                height="32"
                width="88"
                src={s3Path + "/Images1/wcag2AA.jpg"}
                alt="Level Double-A conformance,W3C WAI Web Content Accessibility Guidelines 2.0"
              />
            </Link>
            <Link
              target={"_blank"}
              rel="noreferrer"
              title="Valid XHTML 1.0 Transitional"
              onClick={() => {
                if (
                  window.confirm(
                    "This link will take you to an external website.यह लिंक आपको बाहरी वेबसाइट पर ले जाएगा।  हा दुवा आपल्याला एका बाह्य वेबसाइटवर घेऊन जाईल."
                  )
                ) {
                  window.open(
                    "https://validator.w3.org/check?uri=https%3A%2F%2Fweb.msbte.ac.in%2F&charset=%28detect+automatically%29&doctype=Inline&group=0",
                    "_blank"
                  );
                }
              }}
            >
              <img
                src={s3Path + "/Images1/valid-xhtml.png"}
                alt="Valid XHTML 1.0 Transitional"
                height="32"
                width="88"
              />
            </Link>
            <Link
              title="Valid CSS!"
              target={"_blank"}
              rel="noreferrer"
              onClick={() => {
                if (
                  window.confirm(
                    "This link will take you to an external website.यह लिंक आपको बाहरी वेबसाइट पर ले जाएगा।  हा दुवा आपल्याला एका बाह्य वेबसाइटवर घेऊन जाईल."
                  )
                ) {
                  window.open(
                    "https://jigsaw.w3.org/css-validator/check/referer",
                    "_blank"
                  );
                }
              }}
            >
              <img
                height="31"
                width="88"
                src={s3Path + "/Images1/vcss.jpg"}
                alt="Valid CSS!"
              />
            </Link>
          </div>
          <div className="counterLink text-center">
            {visitor !== 0 ? (
              <p className="visitorCount">
                <b className="custom-translate">Visitor Count</b> :&nbsp;{" "}
                {String(visitor)
                  .split("")
                  .map((char, index) => (
                    <span key={index}>{char}</span>
                  ))}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="bottom-text pt-2 pb-3">
            <span className="text-left">
              <span className="custom-translate">Last updated on</span> :&nbsp;
              {lastUpdatedDate ? (
                <Moment format="DD/MM/YYYY, hh:mm A">{lastUpdatedDate}</Moment>
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        contentClassName="modal-pdf-content"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body closeButton>
          <iframe title="myFrame" className="w-100 h-100" src={file}></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
}

function AddVisitor(setMsg, setShow) {
  API.post("visitor", {})
    .then((res) => {
      if (res.data.status === "success") {
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}

function GetVisitor(setMsg, setShow, setVisitor) {
  API.get("visitor/count")
    .then((res) => {
      if (res.data.status === "success") {
        setVisitor(de(res.data.data));
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}

function getLastUpdateDate(setMsg, setShow, setLastUpdatedDate) {
  API.get("lastUpdateDate")
    .then((res) => {
      if (res.data.status === "success") {
        setLastUpdatedDate(parseInt(de(res.data.data)));
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}
export default Footer;
