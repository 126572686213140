import React, { useEffect, useState } from "react";
import { FaRocket } from "react-icons/fa"; // Using FontAwesome for the rocket icon
import Fireworks from "./Fireworks"; // Import Fireworks component

const LaunchPage = () => {
  const [showFireworks, setShowFireworks] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const checkTimeRange = () => {
      const now = new Date();
      const startTime = new Date("2024-10-09T16:00:00"); // Start date and time (4:00 PM)
      const endTime = new Date("2024-10-09T21:00:00"); // End date and time (9:00 PM)

      if (now >= startTime && now <= endTime) {
        console.log("Button should be visible");
      } else {
        setShowButton(false);
      }
    };

    checkTimeRange(); // Check immediately
    const interval = setInterval(checkTimeRange, 60 * 1000); // Check every minute

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      {showButton && (
        <button
          onClick={() => setShowFireworks(true)}
          style={{
            position: "fixed",
            bottom: "22px",
            right: "22px",
            backgroundColor: "#FF5733",
            border: "none",
            borderRadius: "50%",
            padding: "15px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            cursor: "pointer",
            zIndex: 1000,
          }}
        >
          <FaRocket size={24} color="white" /> {/* Rocket icon */}
        </button>
      )}
      {showFireworks && <Fireworks />}
    </div>
  );
};

export default LaunchPage;
