import React from "react";

function ContactUs() {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card mb-3 borderforcard paddingAboutMsbte">
            <div className="card-header backgroundColorCardHeader">
              <h1 className="fontForHeader custom-translate">Contact Us</h1>
            </div>
            <div className="row g-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <p className="colorcontact px-3">
                      <span className="custom-translate">
                        MAHARASHTRA STATE BOARD OF TECHNICAL EDUCATION
                      </span>
                      <br />
                      <span className="custom-translate">
                        (Autonomous) (ISO 9001:2015) (ISO/IEC 27001:2013)
                      </span>
                    </p>
                    <div className="px-3 my-2 table-responsive">
                      <table className="table contactTable table-bordered">
                        <tr>
                          <td
                            className="colorcontact custom-translate"
                            width="110px"
                          >
                            Address :
                          </td>
                          <td className="colorcontact">
                            <a
                              className="phoneLinkColor custom-translate"
                              href="https://goo.gl/maps/CeosixxKD1qFBi4S8"
                              target="_blank"
                              rel="noreferrer"
                            >
                              4th floor, Govt. Polytechnic Building, 49,
                              Kherwadi, Bandra (E), Mumbai- 400 051
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="colorcontact custom-translate">
                            Phone :
                          </td>
                          <td className="colorcontact">
                            <a
                              className="phoneLinkColor"
                              href="tel:+022-62542100"
                              target="_blank"
                              rel="noreferrer"
                            >
                              +022-62542100
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="colorcontact custom-translate">
                            Email :
                          </td>
                          <td className="colorcontact">
                            <a
                              className="phoneLinkColor"
                              href="mailto:secretary@msbte.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              secretary@msbte.com
                            </a>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <p className="colorcontact mx-3 custom-translate">
                      REGIONAL OFFICES
                    </p>
                    <div className="table-responsive px-3 py-0">
                      <table className="table table-bordered contactTable">
                        <tbody>
                          <tr>
                            <td className="text-center custom-translate">
                              Mumbai Region
                            </td>
                            <td>
                              <span className="custom-translate">
                                Dy.Secretary, Maharashtra State Board of
                                Technical Education, Region Office, 2nd Floor,
                                Govt. Polytechnic Building, 49, Kherwadi, Bandra
                                (East), Mumbai:400051
                              </span>
                              <div className="d-flex">
                                <span className="contactFont custom-translate pe-2">
                                  Phone :
                                </span>
                                <a
                                  className="phoneLinkColor"
                                  href="tel:+022-62542118"
                                >
                                  022-62542118,
                                </a>
                                <a
                                  className="phoneLinkColor"
                                  href="tel:022-62542112"
                                >
                                  022-62542112
                                </a>
                              </div>
                              <div className="d-flex">
                                <span className="contactFont custom-translate pe-2">
                                  Mail :
                                </span>
                                <a href="mailto:rbtemumbai@msbte.com">
                                  rbtemumbai@msbte.com
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center custom-translate">
                              Pune Region
                            </td>
                            <td>
                              <span className="custom-translate">
                                Dy.Secretary, Maharashtra State Board of
                                Technical Education, Region Office, 412-E,
                                Shivaji Nager, Bahirat Patil Chowk, Pune-411016
                              </span>
                              <div className="d-flex">
                                <span className="contactFont custom-translate pe-2">
                                  Phone :
                                </span>
                                <a
                                  className="phoneLinkColor"
                                  href="tel:020-25656994"
                                >
                                  020-25656994/
                                </a>
                                <a
                                  className="phoneLinkColor"
                                  href="tel:+02025660319"
                                >
                                  25660319
                                </a>
                              </div>
                              <div className="d-flex">
                                <span className="contactFont custom-translate pe-2">
                                  Mail :
                                </span>
                                <a href="mailto:rbtepn@msbte.com">
                                  rbtepn@msbte.com
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center custom-translate">
                              Nagpur Region
                            </td>
                            <td>
                              <span className="custom-translate">
                                Dy.Secretary, Maharashtra State Board of
                                Technical Education, Region Office, Sadar,
                                Nagpur-440001
                              </span>
                              <div className="d-flex">
                                <span className="contactFont custom-translate pe-2">
                                  Phone :
                                </span>
                                <a
                                  className="phoneLinkColor"
                                  href="tel:0712-6603875"
                                >
                                  0712-6603875/
                                </a>
                                <a
                                  className="phoneLinkColor"
                                  href="tel:0712-6603876"
                                >
                                  0712-6603876/
                                </a>
                                <a
                                  className="phoneLinkColor"
                                  href="tel:0712-6603877"
                                >
                                  0712-6603877
                                </a>
                              </div>
                              <div className="d-flex">
                                <span className="contactFont custom-translate pe-2">
                                  Mail :
                                </span>
                                <a href="mailto:rbteng@msbte.com">
                                  rbteng@msbte.com
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center custom-translate">
                              Chatrapati Shambhajinagar Region
                            </td>
                            <td>
                              <span className="custom-translate">
                                Dy.Secretary, Maharashtra State Board of
                                Technical Education, Region Office, Osmanapura,
                                Chatrapati Shambhajinagar-431005
                              </span>
                              <div className="d-flex">
                                <span className="contactFont custom-translate pe-2">
                                  Phone :
                                </span>
                                <a
                                  className="phoneLinkColor"
                                  href="tel:02402331273"
                                >
                                  0240-2331273
                                </a>
                                <a
                                  className="phoneLinkColor"
                                  href="tel:02402334025"
                                >
                                  2334025
                                </a>
                              </div>
                              <div className="d-flex">
                                <span className="contactFont custom-translate pe-2">
                                  Mail :
                                </span>
                                <a href="mailto:rbteau@msbte.com">
                                  rbteau@msbte.com
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    {/* <NewMap /> */}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.5311805768656!2d72.8427585!3d19.063767!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9194bf3581b%3A0x79a2a1703fe9ffae!2sMaharashtra%20State%20Board%20of%20Technical%20Education%2C%20Mumbai!5e0!3m2!1sen!2sin!4v1728482005480!5m2!1sen!2sin"
                      width="100%"
                      className="mainMap"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
