import confetti from "canvas-confetti";
import { useEffect } from "react";

const Fireworks = () => {
  useEffect(() => {
    const duration = 30 * 1000; // 10 seconds
    const end = Date.now() + duration;

    const fire = () => {
      confetti({
        particleCount: 70, // Reduced particle count for smoother performance
        startVelocity: 25,
        spread: 360,
        origin: {
          x: Math.random(),
          y: Math.random() - 0.2,
        },
      });
    };

    const frame = () => {
      fire();
      if (Date.now() < end) {
        setTimeout(() => requestAnimationFrame(frame), 250); // Fire every 250ms to reduce lag
      }
    };

    requestAnimationFrame(frame);
  }, []);

  return null;
};

export default Fireworks;
