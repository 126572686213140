const InstituteCourses = [
  {
    courseCode: "BC",
    courseName: "Diploma in Beauty Culture And Hair Dressing",
  },
  { courseCode: "BO", courseName: "Diploma in Bamboo Technology" },
  {
    courseCode: "CB",
    courseName: "Advance Diploma in Cyber Security Management",
  },
  { courseCode: "CY", courseName: "Diploma in CNC Machine Techniques" },
  { courseCode: "DA", courseName: "Diploma in 3D Animation & Graphics" },
  { courseCode: "DB", courseName: "Diploma in Animation & Graphics" },
  {
    courseCode: "DM",
    courseName: "Diploma in Dress Designing & Manufacturing",
  },
  { courseCode: "DX", courseName: "Advanced Diploma in Dietetics" },
  {
    courseCode: "ER",
    courseName: "Advance Diploma in Energy Management & Audit",
  },
  { courseCode: "ES", courseName: "Advance Diploma in Embedded Systems" },
  {
    courseCode: "EW",
    courseName: "Advance Diploma in Energy Management & Audit",
  },
  {
    courseCode: "EZ",
    courseName: "Advanced Diploma in Environmental Engineering",
  },
  {
    courseCode: "FA",
    courseName:
      "Advance Diploma in Fire Safety and Industrial Environmental Engineering",
  },
  {
    courseCode: "FF",
    courseName: "Advance Diploma in Industrial Safety and Security Management",
  },
  { courseCode: "FI", courseName: "Post Diploma in Fire Engineering" },
  { courseCode: "FN", courseName: "Diploma in Fashion and Textile Designing" },
  { courseCode: "FR", courseName: "Diploma in Fire Service Engineering" },
  {
    courseCode: "FS",
    courseName: "Advance Diploma in Fire Safety Engineering",
  },
  {
    courseCode: "FU",
    courseName: "Advance Diploma in Fire and Security Management",
  },
  { courseCode: "FY", courseName: "Diploma in Fashion & Textile Designing" },
  { courseCode: "HD", courseName: "Diploma in Hemodialysis Technician" },
  { courseCode: "HO", courseName: "Diploma in Hotel Operation" },
  {
    courseCode: "ID",
    courseName: "Diploma in Interior Designing and Decoration",
  },
  {
    courseCode: "IG",
    courseName: "Advance Diploma in Internet of Things (IoT)",
  },
  {
    courseCode: "IN",
    courseName: "Diploma in Interior Designing and Decoration",
  },
  {
    courseCode: "IR",
    courseName: "Post Diploma in Interior Designing & Decoration",
  },
  { courseCode: "IT", courseName: "Advance Diploma in Industrial Safety" },
  {
    courseCode: "LX",
    courseName: "Advance Diploma in Medical Laboratory Technology",
  },
  {
    courseCode: "MJ",
    courseName: "Advance Diploma in Medical Imaging Technology",
  },
  { courseCode: "MQ", courseName: "Diploma in Medical Equipment Maintenance" },
  { courseCode: "OT", courseName: "Diploma in Operation Theatre Technician" },
  {
    courseCode: "PR",
    courseName:
      "Advance Diploma in Project Management in Building Construction",
  },
  { courseCode: "PV", courseName: "Diploma in Photography and Videography" },
  {
    courseCode: "RX",
    courseName:
      "Advance Diploma in X-ray and Radiography and Ultra Sonography Techniques",
  },
  {
    courseCode: "RY",
    courseName: "Advance Diploma in Radiotherapy Technology",
  },
  { courseCode: "RZ", courseName: "Advanced Diploma in X-ray Radiography" },
  {
    courseCode: "SM",
    courseName: "Diploma in Logistics and Supply Chain Management",
  },
  {
    courseCode: "ST",
    courseName: "Diploma in Stenography & Secretarial Practice",
  },
  {
    courseCode: "SV",
    courseName: "Diploma in Sound Recording & Video Editing",
  },
  { courseCode: "SX", courseName: "Advance Diploma in Sugar Chemical Control" },
  { courseCode: "TY", courseName: "Advance Diploma in Travel & Tourism" },
  {
    courseCode: "UV",
    courseName:
      "Advance Diploma in Unmanned Aerial Vehicles (UVA) and Drone Technology",
  },
];

function NonAICTE_Courses() {
  return (
    <div className="card mb-3 borderforcard paddingAboutMsbte">
      <div className="card-header backgroundColorCardHeader">
        <h1 className="fontForHeader custom-translate">Non AICTE Courses</h1>
      </div>
      <div className="row g-0">
        <div className="card-body table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr className="text-center">
                <th scope="col" className="custom-translate" width="100px">
                  Sr. No.
                </th>
                <th scope="col" className="custom-translate">
                  Course Code
                </th>
                <th scope="col" className="custom-translate">
                  Course Name
                </th>
              </tr>
            </thead>
            <tbody>
              {InstituteCourses.length > 0 &&
                InstituteCourses.map((item, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}.</td>
                    <td className="text-center">{item.courseCode}</td>
                    <td className="custom-translate">{item.courseName}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default NonAICTE_Courses;
