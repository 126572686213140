import React from "react";

function OnlineActivities() {
  return (
    <div>
      <div className="row  ">
        <div className="col-sm-12">
          <div className="card RemoverBorderRadius">
            <div className="card-body">
              <h6 className="linktextcolor px-3 py-2">Online Activities</h6>
              <ul>
                <li className="lidt my-2">
                  <a
                    title="Institute and exam center activity Winter 2022 examination"
                    href="https://online.msbte.co.in/MES022022_TH/index.php"
                    id="mesthdno"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>
                      Institute /Exam center and RAC activities Winter 2022
                      examination
                    </strong>
                  </a>
                </li>
                <li className="lidt my-2">
                  <a
                    title="Non Theory E-mark sheet link for the Winter 2022 Examination"
                    href="https://online.msbte.co.in/MES022022/index.php"
                    id="mesthdno"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>
                      Non Theory E-mark sheet link for the Winter 2022
                      Examination
                    </strong>
                  </a>
                </li>
                <li className="lidt my-2">
                  <a
                    title="Hall Ticket for Winter 2022 Examination"
                    href="https://online.msbte.co.in/msbte22/index.php?act=hall_ticket&sub=hallticket_w_public"
                    id="mesthdno"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>Hall Ticket for Winter 2022 Examination</strong>
                  </a>
                </li>
                <li className="lidt my-2">
                  <a
                    title="MSBTE Online Activities Logins. (A.Y. 2021-22)"
                    href="https://online.msbte.co.in/msbte21/"
                    id="mesthdno"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>
                      MSBTE Online Activities Logins. (A.Y. 2021-22)
                    </strong>
                  </a>
                </li>
                <li className="lidt my-2">
                  <a
                    title="Affiliation Module 2022-23 and Summer 2022 UV/OV/PH application link."
                    href="https://online.msbte.co.in/msbte22/"
                    id="mesthdno"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>
                      MSBTE On-Line Activities login for A.Y. 2022-23.
                    </strong>
                  </a>
                </li>
                <li className="lidt my-2">
                  <a
                    title="MSBTE ONLINE EXAM PORTAL"
                    href="https://exam.msbte.ac.in/oexam/"
                    id="mesthe"
                  >
                    <strong>MSBTE THEORY EXAM PORTAL</strong>
                  </a>
                </li>
                <li className="lidt my-2">
                  <a
                    title="Institute Monitoring/Lab Manual"
                    href="https://acmon.msbte.ac.in/msbteacmon/acmon_s_22_23_bak/"
                    id="mesthf"
                  >
                    <strong>Institute Monitoring/Lab Manual</strong>
                  </a>
                </li>
                <li className="lidt my-2">
                  <a
                    title="Emarksheet Link for Community Polytechnic."
                    href="https://online.msbte.co.in/ems_community_w15/index.php/principallogin/"
                    id="mesthg"
                  >
                    <strong>Emarksheet Link for Community Polytechnic.</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnlineActivities;
