import React from "react";

function ScreenReaderAccess() {
  return (
    <>
      <div className="card mb-3  borderforcard paddingAboutMsbte">
        <div className="card-header backgroundColorCardHeader">
          <h1 className="fontForHeader custom-translate">
            Screen Reader Access
          </h1>
        </div>
        <br />
        <div className="table-responsive">
          <table className="table table-bordered region-table">
            <thead className="thead-dark">
              <tr>
                <th className="custom-translate">Sr. No.</th>
                <th className="custom-translate">Screen Reader</th>
                <th className="custom-translate">Website</th>
                <th className="custom-translate">Free / Commercial</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td className="custom-translate">
                  Screen Access For All (SAFA)
                </td>
                <td>
                  <a
                    href="https://www.nabdelhi.org/NAB_SAFA.htm"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    www.nabdelhi.org/NAB_SAFA.htm
                  </a>
                </td>
                <td className="custom-translate">Free</td>
              </tr>
              <tr>
                <td>2.</td>
                <td className="custom-translate">
                  Non Visual Desktop Access (NVDA)
                </td>
                <td>
                  <a
                    href="https://www.nvda-project.org/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    www.nvda-project.org/
                  </a>
                </td>
                <td className="custom-translate">Free</td>
              </tr>
              <tr>
                <td>3.</td>
                <td className="custom-translate">System Access To Go</td>
                <td id="safa">
                  <a
                    href="https://www.satogo.com/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    www.satogo.com/
                  </a>
                </td>
                <td className="custom-translate">Free</td>
              </tr>
              <tr>
                <td>4.</td>
                <td className="custom-translate">Thunder</td>
                <td>
                  <a
                    href="https://www.screenreader.net/index.php?pageid=2"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    www.screenreader.net/index.php?pageid=2
                  </a>
                </td>
                <td className="custom-translate">Free</td>
              </tr>
              <tr>
                <td>5.</td>
                <td className="custom-translate">WebAnywhere</td>
                <td>
                  <a
                    href="https://webinsight.cs.washington.edu/projects/webanywhere/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    webinsight.cs.washington.edu/projects/webanywhere/
                  </a>
                </td>
                <td className="custom-translate">Free</td>
              </tr>
              <tr>
                <td>6.</td>
                <td className="custom-translate">Hal</td>
                <td>
                  <a
                    href="https://www.yourdolphin.com/products?id=3"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    www.yourdolphin.com/products?id=3
                  </a>
                </td>
                <td className="custom-translate">Commercial</td>
              </tr>
              <tr>
                <td>7.</td>
                <td className="custom-translate">JAWS</td>
                <td>
                  <a
                    href="https://www.freedomscientific.com/Downloads/JAWS"
                    id="safa7"
                  >
                    www.freedomscientific.com/Downloads/JAWS
                  </a>
                </td>
                <td className="custom-translate">Commercial</td>
              </tr>
              <tr>
                <td>8.</td>
                <td className="custom-translate">Supernova</td>
                <td>
                  <a
                    href="https://www.yourdolphin.com/product?id=4"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    www.yourdolphin.com/product?id=4
                  </a>
                </td>
                <td className="custom-translate">Commercial</td>
              </tr>
              <tr>
                <td>9.</td>
                <td className="custom-translate">Window-Eyes</td>
                <td>
                  <a
                    href="https://www.gwmicro.com/Window-Eyes/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    www.gwmicro.com/Window-Eyes/
                  </a>
                </td>
                <td className="custom-translate">Commercial</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ScreenReaderAccess;
