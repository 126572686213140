const HeaderFooterTrans = {
  "महाराष्ट्र शासन": "महाराष्ट्र शासन",
  "Government of Maharashtra": "महाराष्ट्र शासन",
  "Visit Old site": "जुन्या साइटला भेट द्या",
  "Skip to main content": "मुख्य सामग्रीवर जा",
  HOME: "घर",
  "ABOUT US": "आमच्याबद्दल",
  "ABOUT MSBTE": "महाराष्ट्र राज्य तंत्रशिक्षण मंडळ बद्दल",
  "MSBTE ACT": "महाराष्ट्र राज्य तंत्रशिक्षण मंडळ कायदा",
  "OUR ASSOCIATION": "आमची संघटना",
  "CORE VALUES": "मुख्य मूल्ये",
  "VISION AND MISSION": "व्हिजन आणि मिशन",
  "QUALITY POLICY": "गुणवत्ता धोरण",
  "ORGANIZATIONAL STRUCTURE": "संघटनात्मक रचना",
  "GOVERNING COUNCIL": "गव्हर्निंग कौन्सिल",
  "GOVERNING BOARD": "गव्हर्निंग बोर्ड",
  "MSBTE OFFICERS": "महाराष्ट्र राज्य तंत्रशिक्षण मंडळ अधिकारी",
  "REGIONAL OFFICERS": "प्रादेशिक अधिकारी",
  "MUMBAI REGION": "मुंबई क्षेत्र",
  "PUNE REGION": "पुणे क्षेत्र",
  "NAGPUR REGION": "नागपूर क्षेत्र",
  "CHATRAPATI SAMBHAJINAGAR REGION": "छत्रपती संभाजीनगर क्षेत्र",
  "FEE FIXATION COMMITTEE (Diploma)": "शुल्क निर्धारण समिती (पदविका)",
  "CITIZEN CHARTER": "सिटीझन चार्टर",
  "ISO 9001:2015": "आयएसओ 9001:2015",
  INSTITUTES: "संस्था",
  AFFILIATION: "परिपूर्णता",
  "VIEW INSTITUTES": "संस्थांकडे पहा",
  "ACADEMIC MONITORING": "शैक्षणिक देखरेख",
  "INSTITUTE EQUIVALENCE": "संस्थेचे संतुलन",
  CURRICULUM: "अभ्यासक्रम",
  Search: "शोध",

  "Curriculum Search": "अभ्यासक्रम शोध",
  "MSBTE Video Lectures": "महाराष्ट्र राज्य तंत्रशिक्षण मंडळ व्हिडिओ व्याख्यान",
  "MSBTE's E-Contents": "महाराष्ट्र राज्य तंत्रशिक्षण मंडळ ची ई-सामग्री",
  EXAMINATION: "परीक्षा",
  "Academic Calender": "शैक्षणिक दिनदर्शिका",
  "Examination Form": "परीक्षेचा फॉर्म",
  "Exam Timetable": "परीक्षेचे वेळापत्रक",
  "Hall Ticket": "प्रवेशपत्र",
  Result: "परिणाम",
  Verification: "तपासणी",
  Reassessment: "पुनर्मूल्यांकन",
  "Examination Regulation": "परीक्षेचे नियमन",
  "Exam Regulation Amendment": "परीक्षा नियमन सुधारणा",
  "Examination Activities": "परीक्षा उपक्रम",
  FACULTY: "विद्याशाखा",
  "Industrial Training": "औद्योगिक प्रशिक्षण",
  "Faculty Development Program": "विद्याशाखा विकास कार्यक्रम",
  "STUDENT ACTIVITIES": "विद्यार्थ्यांच्या कृती",
  "Project Competition": "प्रकल्प स्पर्धा",
  "Technical Quiz Competition": "तांत्रिक प्रश्नमंजुषा स्पर्धा",
  "Paper presentation": "कागदाचे सादरीकरण",
  "MSBTE Scholarship": "महाराष्ट्र राज्य तंत्रशिक्षण मंडळ शिष्यवृत्ती",
  "NEP 2020": "एनईपी 2020",
  "About NEP 2020": "एनईपी 2020 बद्दल",
  "NEP 2020 (English)": "एनईपी 2020 (इंग्रजी)",
  "NEP 2020 (Marathi)": "एनईपी 2020 (मराठी)",
  "Goverment Resolution": "शासनाचा ठराव",
  "MS-CIT": "एमएस-सीआयटी",
  "ALC/Exam Centers": "एएलसी/परीक्षा केंद्रे",
  "MSCIT GR": "एमएससीआयटी जीआर",
  "MSCIT Equivalent": "एमएससीआयटी समतुल्य",
  "MEDIA CORNER": "मीडिया कॉर्नर",
  "Information Brouchers": "माहितीचे माहितीपत्रक",
  Gallery: "गॅलरी",
  "MSBTE Newsletter": "एमएसबीटीई वृत्तपत्र",
  SERVICES: "सेवा",
  "AUTHENTICATION (Diploma)": "प्रमाणीकरण (पदविका)",
  "VERIFICATION (Diploma)": "पडताळणी (पदविका)",
  "DUPLICATE MARKSHEET (Diploma)": "दुहेरी मार्कशीट (पदविका)",
  "DUPLICATE CERTIFICATE (Diploma)": "दुहेरी प्रमाणपत्र (पदविका)",
  "TRANSCRIPT CERTIFICATE": "हस्तांतरण प्रमाणपत्र",
  "AUTHETICATIONS AND VERIFICATION": "प्रमाणीकरण आणि पडताळणी",
  "MIGRATION CERTIFICATE": "स्थलांतर प्रमाणपत्र",
  "EQUIVALENCE CERTIFICATE": "पात्रता प्रमाणपत्र",
  "DUPLICATE CERTIFICATE (MSCIT)": "दुहेरी प्रमाणपत्र (एमएस-सीआयटी)",
  "VERIFICATION (MSCIT)": "पडताळणी (एमएस-सीआयटी)",
  ADMISSION: "प्रवेश",
  "Importance of Diploma": "पदविकेचे महत्त्व",
  "Non AICTE": "बिगर ए. आय. सी. टी. ई.",
  "First Year Post SSC Diploma Technical Courses in Engineering/Technology 2022-2023":
    "अभियांत्रिकी तंत्रज्ञान 2022-2023 मध्ये प्रथम वर्ष एसएससी पदविका तांत्रिक अभ्यासक्रम",
  "First Year Post HSC Diploma Technical Courses in Pharmacy, SCT and HMCT Admissions 2022-2023":
    "फार्मसी, एससीटी आणि एचएमसीटी मधील प्रथम वर्ष पोस्ट एचएससी डिप्लोमा तांत्रिक अभ्यासक्रम प्रवेश 2022-2023",
  "CONTACT US": "आमच्याशी संपर्क साधा",
  "MSCIT Exam": "MSCIT परीक्षा",
  "SEARCH 2023-24": "2023-24 च्या दरम्यान शोधा",
  "SEARCH 2024-25 AFFILIATED INSTITUTES":
    "2024-25 च्या मान्यताप्राप्त संस्थांचा शोध घ्या",
  "Non AICTE Course": "AICTE मान्यताप्राप्त नसलेला अभ्यासक्रम",
  "Non AICTE Institute": "AICTE मान्यताप्राप्त नसलेली संस्था",
  "Admission Notice for first year of post SSC Diploma courses in Engineering and Technology for Academic Year 2024-25":
    "शैक्षणिक वर्ष 2024-25 साठी एसएससी नंतरच्या अभियांत्रिकी आणि तंत्रज्ञान डिप्लोमा अभ्यासक्रमांच्या पहिल्या वर्षासाठी प्रवेश सूचना",

  MSCIT: "एमएससीआयटी",
  Results: "परिणाम",
  "EMARKSHEET - NON THEORY": "एमार्कशीट-गैर सिद्धांत",
  "EMARKSHEET  - THEORY": "एमार्कशीट-सिद्धांत",
  "EMARKSHEET COMMUNITY COLLEGE": "एमार्कशीट कम्युनिटी कॉलेज",
  "Equivalence(Other Board)": "समतुल्यता (इतर मंडळ)",
  HSCEquivalence: "एच. एस. सी. समतुल्यता",
  "Faculty Approval": "विद्याशाखा मान्यता",
  Scholarship: "शिष्यवृत्ती",

  "LATEST UPDATES": "नवीनतम अद्यतने",

  Disclaimer: "अस्वीकरण",
  Policies: "धोरणे",
  "Terms & Conditions": "अटी आणि शर्ती",
  "Accessibility Statement": "प्रवेशयोग्यता विधान",
  "Grievance & Redressal": "तक्रार निवारण",
  Feedback: "अभिप्राय",
  FAQ: "वारंवार विचारले जाणारे प्रश्न",
  RTI: "RTI",
  Help: "मदत करा.",
  "Site map": "स्थळाचा नकाशा",
  "Maharashtra State Board of Technical Education, Mumbai. All Rights Reserved.":
    "महाराष्ट्र राज्य तंत्रशिक्षण मंडळ, मुंबई सर्व हक्क राखीव आहेत.",
  "Visitor Count": "पाहुण्यांची संख्या",
  "Last updated on": "शेवटचे अद्ययावत केले",

  "Are you sure you want to continue?": "आपण खात्रीने पुढे जायचे आहे का?",
  Close: "बंद करा",
  "Go to Website": "वेबसाइटवर जा",
  "You are being redirected to an external website. Please note that MSBTE is not responsible for external websites content & privacy policies.":
    "आपण बाह्य वेबसाइटकडे पुनर्निर्देशित होत आहात. कृपया लक्षात घ्या की MSBTE बाह्य वेबसाइट्सच्या सामग्री आणि गोपनीयता धोरणांसाठी जबाबदार नाही.",
};

export default HeaderFooterTrans;
