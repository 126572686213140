import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "./API";
import PreventInspect from "./Components/HeaderComponent/PreventInspect";
import TopControl from "./Components/HeaderComponent/TopControl";
import LaunchPage from "./Components/Launch/LaunchPage";
import ExternalModal from "./Components/Modal/ExternalModal";
import Content from "./Layout/Content";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import SliderFooter from "./Layout/SliderFooter";
export const ShowContext = React.createContext();

function App() {
  const [showModal, setShowModal] = useState(false);
  const [externalLink, setExternalLink] = useState("");

  const openModal = (url) => {
    setExternalLink(url);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setExternalLink("");
  };

  const handleAccept = () => {
    window.open(externalLink, "_blank", "noopener,noreferrer");
    closeModal();
  };

  useEffect(() => {
    const handleLinkClick = (e) => {
      const anchor = e.target.closest("a"); // Find the closest anchor element
      if (anchor && anchor.getAttribute("target") === "_blank") {
        const href = anchor.getAttribute("href");

        // Ensure the href is an external link
        if (href.startsWith("http") || href.startsWith("https")) {
          e.preventDefault();
          openModal(href); // Open modal and pass the URL
        }
      }
    };

    document.addEventListener("click", handleLinkClick);

    return () => {
      document.removeEventListener("click", handleLinkClick);
    };
  }, []);

  const switchTheme = (theme) => {
    if (theme === "default") {
      document.documentElement.className = "";
      localStorage.setItem("theme", "default");
    } else {
      document.documentElement.className = theme;
      localStorage.setItem("theme", theme);
    }
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      switchTheme(savedTheme);
    }
  }, []);

  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState();

  setupAxios(setShow, setMsg, navigate);

  return (
    <div className="App">
      <ShowContext.Provider value={{ setShow, setMsg }}>
        <TopControl switchTheme={switchTheme} />
        <LaunchPage />
        <Header />
        <Content />
        <SliderFooter />
        <Footer />

        <ExternalModal
          showModal={showModal}
          handleClose={closeModal}
          externalLink={externalLink}
          message={
            <>
              <div className="text-center px-3 mt-2">
                <p>
                  <b className="custom-translate">
                    You are being redirected to an external website. Please note
                    that MSBTE is not responsible for external websites content
                    & privacy policies.
                  </b>
                </p>
              </div>
            </>
          }
          onAccept={handleAccept}
          size={"sm"}
        />
        <PreventInspect />
      </ShowContext.Provider>
    </div>
  );
}

//----------------------Axios Interceptors--------------------------------------
function setupAxios(setShow, setMsg, navigate) {
  API.interceptors.request.use(
    function (config) {
      const token = JSON.parse(localStorage.getItem("token"));
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  API.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response;
      if (status !== undefined && status.status === 401) {
        navigate("/");
      } else if (status !== undefined && status.status === 429) {
        setShow(true);
        setMsg(
          "Server is Busy. Please wait for some seconds. Your Response will not be saved till this message keeps appearing."
        );
      } else if (status === undefined || !status) {
        setShow(true);
        setMsg(
          "There is some problem with server response.Your Response will not be saved till this message keeps appearing."
        );
      }
      if (!error.response) {
        setShow(true);
        setMsg(
          "Your Connection to server is lost. Please Contact Internet Service Provider"
        );
      }
      return Promise.reject(error);
    }
  );
}
//----------------------------End of Axios Interceptors-------------------------

export default App;
