import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutMsbte from "../Components/AboutMsbte";
import AccessibilityStatement from "../Components/AccessibilityStatement";
import Activities from "../Components/Activities";
import Admin from "../Components/Admin";
import AdminDash from "../Components/AdminDash";
import AuthenticationAndVerification from "../Components/AuthenticationAndVerification";
import CareerFair from "../Components/CareerFair";
import AurangabadRegion from "../Components/ChatrapatiShambhajiNagarRegion";
import AllCircular from "../Components/Circulars/AllCircular";
import ContactUs from "../Components/ContactUs";
import CoreValues from "../Components/CoreValues";
import DirectorProfile from "../Components/DirectorProfile";
import Disclaimer from "../Components/Disclaimer";
import DuplicateCertificate from "../Components/DuplicateCertificate";
import EquivalenceCertificate from "../Components/EquivalenceCertificate";
import EquivalenceOtherBoard from "../Components/EquivalenceOtherBoard";
import Faq from "../Components/Faq";
import Feedback from "../Components/Feedback";
import GoverningBoard from "../Components/GoverningBoard";
import GoverningCouncil from "../Components/GoverningCouncil";
import HSCEquivalence from "../Components/HSCEquivalence";
import Help from "../Components/Help";
import ImportanceOfDiploma from "../Components/ImportanceOfDiploma";
import MediaCorner from "../Components/MediaCorner";
import MigrationCrtificate from "../Components/MigrationCertificate";
import MsbteAct from "../Components/MsbteAct";
import MsbteOfficers from "../Components/MsbteOfficers";
import MumbaiRegion from "../Components/MumbaiRegion";
import NagpurRegion from "../Components/NagpurRegion";
import NonAICTE_Courses from "../Components/NonAICTE_Courses";
import { default as NonAICTE_Institutes } from "../Components/NonAICTE_Institutes";
import OnlineActivities from "../Components/OnlineActivities";
import OnlineMigrationcertificate from "../Components/OnlineMigrationcertificate";
import OnlineProceduretranscertificate from "../Components/OnlineProceduretranscertificate";
import OnrsStudents from "../Components/OnrsStudents";
import OrganizationalStructure from "../Components/OrganizationalStructure";
import OurAssociation from "../Components/OurAssociation";
import Policie from "../Components/Policie";
import PuneRegion from "../Components/PuneRegion";
import QualityPolicy from "../Components/QualityPolicy";
import ScreenReaderAccess from "../Components/ScreenReaderAccess";
import Search from "../Components/Search";
import Services from "../Components/Services";
import SiteMap from "../Components/SiteMap";
import TermsAndCondition from "../Components/TermsAndCondition";
import TranscriptCertificate from "../Components/TranscriptCertificate";
import VideoLectures from "../Components/VideoLectures";
import ViewInstitutes from "../Components/ViewInstitutes";
import Vision from "../Components/Vision";
import WithoutNav from "../Components/WithoutNav";
import Main from "./Main";

function Content() {
  return (
    <>
      <main id="imageMainHomePage" className="post">
        <div className="container" id="paddingForContainer">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/aboutMsbte" element={<AboutMsbte />} />
            <Route path="/coreValues" element={<CoreValues />} />
            <Route path="/visionAndMission" element={<Vision />} />
            <Route path="/qualityPolicy" element={<QualityPolicy />} />
            <Route
              path="/organizationalStructure"
              element={<OrganizationalStructure />}
            />
            <Route path="/governingBoard" element={<GoverningBoard />} />
            <Route path="/governingCouncil" element={<GoverningCouncil />} />
            <Route path="/ourAssociation" element={<OurAssociation />} />
            <Route path="/msbteAct" element={<MsbteAct />} />
            <Route path="/msbteOfficers" element={<MsbteOfficers />} />
            <Route path="/mumbai_region" element={<MumbaiRegion />} />
            <Route path="/pune_region" element={<PuneRegion />} />
            <Route path="/nagpur_region" element={<NagpurRegion />} />
            <Route
              path="/chatrapati_shambhaji_nagar_region"
              element={<AurangabadRegion />}
            />

            <Route path="/careerFair" element={<CareerFair />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/onrsStudents" element={<OnrsStudents />} />
            <Route
              path="/transcriptCertificate"
              element={<TranscriptCertificate />}
            />
            <Route
              path="/onlinetranscriptCertificate"
              element={<OnlineProceduretranscertificate />}
            />
            <Route
              path="/OnlineMigrationcertificate"
              element={<OnlineMigrationcertificate />}
            />
            <Route
              path="/duplicateCertificate"
              element={<DuplicateCertificate />}
            />
            <Route
              path="/migrationCertificate"
              element={<MigrationCrtificate />}
            />
            <Route
              path="/equivalenceCertificate"
              element={<EquivalenceCertificate />}
            />
            <Route
              path="/authenticationAndVerification"
              element={<AuthenticationAndVerification />}
            />
            <Route
              path="/equivalenceOtherBoard"
              element={<EquivalenceOtherBoard />}
            />
            <Route path="/hscEquivalence" element={<HSCEquivalence />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/mediaCorner" element={<MediaCorner />} />
            <Route path="/admindash" element={<AdminDash />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/policie" element={<Policie />} />
            <Route
              path="/terms-and-condition"
              element={<TermsAndCondition />}
            />
            <Route
              path="/accessibility-statement"
              element={<AccessibilityStatement />}
            />
            <Route path="/help" element={<Help />} />
            <Route path="/services" element={<Services />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/sitemap" element={<SiteMap />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/online-activities" element={<OnlineActivities />} />
            <Route
              path="/screen-reader-access"
              element={<ScreenReaderAccess />}
            />

            <Route path="/msbteVideoLectures" element={<VideoLectures />} />
            <Route path="/director_profile" element={<DirectorProfile />} />
            <Route path="/search" element={<Search />} />
            <Route
              path="/importantce-of-diploma"
              element={<ImportanceOfDiploma />}
            />

            <Route
              path="/nonaicteInstitutes"
              element={<NonAICTE_Institutes />}
            />
            <Route path="/nonaicteCourses" element={<NonAICTE_Courses />} />

            <Route path="/viewInstitutes" element={<ViewInstitutes />} />
            <Route path="/all_circulars" element={<AllCircular />} />
            <Route path="/screenReader" element={<ScreenReaderAccess />} />
            <Route element={<WithoutNav />}>
              <Route path="/admin" element={<Admin />} />
            </Route>
          </Routes>
        </div>
      </main>
    </>
  );
}

export default Content;
