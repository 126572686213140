import React from "react";

function PuneRegion() {
  // const s3Path = process.env.REACT_APP_S3_PATH;
  return (
    <div>
      <div className="card mb-3  borderforcard paddingAboutMsbte">
        <div className="card-header backgroundColorCardHeader linearimg">
          <h1 className="fontForHeader custom-translate">Pune Region</h1>
        </div>
        <br />
        <div className="table-responsive">
          <table className="table table-bordered region-table">
            <thead className="thead-dark">
              <tr>
                <th className="custom-translate">Sr. No.</th>
                <th className="custom-translate">Name</th>
                <th className="custom-translate">Designation</th>
                {/* <th className="custom-translate">E-mail</th> */}
                {/* <th className="custom-translate">Officers Image</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td className="custom-translate">Shri. Vijay Marutrao Kolhe</td>
                <td className="custom-translate">Deputy Secretary (T.)</td>
                {/* <td>
                  <a href=""></a>
                </td> */}
              </tr>
              <tr>
                <td>2</td>
                <td className="custom-translate">Mrs. Shital S. Fartade</td>
                <td className="custom-translate">System Analyst</td>
                {/* <td>
                  <a href=""></a>
                </td> */}
              </tr>
              <tr>
                <td>3</td>
                <td className="custom-translate">
                  Mrs. Amita Rajendra Kaigude
                </td>
                <td className="custom-translate">Assistant Secretary (T.)</td>
                {/* <td>
                  <a href=""></a>
                </td> */}
                {/* <td>
                <img src={s3Path + "/Images1/Rajesh.jpg"  alt="Rajesh"/>
              </td> */}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PuneRegion;
