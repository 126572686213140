import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import API from "../API";
import FormField from "../utils/FormField";
// import { de } from "../utils/Helper";

function Feedback() {
  const [message, setMessage] = useState();

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name cannot exceed 50 characters")
      .required("Name is required"),

    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),

    number: Yup.string()
      .matches(/^\d+$/, "Number must be digits only")
      .min(10, "Number must be at least 10 digits")
      .max(15, "Number cannot exceed 15 digits")
      .required("Number is required"),

    feedback: Yup.string()
      .min(10, "Feedback must be at least 10 characters")
      .max(500, "Feedback cannot exceed 500 characters")
      .required("Feedback is required"),
  });

  const [loading, setLoading] = useState(false);

  return (
    <div className="card mb-3  borderforcard paddingAboutMsbte">
      <div className="card-header backgroundColorCardHeader">
        <h1 className="fontForHeader custom-translate">Feedback</h1>
      </div>
      <div className="row g-0">
        <div className="card-body removePadding p-5">
          <Formik
            initialValues={{
              name: "",
              email: "",
              number: "",
              feedback: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              saveFeedback(values);
              actions.resetForm();
            }}
          >
            {({ values, isValid, dirty }) => (
              <>
                <Form>
                  <div className="row">
                    <div className="col-sm-6">
                      <FormField
                        values={values}
                        name="name"
                        heading="Your Name"
                      />
                    </div>
                    <div className="col-sm-6">
                      <FormField
                        values={values}
                        name="email"
                        heading="Your Email"
                      />
                    </div>
                    <div className="col-sm-12">
                      <FormField
                        values={values}
                        name="number"
                        heading="Your Number"
                      />
                    </div>
                    <div className="col-sm-12">
                      <FormField
                        values={values}
                        as="textarea"
                        name="feedback"
                        events={{
                          rows: 4,
                          cols: 50,
                        }}
                        heading="Your Feedback"
                      />
                    </div>
                    <div className="text-center pt-3">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading || !isValid || !dirty}
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                    {message && (
                      <div className="col-sm-12 col-md-6 offset-md-3">
                        <div className="text text-success m-2 text-center">
                          {message}
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );

  function saveFeedback(values) {
    setLoading(true);
    API.post("/feedback", values)
      .then((res) => {
        setLoading(false);
        setMessage(res.data.message);
        setTimeout(() => {
          setMessage();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
}

export default Feedback;
