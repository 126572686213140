import React from "react";
import GoogleTranslator from "./GoogleTranslator";
import ThemeAndFontChanger from "./ThemeAndFontChanger";
import ToggleMarathi from "./ToggleMarathi";

function TopControl({ switchTheme }) {
  const scrollToMainContent = (e) => {
    e.preventDefault();

    const mainContent = document.getElementById("imageMainHomePage");
    const offset = 65;
    const yCoordinate =
      mainContent.getBoundingClientRect().top + window.pageYOffset - offset;

    window.scrollTo({
      top: yCoordinate,
      behavior: "smooth",
    });
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light" id="topBack">
      <div className="container align-items-center justify">
        <ul>
          <li>
            <span className="d-block custom-translate">महाराष्ट्र शासन</span>
            <span className="custom-translate">Government of Maharashtra</span>
          </li>
          <li className="m-none">
            <a
              href="https://msbte.org.in/index.html"
              target="_blank"
              className="text-white custom-translate"
            >
              Visit Old site
            </a>
          </li>
          <li>
            <a
              href="#main-content"
              onClick={scrollToMainContent}
              className="custom-translate"
            >
              Skip to main content
            </a>
          </li>

          {/* <li>
            <span style={{ fontFamily: "shivaji" }}>
              {" "}
              उच्च व तंत्रशिक्षण विभाग{" "}
            </span>
            <br />
            Higher & Technical Education Department
          </li> */}
        </ul>
        <ul>
          <li>
            {/* <ScreenReader /> */}
            <a href="/screenreader">
              <i className="fa fa-volume-up" title="Screen Reader"></i>
            </a>
          </li>

          <li>
            <a href="/sitemap">
              <i className="fa fa-sitemap" title="Sitemap"></i>
            </a>
          </li>

          <ThemeAndFontChanger switchTheme={switchTheme} />
          <li>
            <ToggleMarathi />
          </li>
          <li className="m-none">
            <GoogleTranslator />
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default TopControl;
