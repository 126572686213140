import React, { useRef } from "react";
import ReactPlayer from "react-player";

const ImpVideoDiploma = (props) => {
  const { videoList } = props;
  const players = useRef([]);

  const handlePlay = (index) => {
    players.current.forEach((video, idx) => {
      if (idx !== index && video && !video.paused) {
        video.pause();
      }
    });
  };

  return (
    <>
      {videoList.map((vidObj, index) => (
        <div className="col-sm-6 p-2" key={vidObj.id}>
          {ReactPlayer.canPlay(vidObj.url) ? (
            <ReactPlayer
              width="100%"
              controls
              url={vidObj.url}
              onPlay={() => handlePlay(index)}
              ref={(player) => {
                players.current[index] = player;
              }}
            />
          ) : (
            <video
              width="100%"
              controls
              ref={(el) => {
                players.current[index] = el;
              }}
              onPlay={() => handlePlay(index)}
              src={vidObj.url}
            ></video>
          )}
        </div>
      ))}
    </>
  );
};

export default ImpVideoDiploma;
