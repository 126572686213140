import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import API from "../API";
import { ShowContext } from "../App";
import { de } from "../utils/Helper";

const Circulars = () => {
  const { setShow, setMsg } = useContext(ShowContext);
  const [circulars, setCirculars] = useState();
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState("");
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const marqueeRef = useRef(null);

  const handleMouseEnter = () => {
    if (marqueeRef.current) {
      marqueeRef.current.stop();
    }
  };

  const handleMouseLeave = () => {
    if (marqueeRef.current) {
      marqueeRef.current.start();
    }
  };

  useEffect(() => {
    getCirculars(setCirculars, setShow, setMsg);
  }, []);

  return (
    <>
      <div className="card border border-light shadow-0 mb-3">
        <div className="card-header backgroundColorCircular text-center">
          <span className="all-cricular pull-right">
            <Link to={"/all_circulars"} className="custom-translate">
              All Circulars
            </Link>
          </span>
          <h4 className="custom-translate text-center my-2">CIRCULARS</h4>
        </div>
        <div className="card-body overflow-auto heighttableDiv">
          {circulars ? (
            <marquee
              behavior="scroll"
              direction="up"
              scrollamount="2"
              loop="infinite"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              ref={marqueeRef}
              className="circularBox"
            >
              <ul>
                {circulars &&
                  circulars
                    .filter((circular) => circular.category === 1)
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .slice(0, 20)
                    .map((circular, index) => (
                      <li className="circularsList" key={index}>
                        <a
                          href="javascript:void(0);"
                          className="custom-translate py-1"
                          aria-disabled="true"
                          onClick={() => {
                            handleShow();
                            setFile(circular.url);
                          }}
                        >
                          {circular.heading}
                        </a>
                      </li>
                    ))}
              </ul>
            </marquee>
          ) : null}
        </div>
        <Modal
          show={showModal}
          onHide={handleClose}
          centered
          contentClassName="modal-pdf-content"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body closeButton>
            <iframe title="myFrame" className="w-100 h-100" src={file}></iframe>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

async function getCirculars(setCirculars, setShow, setMsg) {
  API.get("circulars")
    .then((res) => {
      if (res.data.status === "success") {
        setCirculars(JSON.parse(de(res.data.data)));
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}

export default Circulars;
