import React from "react";

function DuplicateCertificate() {
  return (
    <>
      <div className="row "></div>
    </>
  );
}

export default DuplicateCertificate;
