import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import API from "../API";
import { ShowContext } from "../App";
import { de, en } from "../utils/Helper";

function Search() {
  const { setShow, setMsg } = useContext(ShowContext);
  let [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("query"));
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState("");
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    setQuery(searchParams.get("query"));
  }, [searchParams]);

  useEffect(() => {
    getResults(setList, query, setShow, setMsg);
  }, [query, setMsg, setShow]);

  return (
    <>
      <div className="card mb-3  borderforcard paddingAboutMsbte">
        <div className="card-header backgroundColorCardHeader">
          <h1 className="fontForHeader">
            <span className="custom-translate">Search Results for</span> "
            <em>{query}</em>"
          </h1>
        </div>
        <div className="row g-0">
          {list && list.length > 0 ? (
            list.map((item) => (
              <div className="px-2 my-1">
                <div className="p-2">
                  {item.url.includes(".pdf") ? (
                    <Link
                      className="fontqualitypolicy"
                      aria-disabled="true"
                      onClick={() => {
                        handleShow();
                        setFile(item.url);
                      }}
                    >
                      [PDF]
                      {item.heading}
                    </Link>
                  ) : (
                    <a
                      className="fontqualitypolicy"
                      href={item.url}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {item.heading}
                    </a>
                  )}
                </div>
              </div>
            ))
          ) : (
            <h2>No Results found!</h2>
          )}
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        contentClassName="modal-pdf-content"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body closeButton>
          <iframe title="myFrame" className="w-100 h-100" src={file}></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
}

function getResults(setList, query, setShow, setMsg) {
  API.get("search", { params: { search_text: en("" + query + "") } })
    .then((res) => {
      if (res.data.status === "success") {
        setList(JSON.parse(de(res.data.data)));
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.message);
    });
}

export default Search;
