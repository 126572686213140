import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

function OnlineMigrationcertificate() {
  const s3Path = process.env.REACT_APP_S3_PATH;

  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState("");
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  return (
    <>
      <div className="card mb-3  borderforcard paddingAboutMsbte OnlineProceduret p-4">
        <div className="card-headers">
          <h3 className="custom-translate">
            Online Procedure for Migration certificate
          </h3>
          <button className="back-btn">
            <Link
              to={{ pathname: "/migrationCertificate" }}
              className="custom-translate"
            >
              Back
            </Link>
          </button>
        </div>
        <br />
        <p>
          <span className="custom-translate">
            Application for Migration certificate is available on
            www.msbte.org.in website &gt; Student &gt; Transcript &gt; apply
            &gt;
          </span>
          <a
            href="https://aaplesarkar.mahaonline.gov.in/en/"
            target="_blank"
            rel="noreferrer"
          >
            www.aaplesarkar.mahaonline.gov.in
          </a>
          .
        </p>
        <strong className="custom-translate">OR</strong>
        <p>
          <a
            href="https://aaplesarkar.mahaonline.gov.in/en/"
            target="_blank"
            rel="noreferrer"
          >
            www.aaplesarkar.mahaonline.gov.in
          </a>
          <span className="custom-translate">
            New User Registration &gt; Select 'Option 2' &gt; Fill the form for
            Registration
          </span>
        </p>

        <h6 className="custom-translate">
          Required documents for Migration Certificate:
        </h6>

        <ol>
          <li className="custom-translate">
            Diploma Mark sheets of last two Semesters (for semester
            pattern)/final year mark sheet (for yearly Pattern)
          </li>
          <li className="custom-translate">
            Provisional /Final Board Certificate. (Provisional certificate is
            valid only for 6 months)
          </li>
          <li className="custom-translate">
            Leaving certificate (Last attended Diploma Institute)
          </li>
          <p className="custom-translate">
            Fee for Migration certificate is Rs. 300/-
          </p>
          <b className="custom-translate">
            #failed or incomplete diploma Students have to fill the Application
            form only through offline mode. (Required Documents: Attended
            semesters mark sheets and Diploma Inst. Leaving Certificate)#
          </b>
        </ol>

        <h6 className="custom-translate">
          Fee for transcript certificate is as follows,
        </h6>

        <ol>
          <li className="custom-translate">
            Visit: aaplesarkar.mahaonline.gov.in
          </li>
          <li className="custom-translate">
            Click on track your application (अर्ज मागोवा)
          </li>
          <li className="custom-translate">
            Select Higher & Technical Education Department
          </li>
          <li className="custom-translate">
            Select Maharashtra state board of Technical Education
          </li>
          <li className="custom-translate">Select Migration Certificate</li>
          <li className="custom-translate">Enter Application Id</li>
          <li className="custom-translate">Download Certificate</li>
          <br />
          <p className="custom-translate">
            (No signature Validation is required for Adobe Reader version 9.3
            and above)
          </p>
        </ol>

        <h6 className="custom-translate">
          Steps to Validate Digital Signature:
        </h6>
        <ol>
          <li className="custom-translate">
            VSave the Certificate and open the Certificate from destination,
            Right click on digital signature panel.
          </li>
          <li className="custom-translate">
            Select 'show signature properties'
          </li>
          <li className="custom-translate">
            Click on 'show signer's certificate'
          </li>
          <li className="custom-translate">
            Click on 'Trust' and click on 'Add to Trusted Certificates' then
            click on 'Ok'
          </li>
          <li className="custom-translate">
            Click on 'check box of certified documents' and below 3 check box
            then click on 'Ok'
          </li>
          <li className="custom-translate">Click on 'validate signature.</li>
          <br />
        </ol>

        <b className="custom-translate">
          For Migration certificate related Query, you can contact:
          desk50migration@msbte.com OR 022- 62542156
        </b>
        <br />
        <h5>
          <span
            className="link-primary1 custom-translate"
            aria-disabled="true"
            onClick={() => {
              handleShow();
              setFile(s3Path + "/files/migration.pdf");
            }}
          >
            Sample application form for Migration certificate
          </span>
        </h5>
      </div>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        contentClassName="modal-pdf-content"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body closeButton>
          <iframe title="myFrame" className="w-100 h-100" src={file}></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OnlineMigrationcertificate;
