import React from "react";

function NagpurRegion() {
  return (
    <div>
      <div className="card mb-3 borderforcard paddingAboutMsbte">
        <div className="card-header backgroundColorCardHeader linearimg">
          <h1 className="fontForHeader custom-translate">Nagpur Region</h1>
        </div>
        <br />
        <div className="table-responsive">
          <table className="table table-bordered region-table">
            <thead className="thead-dark">
              <tr>
                <th className="custom-translate">Sr. No.</th>
                <th className="custom-translate">Name</th>
                <th className="custom-translate">Designation</th>
                {/* <th className="custom-translate">E-mail</th> */}
                {/* <th className="custom-translate">Officers Image</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td className="custom-translate">
                  Shri. Usmani Mohammad Shaheed Afzal Ahmad
                </td>
                <td className="custom-translate">Dy. Secretary (T) </td>
                {/* <td>
                  <a href=""></a>
                </td>
                {/* <td>
                  <img
                    src="/assets/Images1/Dy.Secretary(T)_Nagpur.jpg"
                    alt="Kanchan"
                  />
                </td> */}
              </tr>

              <tr>
                <td>2</td>
                <td className="custom-translate">Mrs. Vaishali Anirudh Raje</td>
                <td className="custom-translate">System Analyst</td>
                {/* <td>
                  <a href=""></a>
                </td> */}
              </tr>
              <tr>
                <td>3</td>
                <td className="custom-translate">
                  Shri. Dilip Kumar Hulasram Lilhare
                </td>
                <td className="custom-translate">Assistant Secretary (T.)</td>
                {/* <td>
                  <a href=""></a>
                </td> */}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default NagpurRegion;
