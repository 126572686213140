const VideoLinks = [
  {
    title: "DIP M2 U1 01 Functions",
    subject: "Applied Maths",
    url: "https://www.youtube.com/embed/wtLZ_bXrNGk",
  },
  {
    title: "DIP M2 U1 01 Limits",
    subject: "Applied Maths",
    url: "https://www.youtube.com/embed/48vW0rzXCwI",
  },
  {
    title: "DIP M2 U1 03 Derivatives I",
    subject: "Applied Maths",
    url: "https://www.youtube.com/embed/9KlMn4f_F94",
  },
  {
    title: "DIP M2 U1 03 Derivatives II",
    subject: "Applied Maths",
    url: "https://www.youtube.com/embed/Ih2I0_SS3zg",
  },
  {
    title: "DIP M2 U1 03 Derivatives III",
    subject: "Applied Maths",
    url: "https://www.youtube.com/embed/LOzDxsNUunY",
  },
  {
    title: "DIP M2 U1 03 Derivatives IV",
    subject: "Applied Maths",
    url: "https://www.youtube.com/embed/Ug_ulPVq_h8",
  },
  {
    title: "DIP M2 U1 04 Application of Derivetives",
    subject: "Applied Maths",
    url: "https://www.youtube.com/embed/i6FNE_iWN9A",
  },
  {
    title: "DIP M2 U2 01 Simple Integration Part 01",
    subject: "Applied Maths",
    url: "https://www.youtube.com/embed/KpXCII3EpAo",
  },
  {
    title: "DIP M2 U2 01 Simple Integration Part 02 ",
    subject: "Applied Maths",
    url: "https://www.youtube.com/embed/JLMSmsO9Hos",
  },
  {
    title: "DIP M2 U2 03 Methods of Integration I",
    subject: "Applied Maths",
    url: "https://www.youtube.com/embed/FH-Ct9QeW4c",
  },
  {
    title: "DIP M2 U2 04 Methods of Integration II",
    subject: "Applied Maths",
    url: "https://www.youtube.com/embed/n4s9f6Gw6bg",
  },
];

function VideoLectures() {
  return (
    <div className="card mb-3 borderforcard paddingAboutMsbte">
      <div className="card-header backgroundColorCardHeader">
        <h1 className="fontForHeader custom-translate">MSBTE Video Lectures</h1>
      </div>
      <div className="row g-0">
        <div className="card-body table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr className="text-center">
                <th scope="col" className="custom-translate" width="100px">
                  Sr. No.
                </th>
                <th scope="col" className="custom-translate">
                  Subject
                </th>

                <th scope="col" className="custom-translate">
                  Description
                </th>
                <th scope="col" className="custom-translate">
                  Video
                </th>
              </tr>
            </thead>
            <tbody>
              {VideoLinks.length > 0 &&
                VideoLinks.map((item, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}.</td>
                    <td className="custom-translate">{item.subject}</td>
                    <td className="custom-translate">{item.title}</td>
                    <td>
                      <iframe
                        width="100%"
                        height="200"
                        src={item.url}
                        title={item.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default VideoLectures;
