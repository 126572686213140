const HomePageTranslation = {
  "Shri. Eknath Shinde": "श्री. एकनाथ शिंदे",
  "Hon'ble Chief Minister": "माननीय मुख्यमंत्री",
  "Shri. Devendra Fadnavis": "श्री. देवेंद्र फडणवीस",
  "Hon'ble Deputy Chief Minister": "माननीय उपमुख्यमंत्री",
  "Hon’ble Deputy Chief Minister": "माननीय उपमुख्यमंत्री",
  "Shri. Ajit Pawar": "श्री. अजित पवार",
  "Shri. Chandrakant Patil": "श्री. चंद्रकांत पाटील",
  "Hon'ble Minister of Higher and Technical Education":
    "उच्च व तंत्रशिक्षण मंत्री",
  "Read less": "कमी वाचा",
  "Read More": "अधिक वाचा",
  "FROM DIRECTOR'S DESK": "संचालकांच्या डेस्कवरून",
  "Shri Pramod A. Naik": "श्री. प्रमोद ए. नाईक",
  "Director MSBTE": "संचालक, महाराष्ट्र राज्य तंत्रशिक्षण मंडळ",

  "Maharashtra State Board of Technical Education (MSBTE) is an autonomous Board of Government of Maharashtra mandated to regulate matters pertaining to Diploma Level Technical education in the state. Curriculum development- it’s efficient implementation strategies through faculty development programs, student development initiatives, industry-institution interaction, Academic monitoring and various online evaluation activities have been the significant insignia of MSBTE.":
    "महाराष्ट्र राज्य तंत्रशिक्षण मंडळ (एम. एस. बी. टी. ई.) हे महाराष्ट्र सरकारचे एक स्वायत्त मंडळ आहे, जे राज्यातील पदविका पातळीवरील तंत्रशिक्षणासंबंधित बाबींचे नियमन करते. अभ्यासक्रम विकास-विद्याशाखा विकास कार्यक्रम, विद्यार्थी विकास उपक्रम, उद्योग-संस्था संवाद, शैक्षणिक देखरेख आणि विविध ऑनलाइन मूल्यांकन उपक्रमांद्वारे त्याची कार्यक्षम अंमलबजावणी धोरणे एमएसबीटीईचे महत्त्वपूर्ण चिन्ह आहेत.",

  "In the recent past, MSBTE has made its foray into skill development activities, thus broadening its horizon and area of service. However, the revision of curricular to suit the essentially of industry as expected from diploma students had become a dire need and of late a very important parameter for employability which has been accomplished and implemented from the academic year 2017-18. Having been closely associated with the system and from the suggestions and feedback of the stakeholders, it shall be my endeavor to revamp the various processes and adopt better technological implementation & evaluation techniques for the benefit of stakeholder students.":
    "अलीकडच्या काळात, एम. एस. बी. टी. ई. ने कौशल्य विकास उपक्रमांमध्ये प्रवेश केला आहे, त्यामुळे त्याचे क्षितिज आणि सेवा क्षेत्र विस्तृत झाले आहे. तथापि, डिप्लोमा विद्यार्थ्यांकडून अपेक्षित असलेल्या उद्योगाच्या मूलभूततेनुसार अभ्यासक्रमात सुधारणा करणे ही एक गंभीर गरज बनली होती आणि रोजगार क्षमतेसाठी एक अतिशय महत्त्वाचा मापदंड होता जो शैक्षणिक वर्ष 2017-18 पासून पूर्ण आणि अंमलात आणला गेला आहे. प्रणालीशी जवळून संबंधित असल्याने आणि हितधारकांच्या सूचना आणि अभिप्रायावरून, विविध प्रक्रियांमध्ये सुधारणा करणे आणि हितधारक विद्यार्थ्यांच्या फायद्यासाठी अधिक चांगल्या तांत्रिक अंमलबजावणी आणि मूल्यांकन तंत्राचा अवलंब करणे हा माझा प्रयत्न असेल.",

  "Circular/Office Order": "परिपत्रक/कार्यालय आदेश",
  "Extra Curricular Activities": "अतिरिक्त अभ्यासक्रम उपक्रम",
  Training: "प्रशिक्षण",
  GR: "जीआर",
  Downloads: "डाउनलोड करा",
  CIRCULARS: "परिपत्रके",
  "All Circulars": "सर्व परिपत्रके",

  "Sr.No": "क्र.सं.",
  Date: "तारीख",
  News: "बातमी",

  "Summer 2022 Exam photocopy & Reassessment schedule for State Government Approved Short Term (Non-AICTE) courses. (Marathi)(334 kb)pdf":
    "उन्हाळा 2022 चा परीक्षा फोटोकॉपी व पुनर्मूल्यांकन वेळापत्रक राज्य सरकारी मान्यता प्राप्त लघु कालीन (गैर-AICTE) कोर्सेससाठी. (मराठी)(334 KB)pdf",
  "Sample Question Paper Search": "नमुना प्रश्नपत्रिका शोधा",
  "Draft Copy For Ciruculum": "पाठ्यचर्या साठी मसुदा प्रती",
  "MSBTE Latest Videos": "MSBTE ची ताज्या व्हिडिओ",
  "MSBTE E-Content Home": "MSBTE ई- सामग्री मुख्यपृष्ठ",
  "I-Scheme Implimentation": "I-योजनेची अंमलबजावणी",
  "Academic schedule for newly admitted First Year Diploma Pharmacy students A.Y. 2022-23.(English)(171 kb)pdf":
    "नवीन प्रवेशित पहिल्या वर्षाच्या डिप्लोमा फार्मसी विद्यार्थ्यांसाठी शैक्षणिक वेळापत्रक A.Y. 2022-23.(इंग्रजी)(171 KB)pdf",
  "Special provision for filling Winter 2022 Examination form.(English)(299 kb)pdf":
    "हिवाळा 2022 च्या परीक्षा फॉर्म भरण्यासाठी विशेष तरतूद.(इंग्रजी)(299 KB)pdf",
  "First Extension of MSBTE/TENDER/2022/01 (English)(128 kb)pdf":
    "MSBTE/TENDER/2022/01 यांचा पहिला विस्तार (इंग्रजी)(128 KB)pdf",
  "One Week faculty Development Training program (Online mode) on “ Advances in Internet of Things and its Applications” from 21st to 25th November, 2022 organized by Government Polytechnic, Awasari (Khurd), Pune. (Marathi)(294 kb)pdf":
    "सर्कार पॉलिटेक्निक, अवसरी (खेर्ड), पुणे द्वारे 21 ते 25 नोव्हेंबर 2022 दरम्यान इंटरनेट ऑफ थिंग्ज आणि त्याचे अनुप्रयोग या विषयावर एक आठवड्याचा ऑनलाइन शिक्षकेत्तर विकास प्रशिक्षण कार्यक्रम आयोजित केला जात आहे. (मराठी)(294 KB)pdf",
  "Tender Notice for Printing and Supply of Examination Stationery - Answer Booklet.(English)(460 kb)pdf":
    "प्रश्नपत्रिका छपाई आणि परीक्षा लेखनपत्रिका गोळा करण्यासाठी टेंडरnotice. (इंग्रजी)(460 KB)pdf",
  "Summer 2022 Re-Examination Result Declaration.(Marathi)(80 kb)pdf":
    "उन्हाळा 2022 पुनःपरीक्षा निकाल जाहीर. (मराठी)(80 KB)pdf",
  "Enrollment and Exam form schedule for Newly Admitted students for A.Y. 2022-23.(Marathi)(pdf":
    "नवीन प्रवेशित विद्यार्थ्यांसाठी A.Y. 2022-23 साठी नामांकन व परीक्षा फॉर्म वेळापत्रक.(मराठी)(pdf",
  "Winter 2022 Exam result declaration.(Marathi)pdf":
    "हिवाळा 2022 परीक्षा निकाल जाहीर.(मराठी)pdf",
  "Winter 2022 Exam Photocopy & Reassessment schedule.(Marathi)pdf":
    "हिवाळा 2022 परीक्षा फोटोकॉपी व पुनर्मूल्यांकन वेळापत्रक.(मराठी)pdf",
  "ISTE Approved One Week Online Short Term Training Program On Engineering Research Methodology From 27th Feb. To 3rd March, 2023 Organized By Dept. Of Mechanical Engineering M. H. Saboo Siddik Polytechnic, Mumbai.(English)pdf":
    "इंजिनिअरिंग संशोधन पद्धतीशास्त्रात ISTE मान्यताप्राप्त एक आठवड्याचा ऑनलाइन लघुकालीन प्रशिक्षण कार्यक्रम 27 फेब्रुवारी ते 3 मार्च 2023 दरम्यान, एम. एच. साबू सिद्दिक पॉलिटेक्निक, मुंबई द्वारे आयोजित केलेला.(इंग्रजी)pdf",
  'MSBTE Approved (Self Financed) Two Days Faculty Development Programme On  Recent Trends In Electric Vehicles And Artificial Intelligence " from 01st Mar 2023 to 02nd Mar 2023 Organized BY Electrical Engineering Department Sandip Foundation’s Sandip Polytechnic, Nashik.(English)pdf':
    'MSBTE मान्यताप्राप्त (स्वतंत्र वित्त) इलेक्ट्रिक वाहन व आर्टिफिशियल इंटेलिजन्स मधील नवीनतम प्रवृत्त्या" या विषयावर दोन दिवसांचे शिक्षकेत्तर विकास कार्यक्रम 01 मार्च 2023 ते 02 मार्च 2023 आयोजित केलेले वीज यंत्रणाच्या विभागाने सांडिप फाउंडेशनच्या सांडिप पॉलिटेक्निक, नाशिक द्वारे.(इंग्रजी)pdf',
  'Two days Faculty Development Training program on CNC Programming and Operating(For non teaching faculty)" from 16th to 17th February, 2023 at Sandip Polytechnic, Nashik.':
    'संदिप पॉलिटेक्निक, नाशिक येथे 16 ते 17 फेब्रुवारी 2023 दरम्यान CNC प्रोग्रामिंग व ऑपरेटिंग (गैर-शिक्षक वर्गासाठी)" विषयावर दोन दिवसांचे शिक्षकेत्तर विकास प्रशिक्षण कार्यक्रम.',
  "Deputation of Faculties Names for an Online Industrial Training program at National Institute of Electronics & Information Technology (NIELIT), Aurangabad for the faculties of Mechanical, Electrical, Electronics, E&Tc, Civlil Engg. Dept.from 13-02-2023 to 24-02-2023.":
    "राष्ट्रीय इलेक्ट्रॉनिक्स व माहिती तंत्रज्ञान संस्थेत (NIELIT), औरंगाबाद येथे यांत्रिकी, इलेक्ट्रिकल, इलेक्ट्रॉनिक्स, E&Tc, सिव्हिल अभियांत्रिकी विभागातील शिक्षकांसाठी ऑनलाइन औद्योगिक प्रशिक्षण कार्यक्रमासाठी शिक्षकांचे नामांकन.",
  "A.Y. 2022-23 Revised Academic Calendar for Even Semester Academic Term.":
    "A.Y. 2022-23 च्या समर सेमिस्टर शैक्षणिक टर्मसाठी सुधारित शैक्षणिक कॅलेंडर.",
  "Academic schedule for newly admitted First Year Diploma Pharmacy students A.Y. 2022-23.":
    "नवीन प्रवेशित पहिल्या वर्षाच्या डिप्लोमा फार्मसी विद्यार्थ्यांसाठी शैक्षणिक वेळापत्रक A.Y. 2022-23.",
  "Special provision for filling Winter 2022 Examination form.":
    "हिवाळा 2022 च्या परीक्षा फॉर्म भरण्यासाठी विशेष तरतूद.",
  "First Extension of MSBTE/TENDER/2022/01":
    "MSBTE/TENDER/2022/01 यांचा पहिला विस्तार",
  "Enrollment and Exam form schedule for Newly Admitted students for A.Y. 2022-23.":
    "नवीन प्रवेशित विद्यार्थ्यांसाठी A.Y. 2022-23 साठी नामांकन व परीक्षा फॉर्म वेळापत्रक.",
  "Change in Enrollment schedule for Newly admitted 1st Semester / Year and Direct 2nd year students.":
    "नवीन प्रवेशित 1ले सेमिस्टर / वर्ष आणि डायरेक्ट दुसऱ्या वर्षाच्या विद्यार्थ्यांसाठी नामांकन वेळापत्रकात बदल.",
  "Summer 2022 Exam photocopy & Reassessment schedule for State Government Approved Short Term (Non-AICTE) courses.":
    "उन्हाळा 2022 चा परीक्षा फोटोकॉपी व पुनर्मूल्यांकन वेळापत्रक राज्य सरकारी मान्यता प्राप्त लघु कालीन (गैर-AICTE) कोर्सेससाठी.",
  "Revised Academic Calendar for Academic Year 2022-23.":
    "A.Y. 2022-23 साठी सुधारित शैक्षणिक कॅलेंडर.",
  "Summer 2022 Exam result declaration for Government Approved Short Term (Non-AICTE) Courses.":
    "सरकारी मान्यता प्राप्त लघु कालीन (गैर-AICTE) कोर्सेस साठी उन्हाळा 2022 परीक्षा निकाल जाहीर.",
  "Conference-Seminar “BOILER INDIA 2022” या कार्यक्रमामध्ये सहभागी होणेबाबत.":
    "परिषदा-संपदा “BOILER INDIA 2022” या कार्यक्रमामध्ये सहभागी होण्याबाबत.",
  "Circular for Academic Year 2022-23 OTO student Registration.":
    "A.Y. 2022-23 च्या शैक्षणिक वर्षासाठी OTO विद्यार्थी नोंदणी साठी परिपत्रक.",
  "Circular for Summer 2022 RE-Examination for Final Semester / Year & their backlog subject.":
    "अंतिम सेमिस्टर / वर्ष व त्यांच्या बॅकलॉग विषयांसाठी उन्हाळा 2022 पुनःपरीक्षा साठी परिपत्रक.",

  "Summer 2022 Exam result declaration for AICTE and Pharmacy Diploma courses.":
    "सरकारी मान्यता प्राप्त लघु कालीन (गैर-AICTE)  कोर्सेस साठी उन्हाळा 2022 परीक्षा निकाल जाहीर.",
  "Summer 2022 Exam photocopy & Reassessment schedule.":
    "उन्हाळा 2022 चा परीक्षा फोटोकॉपी व पुनर्मूल्यांकन वेळापत्रक.",
  "Conduction of Summer 2022 Examination for State Government Approved short term (Non-AICTE) courses.":
    "राज्य सरकारी मान्यता प्राप्त लघु कालीन (गैर-AICTE) कोर्सेस साठी उन्हाळा 2022 च्या परीक्षा आयोजर्गित.",

  "Special provision for filling Summer 2022 Examination form.":
    "उन्हाळा 2022 परीक्षा फॉर्म भरण्यासाठी विशेष तरतूद.",

  "Special provision for filling Summer 2023 Examination form.(English)":
    "उन्हाळा 2023 परीक्षा फॉर्म भरण्यासाठी विशेष तरतूद.",

  "Details of second class test for the subjects having online examination.":
    "ऑनलाइन परीक्षा असलेल्या विषयांसाठी दुसऱ्या वर्गाच्या चाचण्या संबंधित तपशील.",
  "Second test and end semester examination for subjects with online examination.":
    "ऑनलाइन परीक्षा असलेल्या विषयांचा दुसरा चाचणी व अंत्य सेमिस्टर परीक्षा.",
  "A National Level online Project, Paper and poster Competition for polytechnic Students on 06th and 07th May, 2022 organized by Anantrao pawar college of Engineering and Research, Parvati, Pune.":
    "पॉलिटेक्निक विद्यार्थ्यांसाठी राष्ट्रीय स्तरावरील ऑनलाइन प्रकल्प, पेपर आणि पोस्टर स्पर्धा 06 व 07 मे, 2022 आयोजित केलेले आनंदराव पवार अभियांत्रिकी व संशोधन महाविद्यालय, पार्वती, पुणे.",
  "A National Level Technical Event 'PHOENIX 2K22' on 12th April, 2022 organized by Godavari college of Engineering and Polytechnic, Jalgaon.":
    "डिप्लोमा विद्यार्थ्यांसाठी राष्ट्रीय स्तरावरील तांत्रिक कार्यक्रम 'PHOENIX 2K22' 12 एप्रिल 2022 आयोजित गोडावरी महाविद्यालय व पॉलिटेक्निक, जळगाव.",
  "Additional Information about Summer 2022 Exam.":
    "उन्हाळा 2022 परीक्षेसाठी अतिरिक्त माहिती.",

  "Technical Paper competition": "तांत्रिक पेपर स्पर्धा",
  "Technical project competition for final year students of polytechnics (Pune Region) on 18th May, 2022 organised by Government Polytechnic, Pune.":
    "पॉलिटेक्निकच्या फायनल वर्षाच्या विद्यार्थ्यांसाठी तांत्रिक प्रकल्प स्पर्धा (पुणे क्षेत्र) 18 मे 2022 आयोजित राजशासन पॉलिटेक्निक, पुणे द्वारे.",
  "Redressal of Grievance of Students": "विद्यार्थ्यांच्या तक्रारींचा निवारण",
  "State Level Technical Quiz and Poster Presentation Competition":
    "राज्यस्तरीय तांत्रिक क्विझ व पोस्टर सादरीकरण स्पर्धा",
  "Government Approved Short Term courses (Non-AICTE) Winter 2021 Exam result declaration.":
    "सरकारी मान्यता प्राप्त लघु कालीन कोर्सेस (गैर-AICTE) हिवाळा 2021 परीक्षा निकाल जाहीर.",
  "State Level Quiz Competition for Diploma in pharmacy Students on 20th April, 2022 organized by Government College of Pharmacy, Karad.":
    "डिप्लोमा फार्मसी विद्यार्थ्यांसाठी राज्यस्तरीय क्विझ स्पर्धा 20 एप्रिल 2022 आयोजित राज्य कॉलेज ऑफ फार्मसी, कराड.",
  "National Level Technical Event for Diploma Students 'Online Tech Tantra 2022' on 14 May, 2022 organized by Padm. Dr. V. B. Kolte College of Engineering , Malkapur, Buldana.":
    "डिप्लोमा विद्यार्थ्यांसाठी राष्ट्रीय स्तरावरील तांत्रिक कार्यक्रम 'ऑनलाइन टेक तंत्रा 2022' 14 मे 2022 आयोजित पद्म. डॉ. व्ही. बी. कोल्टे अभियांत्रिकी महाविद्यालय, मलाकपूर, बुलडाणा.",
  "State Level Quiz Competition on 30th April, 2022 organized by Vidyavardhini's Bhausaheb Vartak Polytechnic, Vasai, Palghar.":
    "राज्यस्तरीय क्विझ स्पर्धा 30 एप्रिल 2022 आयोजित विद्यामंदिरांचे भाऊसाहेब वर्तक Pॉलिटेक्निक, वसई, पालघर.",
  "State Level Technical Paper presentation competition organized by St. John Polytechnic, Palghar on 23rd April, 2022":
    "राज्यस्तरीय तांत्रिक पेपर सादरीकरण स्पर्धा, स्ट. जॉन पॉलिटेक्निक, पालघर द्वारे 23 एप्रिल 2022 आयोजित.",
  "Online Regional Level Technical paper presentation competition from 28th to 29th April, 2022 organized by Government Polytechnic, Muritzapur.":
    "ऑनलाइन प्रादेशिक स्तरावरील तांत्रिक पेपर सादरीकरण स्पर्धा 28 ते 29 एप्रिल 2022 राजशासन पॉलिटेक्निक, मुठीझापूर द्वारे आयोजित.",
  "State Level Technical E Quiz Competition on 29th April, 2022 organized by Government Polytechnic, Nanded.":
    "राज्यस्तरीय तांत्रिक ई क्विझ स्पर्धा 29 एप्रिल 2022 राजशासन पॉलिटेक्निक, नांदेड़ द्वारे आयोजित.",
  "Online State Level Technical Paper Presentation Competition on 07th May, 2022 organised by Puranmal Lahoti Government Poytechnic, Latur.":
    "राज्यस्तरीय ऑनलाइन तांत्रिक पेपर सादरीकरण स्पर्धा 07 मे 2022 पुअर्नमल लाहोटी सरकारी पॉलिटेक्निक, लातूर द्वारे आयोजित.",
  "Conduction of Summer 2022 Examination": "उन्हाळा 2022च्या परीक्षा आयोजीत.",
  "Redressal of Grievance of Students बाबत कार्यवाही करणे":
    "विद्यार्थ्यांच्या तक्रारींचा निवारण बाबत कार्यवाही करणे",
  "State Level Technical Event (PANCHRANG-2K22) at G. S. Mandal's Marathwada Institute of Technology (Polytechnic), Aurangabad on 19th April, 2022.":
    "राज्यस्तरीय तांत्रिक कार्यक्रम (पंचरंग-2K22) जी. एस. मंडळाच्या मराठवाडा अभियांत्रिकी संस्थेत (पॉलिटेक्निक), औरंगाबाद 19 एप्रिल 2022.",
  "ELECTROTECH 2022 State Level Technical Paper Presentation on 7th May 2022":
    "इलेक्ट्रोटेक 2022 राज्यस्तरीय तांत्रिक पेपर सादरीकरण 7 मे 2022.",
  "State Level Technical Competitions 'TECHNOSTELLAR 2022' Organised by MAEER MIT Polytechnic, Pune from 20th to 21st April, 2022.":
    "राज्यस्तरीय तांत्रिक स्पर्धा 'टेक्नोस्टेलर 2022' MAEER MIT पॉलिटेक्निक, पुणे द्वारे 20 ते 21 एप्रिल 2022 आयोजित.",
  "Exam day and Date wise Final Time Table for MCQ based on-line Winter 2021 theory exam":
    "MCQ आधारित ऑनलाइन हिवाळा 2021 सिद्धांत परीक्षेसाठी परीक्षा दिनांक व वेळापत्रक.",
  "Filling Chief Coordinator/ Coordinator details in Online Examination Module for Online Summer 2020 Examination":
    "ऑनलाइन समर 2020 परीक्षा साठी मुख्य समन्वयक/समन्वयकांचे तपशील ऑनलाइन परीक्षा मॉड्यूलमध्ये भरणे.",
  "Corrigendum regarding Online Term End Examination of SUMMER 2020":
    "उन्हाळा 2020 च्या ऑनलाइन टर्म एंड परीक्षेसाठी исправण.",
  "Time table to conduct Online Class Test II and Online Term End Examination of Summer 2020":
    "ऑनलाइन वर्ग चाचणी II व ऑनलाइन टर्म एंड परीक्षा आयोजित करण्यासाठी वेळापत्रक.",
  "Corrigendum Regarding Online Class Test 2":
    "ऑनलाइन वर्ग चाचणी 2 बाबत शुद्धीपत्र",
  "Schedule to Conduct Online Class Test II and Online Term End Examination Winter 2019":
    "ऑनलाइन वर्ग चाचणी II व ऑनलाइन टर्म एंड परीक्षा हिवाळा 2019 ची आयोजितवाले.",
  "ONLINE EXAMINATION SCHEDULE SUMMER 2017 for Subject Code 21030 (Management) & 21049 (AJP).":
    "ऑनलाइन परीक्षा वेळापत्रक समर 2017 विषय कोड 21030 (व्यवस्थापन) व 21049 (AJP).",

  "One Week Faculty Development Programme on 'Innovation, Incubation & Start-Up Culture' from 13th to 17th March 2023 at V.P.M’s Polytechnic, Thane.":
    "एक आठवड्याचा शिक्षकेत्तर विकास कार्यक्रम 'नवोपक्रम, इन्क्यूबेशन आणि स्टार्ट-अप संस्कृती' 13 ते 17 मार्च 2023 व.पी. एम च्या पॉलिटेक्निक, ठाणे.",
  "Change in Course Code of Diploma in Travel and Tourism (TR)":
    "डिप्लोमा इन ट्रॅव्हल आणि टूरिझम (TR) चा कोर्स कोडमध्ये बदल",
  "Deputation of faculties Names for an Industrial Training program at M/s. Central Institute of Plastic Engineering & Technology, Auragabad for the faculties from Mechanical Engineering Dept. from 14-03-2023 to 18-03-2023.":
    "विभागाच्या प्राध्यापकांच्या औद्योगिक प्रशिक्षण कार्यक्रमासाठी M/s. केंद्रीय प्लास्टिक अभियांत्रिकी आणि तंत्रज्ञान संस्था, औरंगाबाद येथे १४-०३-२०२३ ते १८-०३-२०२३ मध्ये सहभागी होण्यासाठी प्राध्यापकांच्या नावा.",
  "Deputation of faculties Names for an Industrial Training program at M/s. Nashik Engineering Cluster for the faculties from CO/IT Engineering Dept. from 13-03-2023 to 17-03-2023":
    "CO/IT अभियांत्रिकी विभागाच्या प्राध्यापकांसाठी औद्योगिक प्रशिक्षण कार्यक्रमासाठी M/s. नाशिक अभियांत्रिकी क्लस्टर येथे १३-०३-२०२३ ते १७-०३-२०२३.",
  "Deputation of Faculties Names for an Industrial Training program at M/s. Bosch Ltd., Pune for the faculties from ME,AE & PR Engineering Dept. From 13-03-2023 to 17-03-2023.":
    "ME, AE आणि PR अभियांत्रिकी विभागाच्या प्राध्यापकांच्या औद्योगिक प्रशिक्षण कार्यक्रमासाठी M/s. बॉश लिमिटेड, पुणे येथे १३-०३-२०२३ ते १७-०३-२०२३.",

  "MSBTE State Level Project Competition (for Nagpur Region) organized on 03rd April, 2023 at NIT Polytechnic, Nagpur. (1,644)(English)":
    "MSBTE राज्यस्तरीय प्रकल्प स्पर्धा (नागपूर विभागासाठी) ०३ एप्रिल, २०२३ रोजी NIT पॉलिटेक्निक, नागपूर येथे आयोजित करण्यात आली. (१,६४४)",
  "MSBTE State level project competition 2022-23  (for Pune Region ) on 29/03/2023 at S.S.D.G.C.T'S Sanjay Ghodawat Polytechnic,Atigre,Kolhapur (1644)(English)":
    "MSBTE राज्यस्तरीय प्रकल्प स्पर्धा २०२२-२३ (पुणे विभागासाठी) २९/०३/२०२३ रोजी S.S.D.G.C.T च्या संजय घोडावत पॉलिटेक्निक, आतिग्रे, कोल्हापूर येथे आयोजित करण्यात आली. (१६४४)",
  "MSBTE State Level Project Competition (for Mumbai Region) organized on 31st March, 2023 at Bharati Vidyapeeth Institute of Technology , Navi Mumbai.(English)":
    "MSBTE राज्यस्तरीय प्रकल्प स्पर्धा (मुंबई विभागासाठी) ३१ मार्च, २०२३ रोजी भारती विद्यापीठ तंत्रज्ञान संस्थेमध्ये, नवी मुंबई येथे आयोजित करण्यात आली. (इंग्रजी)",
  "MSBTE State Level Project competition 2022-23 (For Aurangabad Region) on 25/03/2023 at Guru Gobind Singh Polytechnic , Nashik (0369)":
    "MSBTE राज्यस्तरीय प्रकल्प स्पर्धा २०२२-२३ (औरंगाबाद विभागासाठी) २५/०३/२०२३ रोजी गुरु गोबिंद सिंह पॉलिटेक्निक, नाशिक येथे. (०३६९)",
  "Regarding Sending entries for MSBTE Project competition 2022-23(English)":
    "MSBTE प्रकल्प स्पर्धा २०२२-२३ साठी प्रवेश पाठवण्याबाबत. (इंग्रजी)",

  "Conduct of State Level MSBTE Technical Paper Presentation Competitions (online) 2022-23 for Diploma Students.(English)":
    "MSBTE राज्यस्तरीय तांत्रिक कागद सादरीकरण स्पर्धा (ऑनलाईन) २०२२-२३ डिप्लोमा विद्यार्थ्यांसाठी. (इंग्रजी)",
  "MSBTE Technical Paper Presentation Competition 2022-23 (online) for RBTE, Pune Region on 19/04/2023 at Shanti Education Society's A.G. Patil Polytechnic Institute ,Solapur (0995) in Electrical Engineering branch.(English)":
    "MSBTE तांत्रिक कागद सादरीकरण स्पर्धा २०२२-२३ (ऑनलाईन) RBTE पुणे विभागासाठी १९/०४/२०२३ रोजी शांती एजुकेशन सोसायटीच्या ए.जी. पाटील पॉलिटेक्निक इन्स्टिट्यूट, सोलापूर येथे (०९९५) इलेक्ट्रिकल अभियांत्रिकी शाखेत. (इंग्रजी)",
  "MSBTE Technical Paper Presentation Competition 2022-23 (online) for RBTE, Mumbai Region on 17/04/2023 at Yashwantrao Bhonsale Polytechnic, Sawantwadi, Sindhudurg (1742) in Civil Engineering branch.(English)":
    "MSBTE तांत्रिक कागद सादरीकरण स्पर्धा २०२२-२३ (ऑनलाईन) RBTE मुंबई विभागासाठी १७/०४/२०२३ रोजी यशवंतराव भोसले पॉलिटेक्निक, सावंतवाडी, सिंधुदुर्ग येथे (१७४२) सिविल अभियांत्रिकी शाखेत. (इंग्रजी)",
  "MSBTE One week faculty Development Training Program on Modern Surveying Using Drone and Photogrammetry Techniques organised at Kasegaon Education Society’s RAJARAMBAPU INSTITUTE OF TECHNOLOGY, Sangli from 17th to 21st April, 2023.(English)":
    "MSBTE एक आठवड्याचा प्राध्यापक विकास प्रशिक्षण कार्यक्रम आधुनिक सर्वेईंग ड्रोन आणि फोटोग्रामेट्री तंत्राने आयोजित केल्यामुळे कासगावन एज्युकेशन सोसायटीच्या राजारामबापू इन्स्टिट्यूट ऑफ टेक्नॉलॉजी, सांगली येथे १७ ते २१ एप्रिल, २०२३. (इंग्रजी)",
  "MSBTE Technical Paper Presentation Competition 2022-23 (online) for RBTE, Pune Region on 18/04/2023 at Kasegaon Education Society's Rajarambapu Institute of Technology, Walwa, Sangli (1740) in Mechanical Engineering branch(English)":
    "MSBTE तांत्रिक कागद सादरीकरण स्पर्धा २०२२-२३ (ऑनलाईन) RBTE पुणे विभागासाठी १८/०४/२०२३ रोजी कासगावन एज्युकेशन सोसायटीच्या राजारामबापू इन्स्टिट्यूट ऑफ टेक्नॉलॉजी, वालवा, सांगली येथे (१७४०) मेकॅनिकल अभियांत्रिकी शाखेत. (इंग्रजी)",
  "MSBTE Technical Paper Presentation Competition 2022-23 (online) for RBTE, Nagpur Region on 20/04/2023 at Government polytechnic, Yavatmal (0022) in Computer Engineering branch.(English)":
    "MSBTE तांत्रिक कागद सादरीकरण स्पर्धा २०२२-२३ (ऑनलाईन) RBTE नागपूर विभागासाठी २०/०४/२०२३ रोजी सरकारी पॉलिटेक्निक, यवतमाळ येथे (००२२) संगणक अभियांत्रिकी शाखेत. (इंग्रजी)",
  "MSBTE Technical Paper Presentation Competition 2022-23 (online) for RBTE, Pune Region on 15/04/2023 at Government College of Pharmacy, Karad, Satara (0151) in Pharmacy Engineering branch(English)":
    "MSBTE तांत्रिक कागद सादरीकरण स्पर्धा २०२२-२३ (ऑनलाईन) RBTE पुणे विभागासाठी १५/०४/२०२३ रोजी सरकारी फार्मसी कॉलेज, कराड, सातारा येथे (०१५१) फार्मसी अभियांत्रिकी शाखेत. (इंग्रजी)",

  "Deputation of Faculties Names for an Industrial Training program at BOSCH - DYPSOEA Joint Certification Center ( JCC ) for the faculties from 3-4-2023 to 7-4-2023(English)":
    "प्राध्यापकांच्या औद्योगिक प्रशिक्षण कार्यक्रमासाठी BOSCH - DYPSOEA संयुक्त प्रमाणपत्र केंद्र (JCC) साठी ३-४-२०२३ ते ७-४-२०२३ (इंग्रजी)",
  "MSBTE One week faculty Development Training Program on “Cutting Edge Technology for Electrical Engineering” from 24th April 2023 to 28th April 2023 organised by Sharad Institute of Technology, Ichalkaranji, Kolhapur.(English)":
    "MSBTE एक आठवड्याचा प्राध्यापक विकास प्रशिक्षण कार्यक्रम ‘इलेक्ट्रिकल अभियांत्रिकीसाठी कटींग एज तंत्रज्ञान’ २४ एप्रिल २०२३ ते २८ एप्रिल २०२३ रोजी शार्द इन्स्टिट्यूट ऑफ टेक्नॉलॉजी, इचलकरंजी, कोल्हापूर द्वारा आयोजित करण्यात आला आहे. (इंग्रजी)",
  "MSBTE Technical Paper Presentation Competition 2022-23 (online) for RBTE, Aurangabad Region on 15/04/2023 at Government Polytechnic, Jalgaon (0018) in Pharmacy Engineering branch.(English)":
    "MSBTE तांत्रिक कागद सादरीकरण स्पर्धा २०२२-२३ (ऑनलाईन) RBTE Aurangabad विभागासाठी १५/०४/२०२३ रोजी सरकारी पॉलिटेक्निक, जलगाव येथे (००१८) फार्मसी अभियांत्रिकी शाखेत. (इंग्रजी)",
  "MSBTE Technical Paper Presentation Competition 2022-23 (online) for RBTE, Nagpur Region on 15/04/2023 at Sudhakarrao Naik Insitute of Pharmacy, Pusad, Yavatmal 0285) in Pharmacy Engineering branch.(English)":
    "MSBTE तांत्रिक कागद सादरीकरण स्पर्धा २०२२-२३ (ऑनलाईन) RBTE नागपूर विभागासाठी १५/०४/२०२३ रोजी सुधाकरराव नाईक इन्स्टिट्यूट ऑफ फार्मसी, पूसद, यवतमाळ येथे (०२८५) फार्मसी अभियांत्रिकी शाखेत. (इंग्रजी)",
  "MSBTE Technical Paper Presentation Competition 2022-23 (online) for RBTE, Mumbai Region on 18/04/2023 at H. J. Theem Trust’s College of Engineering, Boisar, Palghar (1635) in Mechanical Engineering branch.(English)":
    "MSBTE तांत्रिक कागद सादरीकरण स्पर्धा २०२२-२३ (ऑनलाईन) RBTE मुंबई विभागासाठी १८/०४/२०२३ रोजी एच. जे. थिम ट्रस्टच्या इंजिनिअरिंग कॉलेज, बोईसर, पालघर येथे (१६३५) मेकॅनिकल अभियांत्रिकी शाखेत. (इंग्रजी)",
  "MSBTE Technical Paper Presentation Competition 2022-23 (online) for RBTE, Aurangabad Region on 19/04/2023 at Guru Gobind Singh Polytechnic, Nashik (0369) in Electrical Engineering branch.(English)":
    "MSBTE तांत्रिक कागद सादरीकरण स्पर्धा २०२२-२३ (ऑनलाईन) RBTE Aurangabad विभागासाठी १९/०४/२०२३ रोजी गुरु गोबिंद सिंह पॉलिटेक्निक, नाशिक येथे (०३६९) इलेक्ट्रिकल अभियांत्रिकी शाखेत. (इंग्रजी)",
  "MSBTE Technical Paper Presentation Competition 2022-23 (online) for RBTE, Chatrapati Sambhaji Nagar Region on 17/04/2023 at Gramin Polytechnic, Nanded (1107) in Civil Engineering branch.(English)":
    "MSBTE तांत्रिक कागद सादरीकरण स्पर्धा २०२२-२३ (ऑनलाईन) RBTE छत्रपती संभाजी नगर विभागासाठी १७/०४/२०२३ रोजी ग्रामीण पॉलिटेक्निक, नांदेड येथे (१११०७) सिविल अभियांत्रिकी शाखेत. (इंग्रजी)",
  "MSBTE State Level (Online)Technical Paper Presentation Competition (Computer Engineering Group) organised at Government Polytechnic, Thane on 20th April, 2023.(English)":
    "MSBTE राज्यस्तरीय (ऑनलाइन) तांत्रिक कागद सादरीकरण स्पर्धा (संगणक अभियांत्रिकी समूह) आयोजित करण्यात आला. सरकारी पॉलिटेक्निक, ठाणे येथे २० एप्रिल, २०२३ (इंग्रजी)",
  "MSBTE One Week Faculty Development Training Program on 'VIRTUAL & AUGMENTED REALITY' from 24th to 28th April, 2023 organised at Vidyalankar Polytechnic, wadala, Mumbai.(English)":
    "MSBTE एक आठवड्याचा प्राध्यापक विकास प्रशिक्षण कार्यक्रम ‘वर्चुअल व ऑग्मेंटेड रिऐलिटी’ २४ ते २८ एप्रिल, २०२३ रोजी विदयालंकर पॉलिटेक्निक, वडाळा, मुंबईमध्ये आयोजित करण्यात आला. (इंग्रजी)",
  "MSBTE State Level (Online)Technical Paper Presentation Competition (Civil Engineering Group) organised at Government Polytechnic, Gadchiroli on 17th April, 2023.(English)":
    "MSBTE राज्यस्तरीय (ऑनलाइन) तांत्रिक कागद सादरीकरण स्पर्धा (सिविल अभियांत्रिकी समूह) आयोजित करण्यात आला. सरकारी पॉलिटेक्निक, गडचिरोली येथे १७ एप्रिल, २०२३ (इंग्रजी)",
  "MSBTE State Level (Online)Technical Paper Presentation Competition (Mechanical Engineering Group) organised at G. H. Raisoni Institute of engineering and Technology, Nagpur on 18th April, 2023.(English)":
    "MSBTE राज्यस्तरीय (ऑनलाइन) तांत्रिक कागद सादरीकरण स्पर्धा (मेकॅनिकल अभियांत्रिकी समूह) आयोजित करण्यात आला. G. H. Raisoni Institute of Engineering and Technology, नागपूर येथे १८ एप्रिल, २०२३ (इंग्रजी)",
  "MSBTE State Level (Online)Technical Paper Presentation Competition (Civil Engineering Group) organised at Walchand College of Engineering, Sangli on 17th April, 2023.(English)":
    "MSBTE राज्यस्तरीय (ऑनलाइन) तांत्रिक कागद सादरीकरण स्पर्धा (सिविल अभियांत्रिकी समूह) आयोजित करण्यात आला. वालचंद अभियांत्रिकी महाविद्यालय, सांगली येथे १७ एप्रिल, २०२३ (इंग्रजी)",

  "MSBTE State Level (Online)Technical Paper Presentation Competition (Electrical Engineering Group) organised at Government Polytechnic, Mumbai on 19th April, 2023.(English)":
    "MSBTE राज्यस्तरीय (ऑनलाइन) तांत्रिक कागद सादरीकरण स्पर्धा (इलेक्ट्रिकल अभियांत्रिकी समूह) आयोजित करण्यात आला. सरकारी पॉलिटेक्निक, मुंबई येथे १९ एप्रिल, २०२३. (इंग्रजी)",

  "Deputation of faculties Names for an Industrial Training program at M/s. Nashik Engineering Cluster for the faculties from CO/IT Engineering Dept. from 10-04-2023 to 14-04-2023.(English)":
    "प्राध्यापकांच्या औद्योगिक प्रशिक्षण कार्यक्रमासाठी M/s. नाशिक अभियांत्रिकी क्लस्टर CO/IT अभियांत्रिकी विभागाच्या प्राध्यापकांसाठी १०-०४-२०२३ ते १४-०४-२०२३. (इंग्रजी)",

  "MSBTE Sponsored State Level Technical Paper Presentation Competition (ONLINE) For Pharmacy Student Organised At Bharati Vidyapeeth Institute Of Pharmacy, Navi Mumbai On 15th April, 2023.(English)":
    "MSBTE प्रायोजित राज्यस्तरीय तांत्रिक कागद सादरीकरण स्पर्धा (ऑनलाइन) फार्मसी विद्यार्थ्यांसाठी भारती विद्यापीठ फार्मसी संस्थेत, नवी मुंबई येथे १५ एप्रिल, २०२३. (इंग्रजी)",
  "National Level offline Project , Start-Up, Paper & Poster Competition For Polytechnic Students organize at Anantrao Pawar College of Engineering & Research , Parvati, Pune.(English)":
    "राष्ट्रीय स्तरावर ऑफलाईन प्रकल्प, स्टार्ट-अप, कागद आणि पोस्टर स्पर्धा पॉलिटेक्निक विद्यार्थ्यांसाठी अनंतरा पवार कॉलेज ऑफ इंजिनिअरिंग आणि संशोधन, पर्वती, पुणे येथे आयोजित केली. (इंग्रजी)",
  "MSBTE State Level (Online)Technical Paper Presentation Competition (Computer Engineering Group) organised at Marathwada Institute of Technology Polytechnic, Chatrapati Sambhajinagar on 20th April, 2023.(English)":
    "MSBTE राज्यस्तरीय (ऑनलाइन) तांत्रिक कागद सादरीकरण स्पर्धा (संगणक अभियांत्रिकी समूह) आयोजित करण्यात आली. मराठवाडा तंत्रज्ञान पॉलिटेक्निक, छत्रपती संभाजीनगर येथे २० एप्रिल, २०२३. (इंग्रजी)",
  "MSBTE Technical Paper Presentation Competition 2022-23 (online) for RBTE, Nagpur Region on 19/04/2023 at NIT Polytechnic, Nagpur (1199) in Electrical Engineering branch.(English)":
    "MSBTE तांत्रिक लेख प्रेझेंटेशन स्पर्धा 2022-23 (ऑनलाइन) RBTE, नागपूर क्षेत्रासाठी 19/04/2023 रोजी NIT पॉलिटेक्निक, नागपूर (1199) मध्ये वीज अभियांत्रिकी शाखेत. (English)",
  "MSBTE Technical Paper Presentation Competition 2022-23 (online) for RBTE, Pune Region on 20/04/2023 at S.T.E. Society's Sou.Venutai Chavan Polytechnic, Pune (0040) in Computer Engineering branch.(English)":
    "MSBTE तांत्रिक लेख प्रेझेंटेशन स्पर्धा 2022-23 (ऑनलाइन) RBTE, पुणे क्षेत्रासाठी 20/04/2023 रोजी S.T.E. सोसायटीच्या सौ. वसंता चव्हाण पॉलिटेक्निक, पुणे (0040) मध्ये संगणक अभियांत्रिकी शाखेत. (English)",
  "ISTE approved self Financed Three Days Short Term Training Program (STTP) on Advancement in Civil Engineering Construction from 11th to 13th April, 2023 at DKTE Society's Yashwantrao Chavan Polytechnic, Kolhapur.(English)":
    "ISTE स्वयं प्रायोजित एक अध्यापक विकास कार्यक्रम परिणाम आधारित शिक्षण आणि NBA दृष्टिकोनावर २२ ते २६ एप्रिल २०२४ रोजी तुळसिरामजी गायकवाड-पाटिल अभियांत्रिकी आणि तंत्रज्ञान महाविद्यालय, नागपूर येथे आयोजित केला जात आहे. (English)",

  "9th State Level Technical Quiz and Poster Presentation on 13th April,2023 organised at Samarth Polytechnic, Belhe.(English)":
    "सामर्थ पॉलिटेक्निक, बेल्हे येथे १३ एप्रिल, २०२३ रोजी ९ वे राज्यस्तरीय तांत्रिक क्विझ आणि पोस्टर सादरीकरण आयोजित करण्यात आले. (इंग्रजी)",
  "Deputation of Faculties Names for an Industrial Training program at M/s. FESTO India Pvt. Ltd.,Mumbai for the faculties from Mechanical & Production Engineering Dept. from 17-04-2023 to 21-04-2023.(English)":
    "यांत्रिकी आणि उत्पादन अभियांत्रिकी विभागामध्ये इंदस्ट्रियल ट्रेनिंग कार्यक्रमासाठी अध्यापकांची यादी देय. ( १७-०४-२०२३ ते २१-०४-२०२३ ) मी/स. FESTO इंडिया प्रा. लि., मुंबई. (इंग्रजी)",
  "Deputation of faculties Names for an Industrial Training program at M/s. Central Institute of Plastic Engineering & Technology, Auragabad for the faculties from Mechanical Engineering Dept. from 24-04-2023 to 28-04-2023.(English)":
    "यांत्रिकी अभियांत्रिकी विभागामध्ये इंदस्ट्रियल ट्रेनिंग कार्यक्रमासाठी अध्यापकांची यादी देय. (२४-०४-२०२३ ते २८-०४-२०२३) मी/स. प्लास्टिक्स अभियांत्रिकी आणि तंत्रज्ञान केंद्रीय संस्था, औरंगाबाद. (इंग्रजी)",

  "Summer 2023 Exam Result Declaration Circular.(Marathi)":
    "गर्मी २०२३ परीक्षा निकाल जाहीर करण्याचा परिपत्रक. (मराठी)",
  "Academic Calendar for Academic Year 2023-24.":
    "अकादमिक वर्ष २०२३-२४ साठी शैक्षणिक कॅलेंडर.",
  "Summer 2023 Exam Schedule for Photocopy/ Verification and Reassessment.":
    "गर्मी २०२३ परीक्षांचे फोटोकॉपी / सत्यापन आणि पुनर्मूल्यांकनाचा वेळापत्रक.",
  "Summer 2023 Exam Result Declaration Circular.":
    "गर्मी २०२३ परीक्षांचा निकाल जाहीर करण्याचा परिपत्रक.",
  "Appeal to send information for MSBTE Newsletter- September 2023.":
    "MSBTE वृत्तपत्रासाठी माहिती पाठविण्याबाबत आपील - सप्टेंबर 2023.",
  "Akhil Bhartiya Shiksha Samagam (ABSS)-Joining the Live Webcast.":
    "अखिल भारतीय शिक्षाशेम (ABSS) - लाइव वेबकास्टमध्ये सामील होणे.",

  "National level Technical Paper Presentation (Inst. Code-1577) On 15 September 2023.( English)":
    "राष्ट्रीय स्तरावर तांत्रिक पेपर सादरीकरण (संस्थान कोड-1577) 15 सप्टेंबर 2023. (इंग्रजी)",

  "National Level Tri-series Quiz Event, TantraYaan-2023, Government Polytechnic, Nanded.(English)":
    "राष्ट्रीय स्तरावरील त्रिसिरीज क्विझ इवेंट, TantraYaan-2023, सरकारी पॉलिटेक्निक, नांदेड.( इंग्रजी)",
  "Three days online programme at D.K.T.E. Society's Yashwantrao Chavan Polytechnic Kolhapur on “Emerging New and Renewable Energy Technologies & their integration into the grid ”.(English)":
    "DKTE सोसायटीच्या यशवंतराव चव्हाण पॉलिटेक्निक कोल्हापूर येथे “उदयोन्मुख नवीन व नूतन उर्जा तंत्रज्ञान & त्यांची ग्रिडमध्ये एकत्रीकरण” यावर तीन दिवसांची ऑनलाइन कार्यक्रम.( इंग्रजी)",
  "A.Y. 2023-24 Enrollment schedule for Newly Admitted Pharmacy course students.(Marathi)":
    "A.Y. 2023-24 चा नवीन प्रवेशित फार्मसी कोर्स विद्यार्थ्यांसाठी प्रवेश वेळापत्रक.( मराठी)",
  "Industrial Training Program at M/s National Institute of Electronics & Information Technology (NIELIT), Aurangabad for the faculties of Computer dept. of Affiliated Polytechnic from 30.10.2023 to 03.11.2023":
    "माहिती तंत्रज्ञान विभागाच्या संलग्न पॉलिटेक्निकच्या अध्यापकांसाठी म/स नॅशनल इन्स्टिट्यूट ऑफ इलेक्ट्रॉनिक्स अँड इन्फर्मेशन टेक्नॉलॉजी (NIELIT), औरंगाबाद येथे औद्योगिक प्रशिक्षण कार्यक्रम 30.10.2023 ते 03.11.2023.",
  "Industrial Training Program at M/s Central Institute of Plastics Engineering and Technology , Aurangabad for the faculties of Mechanical dept. of Affiliated Polytechnic from 30.10.2023 to 03.11.2023.":
    "माहिती तंत्रज्ञान विभागाच्या संलग्न पॉलिटेक्निकच्या अध्यापकांसाठी म/स सेंट्रल इन्स्टिट्यूट ऑफ प्लास्टिक्स इंजिनीरिंग अँड टेक्नॉलॉजी, औरंगाबाद येथे औद्योगिक प्रशिक्षण कार्यक्रम 30.10.2023 ते 03.11.2023.",
  "Industrial Training Program M/s Festo India Pvt. Ltd. Mumbai for the faculties of Mechanical, Automobile and Production Dept.of affiliated Polytechnic.":
    "संलग्न पॉलिटेक्निकच्या यांत्रिकी, ऑटोमोबाईल आणि उत्पादन विभागांच्या अध्यापकांसाठी म/स फेस्टो इंडिया प्रायव्हेट लिमिटेड, मुंबई येथे औद्योगिक प्रशिक्षण कार्यक्रम.",
  "Industrial Training Program at M/s. BOSCH - DYPSOEA Joint Certification Centre (JCC), Pune for the faculties of Mechanical, Automobile, Production Dept. of Affiliated Polytechnic.":
    "संलग्न पॉलिटेक्निकच्या यांत्रिकी, ऑटोमोबाईल, उत्पादन विभागांच्या अध्यापकांसाठी म/स BOSCH - DYPSOEA जॉइंट सर्टिफिकेशन सेंटर (JCC), पुणे येथे औद्योगिक प्रशिक्षण कार्यक्रम.",
  "Industrial Training Program at M/s. L&T Skills Trainers Academy, Mumbai for the faculties of Computer, Information Technology, Electrical, Civil Dept.of Affiliated Polytechnic.":
    "कामकाज आणि माहिती तंत्रज्ञान, इलेक्ट्रिकल, सिव्हिल विभागाच्या संलग्न पॉलिटेक्निकच्या अध्यापकांसाठी म/स L&T स्किल्स ट्रेनर्स अकॅडमी, मुंबई येथे औद्योगिक प्रशिक्षण कार्यक्रम.",
  "Industrial Training Program at M/s KEC International Training Center, Nagpur l Institute of Plastics Engineering and Technology , Aurangabad for the faculties of Mechanical, Eletrical, Electronics, Instrumentation and Mechatronics dept. of Affiliated Polytechnic from 30.10.2023 to 03.11.2023 .":
    "संलग्न पॉलिटेक्निकच्या यांत्रिकी, इलेक्ट्रिकल, इलेक्ट्रॉनिक्स, इंस्ट्रुमेंटेशन आणि मेकॅट्रॉनिक्स विभागांच्या अध्यापकांसाठी म/स KEC इंटरनॅशनल ट्रेनिंग सेंटर, नागपूर व प्लास्टिक्स इंजिनीरिंग अँड टेक्नॉलॉजी, औरंगाबाद येथे औद्योगिक प्रशिक्षण कार्यक्रम 30.10.2023 ते 03.11.2023.",
  "Industrial Training Program at Nashik Engineering Cluster, Nashik for the faculties of Electrical, Electronics dept. of Affiliated Polytechnic from 30.10.2023 to 03.11.2023.":
    "संलग्न पॉलिटेक्निकच्या इलेक्ट्रिकल, इलेक्ट्रॉनिक्स विभागांच्या अध्यापकांसाठी नाशिक अभियांत्रिकी क्लस्टर, नाशिक येथे औद्योगिक प्रशिक्षण कार्यक्रम 30.10.2023 ते 03.11.2023.",
  "Industrial Training Program at M/s. L&T Skills Trainers Academy, Mumbai for the faculties of Computer, Information Technology, Electrical, Civil Dept.of Affiliated Polytechnic .":
    "कामकाज आणि माहिती तंत्रज्ञान, इलेक्ट्रिकल, सिव्हिल विभागाच्या संलग्न पॉलिटेक्निकच्या अध्यापकांसाठी म/स L&T स्किल्स ट्रेनर्स अकॅडमी, मुंबई येथे औद्योगिक प्रशिक्षण कार्यक्रम.",
  "Special provision for filling Exam form for Winter 2023 Examination.":
    "संपूर्ण परीक्षेसाठी विशेष परीक्षा फॉर्म भरण्याची तरतूद.",
  "Web Circular-CIAAN Norms -2023 च्या नमुन्याबाबत.":
    "वेब सर्कुलर-CIAAN मानक -2023 च्या नमुन्याबाबत.",
  "MSBTE Newsletter October-2023 Issue प्रसिध्द करण्याबाबत.":
    "MSBTE वृत्तपत्र ऑक्टोबर-2023 आवृत्तीत प्रकाशनाबाबत.",
  "Fee Fixation Committee G.R. 13 SEPT 2023":
    "फी निश्चिती समिती जी.आर. 13 सप्टेंबर 2023",
  "Fee Fixation Committee G.R. 08 JUNE 2023":
    "फी निश्चिती समिती जी.आर. 08 जून 2023",

  "Reminder letter regarding ABC Registration activity.":
    "ABC नोंदणी क्रियाकलापाबाबत लक्षात ठेवणारे पत्र.",
  "Appeal to send information for MSBTE Newsletter January-2024 issue.":
    "MSBTE वृत्तपत्र जानेवारी-2024 आवृत्तीसाठी माहिती पाठवण्याचे अपील.",
  "Approval Process Handbook 2024-25 to 2026-27":
    "अधिसूचना प्रक्रियेचा मार्गदर्शक 2024-25 ते 2026-27",
  "MSCIT equivalent GR dated 8th January 2018":
    "MSCIT समकक्ष जीआर दिनांक 8 जानेवारी 2018",
  "MSCIT equivalence GR dated 4th February 2013":
    "MSCIT समकक्ष जीआर दिनांक 4 फेब्रुवारी 2013",
  "Gazette Governing Council dated 6th April 2023":
    "गझेट गव्हर्निंग कौन्सिल दिनांक 6 एप्रिल 2023",
  "Governing Board GR dated 4th December  2023":
    "गव्हर्निंग बोर्ड जीआर दिनांक 4 डिसेंबर 2023",
  "Circular Regarding Guidelines for Implementations of Course Social & Life Skills (Course Code : 312003)":
    "कोर्स सामाजिक आणि जीवन कौशल्यांच्या कार्यान्वयनासाठी मार्गदर्शक सूचना संबंधित सर्कुलर (कोर्स कोड: 312003)",
  "MSBTE sponsored State Level Technical Paper Presentation Competition for Electronics & Telecomm Group. (English)":
    "MSBTE प्रायोजित राज्यस्तरीय तांत्रिक पत्र सादरीकरण स्पर्धा इलेक्ट्रॉनिक्स आणि टेलिकम्युनिकेशन गटासाठी.( इंग्रजी)",
  "Faculty Development Training Program at All India Shri Shivaji Memorial Society's Polytechnic, Pune (0141). (English)":
    "सर्व इंडिया श्री शिवाजी स्मारक समाज पॉलिटेक्निक, पुणे (0141) येथे संकुल विकास प्रशिक्षण कार्यक्रम.( इंग्रजी)",
  "Faculty Development Training Program at Government Polytechnic, Sambhajinagar (0019). (English)":
    "सरकारी पॉलिटेक्निक, संभाजीनगर (0019) येथे संकुल विकास प्रशिक्षण कार्यक्रम.( इंग्रजी)",
  "State level MSBTE Technical Paper Presentation Competition for 2023-24 for Diploma Student at Kasegaon Education Society's Rajarambapu Institute of Technology, Walva, Sangali. (English)":
    "केसगाव एज्युकेशन सोसायटीच्या राजारामभाऊ इन्स्टिट्यूट ऑफ टेक्नोलॉजी, वळवा, सांगली येथे 2023-24 च्या डिप्लोमा विद्यार्थ्यांसाठी राज्य स्तरावरील MSBTE तांत्रिक पत्र सादरीकरण स्पर्धा.( इंग्रजी)",
  "Regarding Exam Stationery Requirement for Summer 2024 and Winter 2024 Examination.. (English)":
    "उन्हाळा 2024 साठी परीक्षा कागदपत्रांची गरज संबंधित.( इंग्रजी)",
  "State level MSBTE Technical Paper Presentation Competition for 2023-24 for Diploma Student at Kalyani Charitable Trust's R.G.Sapkal Institute of Pharmacy, Nashik (1776)(English)":
    "राज्य स्तरावरील MSBTE तांत्रिक पत्र सादरीकरण स्पर्धा 2023-24 डिप्लोमा विद्यार्थ्यांसाठी कालाईनी चेरिटेबल ट्रस्टच्या R.G.सपकाळ इन्स्टिट्यूट ऑफ फार्मसी, नाशिक (1776)( इंग्रजी)",
  "State level MSBTE Technical Paper Presentation Competition for 2023-24 for Diploma Student at S. T. E. Society's Sou. Venutai Chavan Polytechnic, Pune(English)":
    "S. T. E. सोसायटीच्या सौ. येथे डिप्लोमा विद्यार्थ्यांसाठी 2023-24 साठी राज्यस्तरीय MSBTE तांत्रिक पेपर सादरीकरण स्पर्धा. वेणूताई चव्हाण पॉलिटेक्निक, पुणे (इंग्रजी)",
  "State level MSBTE Technical Paper Presentation Competition for 2023-24 for Diploma Student at Government Polytechnic, Nagpur (0026) (English)":
    "राज्य स्तरावरील MSBTE तांत्रिक पत्र सादरीकरण स्पर्धा 2023-24 च्या डिप्लोमा विद्यार्थ्यांसाठी सरकारी पॉलिटेक्निक, नागपूर (0026)( इंग्रजी)",
  "State level MSBTE Technical Paper Presentation Competition for 2023-24 for Diploma Student at Sudhakarrao Naik Institute of Pharmacy, Yavatmal (0285) (English)":
    "राज्य स्तरावरील MSBTE तांत्रिक पत्र सादरीकरण स्पर्धा 2023-24 डिप्लोमा विद्यार्थ्यांसाठी सुधाकरराव नाईक इन्स्टिट्यूट ऑफ फार्मसी, यवतमाळ (0285)( इंग्रजी)",
  "Winter 2023 Exam Result Declaration Circular.(Marathi)":
    "उन्हाळा 2023 परीक्षा निकाल जाहीर करण्याबाबत सर्कुलर.( मराठी)",

  "Winter 2023 Exam Schedule for Photocopy/ Verification and Reassessment.(Marathi)":
    "उन्हाळा 2023 परीक्षा वेळापत्रक फोटोकॉपी/ पडताळणी व पुनर्मूल्यमापन. ( मराठी)",
  "State Level MSBTE Technical Quiz Competition at Walchand College Of Engineering, Sangli for Mumbai and Pune region on 22/02/2024.(English)":
    "मुंबई आणि पुणे क्षेत्रासाठी 22/02/2024 रोजी वालचंद अभियांत्रिकी महाविद्यालयात MSBTE राज्यस्तरीय तांत्रिक क्विझ स्पर्धा.( इंग्रजी)",

  "State Level MSBTE Technical Quiz Competition at Pillai Hoc College of Engineering and Technology, Rasayani, Raigad for Mumbai and Pune in Computer on 20/02/2024.(English)":
    "मुंबई आणि पुणे क्षेत्रासाठी 20/02/2024 रोजी सन्निवान कॉलेज ऑफ अभियांत्रिकी आणि टेक्नॉलॉजी, नागपूरमध्ये MSBTE राज्यस्तरीय तांत्रिक क्विझ स्पर्धा.( इंग्रजी)",
  "State Level MSBTE Technical Quiz Competition at Bharti Vidyapeeth's Jawaharlal Nehru Institute of Technology, Pune for Mumbai and Pune in Mechanical on 21/02/2024.(English)":
    "मुंबई आणि पुणे क्षेत्रासाठी 21/02/2024 रोजी भारती विद्यापीठाच्या जवाहरलाल नेहरू टेक्नोलॉजी इन्स्टिट्यूट, पुणे येथे MSBTE राज्यस्तरीय तांत्रिक क्विझ स्पर्धा.( इंग्रजी)",
  "State Level MSBTE Technical Quiz Competition at MET'S Institute Of Pharmacy, Nashik for Nagpur and Chhatrapati Sambhajinagar in Pharmacy on 27/02/2024.(English)":
    "नागपूर आणि छत्रपती संभाजीनगर क्षेत्रासाठी 27/02/2024 रोजी MET's Institute Of Pharmacy, नाशिक येथे MSBTE राज्यस्तरीय तांत्रिक क्विझ स्पर्धा.( इंग्रजी)",
  "State Level MSBTE Technical Quiz Competition at Sanjivani Rural Education Societys Sanjivani K. B. P Polytechnic, Kopargaon for Nagpur and Chhatrapati Sambhajinagar in Mechanical on 21/02/2024.(English)":
    "नागपूर आणि छत्रपती संभाजीनगर क्षेत्रासाठी 21/02/2024 रोजी सन्निवान ग्रामीण शिक्षण संस्थेच्या संजिवनी के. बी. पी. पॉलिटेक्निक, कोपरगाव येथे MSBTE राज्यस्तरीय तांत्रिक क्विझ स्पर्धा.( इंग्रजी)",
  "State Level MSBTE Technical Quiz Competition at Jaikranti Shikshan Prasarak Mandal's Swami Vivekand Institute of Polytechnic, Latur for Nagpur and Chhatrapati Sambhajinagar region in Electrical on 26/02/2024.(English)":
    "नागपूर आणि छत्रपती संभाजीनगर क्षेत्रासाठी 26/02/2024 रोजी जयक्रांति शिक्षण प्रसारक मंडळाच्या स्वामी विवेकंद इन्स्टिट्यूट ऑफ पॉलिटेक्निक, लातूर येथे MSBTE राज्यस्तरीय तांत्रिक क्विझ स्पर्धा.( इंग्रजी)",
  "State Level MSBTE Technical Quiz Competition at Siddheshwar Women's Polytechnic, Solapur for Mumbai and Pune in Electronics and Tele-Communication on 23/02/2024.(English)":
    "मुंबई आणि पुणे क्षेत्रासाठी 23/02/2024 रोजी सिद्धेश्वर महिला पॉलिटेक्निक, सोलापूर येथे MSBTE राज्यस्तरीय तांत्रिक क्विझ स्पर्धा.( इंग्रजी)",
  "State Level MSBTE Technical Quiz Competition at Suryodaya College Of Engineering & Technology, Nagpur for Nagpur and Chhatrapati Sambhajinagar in Civil on 22/02/2024.(English)":
    "नागपूर आणि छत्रपती संभाजीनगर क्षेत्रासाठी 22/02/2024 रोजी सुर्योदय कॉलेज ऑफ इंजिनिअरिंग अँड टेक्नॉलॉजी, नागपूर येथे MSBTE राज्यस्तरीय तांत्रिक क्विझ स्पर्धा.( इंग्रजी)",
  "Summer 2024 Exam Form Filling Change in Schedule (Marathi)":
    "उन्हाळा 2024 परीक्षा फॉर्म भरण्याची वेळापत्रकातील बदल ( मराठी)",

  "MSBTE State level Quiz competition at Bharati Vidyapeeth Institute of Technology, Navi Mumbai 0027 for Mumbai & Pune region in Electrical on 26/02/2024.(English)":
    "MSBTE राज्य स्तरावरील क्विझ स्पर्धा भारती विद्यापीठ प्रौद्योगिकी इन्स्टिट्यूट, नवी मुंबई 0027 साठी मुंबई आणि पुणे क्षेत्रात इलेक्ट्रिकल विषयावर 26/02/2024.( इंग्रजी)",
  "State Level MSBTE Technical Quiz Competition at Marathwada Institute Of Technology, Aurangabad for Nagpur and Chhatrapati Sambhajinagar in Electronics and Tele-communication on 23/02/2023.(English)":
    "नागपूर आणि छत्रपती संभाजीनगर क्षेत्रासाठी 23/02/2024 रोजी महाराष्ट्र इन्स्टिट्यूट ऑफ टेक्नॉलॉजी, औरंगाबाद येथे MSBTE राज्यस्तरीय तांत्रिक क्विझ स्पर्धा.( इंग्रजी)",
  "State Level MSBTE Technical Quiz Competition at Gokhle Education Society's Sir Dr. M. S. Gosavi Polytechnic Institute, Nashik for Nagpur and Chhatrapati Sambhajinagar in Computer on 20/02/2024.(English)":
    "नागपूर आणि छत्रपती संभाजीनगर क्षेत्रासाठी 20/02/2024 रोजी गोखले एज्युकेशन सोसायटीच्या सर डॉ. एम. एस. गोसावी पॉलिटेक्निक इन्स्टिट्यूट, नाशिक येथे MSBTE राज्यस्तरीय तांत्रिक क्विझ स्पर्धा.( इंग्रजी)",

  "Appeal to send information for MSBTE Newsletter April-2024 Issue.(English)":
    "MSBTE वृत्तपत्र एप्रिल-2024 आवृत्तीसाठी माहिती पाठवण्याचे अपील.( इंग्रजी)",
  "Faculty Development Training Program at Zagdusing Charitable Trust's Thakur Polytechnic, Kandivali (0522)(English)":
    "सर्व इंडिया श्री शिवाजी स्मारक समाज पॉलिटेक्निक, कंदिवली (0522) येथे संकुल विकास प्रशिक्षण कार्यक्रम.( इंग्रजी)",
  "Faculty Development Training Program at S. T. E. Society's Sou. Venutai Chavan Polytechnic, Vadgaon (BK) (0040)(English)":
    "सर्व इंडिया S. T. E. सोसायटीच्या सौ. वेंदुताई चव्हाण पॉलिटेक्निक, वडगांव (BK) (0040) येथे संकुल विकास प्रशिक्षण कार्यक्रम.( इंग्रजी)",

  "Provisional List of MSBTE Scholarship Awardees for the year 2023-24(English)":
    "MSBTE शिष्यवृत्ती पुरस्कार प्राप्तकर्त्यांची तात्पुरती यादी 2023-24साठी( इंग्रजी)",
  "MSBTE Project competition 2024 (English)":
    "MSBTE प्रोजेक्ट स्पर्धा 2024 ( इंग्रजी)",
  "MSBTE Project Competition at Government Polytechnic, Miraj, Sangli Near Maji Sainaik Vasahat Miraj, Dist-Sangli for Pune on 19/03/2024.(English)":
    "एमएसबीटीई प्रोजेक्ट स्पर्धा, सरकारी पॉलिटेक्निक, मिरज, सांगली, माजी सैनाीक वसाहत मिरज, जिल्हा-सांगलीसाठी पुणे, १९/०३/२०२४.",
  "MSBTE Project Competition at J. D. College of Engineering & Management, Nagpur for Nagpur on 21/03/2024.(English)":
    "एमएसबीटीई प्रोजेक्ट स्पर्धा, जे. डी. कॉलेज ऑफ इंजीनियरिंग अँड मॅनेजमेंट, नागपूरसाठी नागपूर, २१/०३/२०२४.",
  "MSBTE Project Competition at Vidhyavardhini's B V Polytechnic, Vasai, Palghar for Mumbai on 18/03/2024. (English)":
    "एमएसबीटीई प्रोजेक्ट स्पर्धा, विद्यावर्धिनी बी. व्ही. पॉलिटेक्निक, वसई, पालघरसाठी मुंबई, १८/०३/२०२४.",
  "MSBTE Project Competition at Gramin Polytechnic, Nanded Gramin Technical And Mangement Campus, Nanded for Chhatrapati Sambhaji Nagar on 20/03/2024.(English)":
    "एमएसबीटीई प्रोजेक्ट स्पर्धा, ग्रामीण पॉलिटेक्निक, नांदेड ग्रामीण तांत्रिक आणि व्यवस्थापन कॅम्पस, नांदेडसाठी छत्रपती संभाजी नगर, २०/०३/२०२४.",
  "National Level offline Project, Start-up, Paper & Poster Competition for Polytechnic Students on 03/04/2024 and 04/04/2024 at Anantrao Pawar College of Engineering & Research, Parvati, Pune. (Marathi)":
    "पॉलिटेक्निक विद्यार्थ्यांच्या राष्ट्रीय स्तरावरील ऑफलाइन प्रोजेक्ट, स्टार्ट-अप, पेपर आणि पोस्टर स्पर्धा, ०३/०४/२०२४ व ०४/०४/२०२४, अनंतराव पवार कॉलेज ऑफ इंजीनियरिंग अँड रिसर्च, पर्वती, पुणे.",
  "A National Level Project Competition for Diploma Final Year Engineering Student Organized by Sanjay Ghodawat Institute Atigre (Kolhapur) on Wednesday 20 March 2024. (English)":
    "डिप्लोमा अंतिम वर्षाच्या अभियांत्रिकी विद्यार्थ्यांसाठी राष्ट्रीय स्तरावरील प्रोजेक्ट स्पर्धा, संजय घोडावत इन्स्टिट्यूट, अटिग्रे (कोल्हापूर) द्वारे आयोजित, बुधवार २० मार्च २०२४.",
  "Final list of MSBTE Scholarship Awardees for the year 2023-24. (English)":
    "एमएसबीटीई शिष्यवृत्ती पुरस्कार winners सूची २०२३-२४.",
  "DRAFT Norms for fixation of fees for unaided diploma institutions affiliated to Maharashtra State Board of Technical Education.(English)":
    "महाराष्ट्र राज्य तांत्रिक शिक्षण मंडळाशी संलग्न असलेल्या अनुदानित डिप्लोमा संस्थांसाठी शुल्क निश्चितीचे प्रारूप मानक.",
  "Special provision for filling Exam form for Summer 2024 Examination.(English)":
    "गरमी २०२४ परिक्षेसाठी परीक्षा फॉर्म भरण्यासाठी विशेष तरतूद.",

  "ISTE self Sponsored one faculty development program on Outcome based education and NBA perspectives on 22 to 26 April 2024 at Tulsiramji Gaikwad-Patil college of enginerring and technology, Nagpur.(English)":
    "आय.एस.टी.ई. स्वयं-स्पॉन्सर्ड एक शिक्षक विकास कार्यक्रम 'आउटकम आधारित शिक्षण आणि एनबीए दृष्टीकोन' २२ ते २६ एप्रिल २०२४, तुलसिरामजी गायकवाड-पाटील कॉलेज ऑफ इंजिनियरिंग अँड टेक्नॉलॉजी, नागपूर येथे.",
  "Notice for Submission of Proposal for Fees Fixation for A.Y. 2023-24 & A.Y. 2024-25 and list of documents and Norms.(English)":
    "अ. वर्ष 2023-24 व 2024-25 साठी शुल्क निश्चितीच्या प्रस्तावांची सादरीकरणाची सूचना आणि कागदपत्रे व मानके लांबीन.",
  "Extention of date for Submission of online proposals for approval of fees for the Academic Year 2023-2024 and 2024-2025.(English)":
    "अ. वर्ष 2023-2024 व 2024-2025 साठी शुल्क मान्यतेसाठी ऑनलाइन प्रस्ताव सादर करण्याची तारीख वाढविणे.",
  "National Pharmacy Welfare Association D Pharm Mega Job Fair 2024.(English)":
    "राष्ट्रीय फार्मसी कल्याण संघ D फार्म मेगा जॉब फेअर २०२४.",
  "A. Y. 2024-25 Academic Calendar.(English)":
    "अ. वर्ष २०२४-२५ शैक्षणिक कॅलेंडर.",
  "MSBTE Newsletter April-2024 Issue प्रसिध्द करण्याबाबत. (Marathi)":
    "एमएसबीटीई वृत्तपत्र एप्रिल-२०२४ आवृत्ती प्रकाशित करण्याबाबत.",
  "Extention of date for Submission of online proposals for approval of fees for the Academic Year 2024-2025. (English)":
    "अ. वर्ष २०२४-२५ साठी शुल्क मान्यतेसाठी ऑनलाइन प्रस्ताव सादर करण्याची तारीख वाढविणे.",
  "Two week ISTE Approved “Industrial training on Recent Technology” at Government Polytechnic Thane from 10/06/2024 to 22/06/2024.(English)":
    "दुई आठवड्यांची आय.एस.टी.ई. मान्यताप्राप्त 'अधिसूचना तंत्रज्ञान प्रशिक्षण कार्यक्रम' सरकारी पॉलिटेक्निक ठाणे येथे १०/०६/२०२४ ते २२/०६/२०२४.",
  "Appeal to send information for MSBTE Newsletter July-2024 Issue.(English)":
    "एमएसबीटीई वृत्तपत्र जुलै-२०२४ आवृत्तीसाठी माहिती पाठवण्याची अपील.",

  "Summer 2024 Exam Schedule for Photocopy/ Verification and Reassessment.(English)":
    "गर्मी २०२४ परिक्षेची तालिका - फोटोकॉपी / सत्यापन आणि पुनःमूल्यांकन.",
  "Summer 2024 Exam Result Declaration Circular(English)":
    "गर्मी २०२४ परिक्षेचा निकाल जाहीर करण्याची सर्कुलर.",
  "State level MSBTE Technical Paper Presentation Competition for 2024-25 for Diploma Student at Government Polytechnic, Ahmadnagar (0130)(English)":
    "एमएसबीटीई तांत्रिक पेपर प्रेझेंटेशन स्पर्धा २०२४-२५, डिप्लोमा विद्यार्थ्यांसाठी सरकारी पॉलिटेक्निक, अहमदनगर.",
  "MSBTE Technical Paper Presentation Competition for 2024-25 for Diploma Student at Government Polytechnic, Jalna (0120)(English)":
    "एमएसबीटीई तांत्रिक पेपर प्रेझेंटेशन स्पर्धा २०२४-२५, डिप्लोमा विद्यार्थ्यांसाठी सरकारी पॉलिटेक्निक, जालना.",
  "State level MSBTE Technical Paper Presentation Competition for 2024-25 for Diploma Student at Amrutvahini Polytechnic, Ahmadnagar (0080)(English)":
    "राज्यस्तरीय एमएसबीटीई तांत्रिक पेपर प्रेझेंटेशन स्पर्धा २०२४-२५, डिप्लोमा विद्यार्थ्यांसाठी अमृतवाहिनी पॉलिटेक्निक, अहमदनगर.",
  "State level MSBTE Technical Paper Presentation Competition for 2024-25 for Diploma Student at MSPMS Shri Shivaji Polytechnic Institute, Parbhani (1553)(English)":
    "राज्यस्तरीय एमएसबीटीई तांत्रिक पेपर प्रेझेंटेशन स्पर्धा २०२४-२५, डिप्लोमा विद्यार्थ्यांसाठी एमएसपीएमएस श्री शिवाजी पॉलिटेक्निक इन्स्टिट्यूट, परभणी.",
  "Appeal to send information for MSBTE Newsletter October-2024 issue. (English)":
    "एमएसबीटीई वृत्तपत्र ऑक्टोबर-२०२४ आवृत्तीसाठी माहिती पाठवण्याची अपील.",
  "Notice for Tender No.: MSBTE/TENDER/2024/07(English)":
    "टेंडर क्रमांक: एमएसबीटीई/टेंडर/२०२४/०७.",
  "Notice for Tender No.: MSBTE/TENDER/2024/06(English)":
    "टेंडर क्रमांक: एमएसबीटीई/टेंडर/२०२४/०६.",
  "MSBTE Technical Paper Presentation Competition for 2024-25 for Diploma Student at SES R. C. Patel Instt. of Pharmacy, Dhule (0620)(English)":
    "एमएसबीटीई तांत्रिक पेपर प्रेझेंटेशन स्पर्धा २०२४-२५, डिप्लोमा विद्यार्थ्यांसाठी एसईएस आर. सी. पटेल इन्स्टिट्यूट ऑफ फार्मसी, धुळे.",
  "MSBTE Technical Paper Presentation Competition for 2024-25 for Diploma Student at Mumbai Education Trust's Inst. of Pharmacy, Mumbai (0166) (English)":
    "एमएसबीटीई तांत्रिक पेपर प्रेझेंटेशन स्पर्धा २०२४-२५, डिप्लोमा विद्यार्थ्यांसाठी मुंबई एज्युकेशन ट्रस्ट इन्स्टिट्यूट ऑफ फार्मसी, मुंबई.",
  "MSBTE Technical Paper Presentation Competition for 2024-25 for Diploma Student at Government Polytechnic, Yavatmal (0135) (English)":
    "एमएसबीटीई तांत्रिक पेपर प्रेझेंटेशन स्पर्धा २०२४-२५, डिप्लोमा विद्यार्थ्यांसाठी सरकारी पॉलिटेक्निक, यवतमाळ.",
  "MSBTE Technical Paper Presentation Competition for 2024-25 for Diploma Student at Institute of Diploma in Pharmacy, Wardha (0262)(English)":
    "एमएसबीटीई तांत्रिक पेपर प्रेझेंटेशन स्पर्धा २०२४-२५, डिप्लोमा विद्यार्थ्यांसाठी डिप्लोमा इन फार्मसी इन्स्टिट्यूट, वर्धा.",
  "State Level Technical Quiz Competition for 2024-25 for Diploma Student at Vidyalankar Polytechnic, Wadala (0568)(English)":
    "राज्य स्तरावरील तांत्रिक क्विझ स्पर्धा २०२४-२५, डिप्लोमा विद्यार्थ्यांसाठी विद्यालंकार पॉलिटेक्निक, वडाला.",

  'Deputation of faculties for "Industrail Training Program at M/s. L & T Skills Trainers Academy, Mumbai for the faculties of EE Dept.of Affiliated Polytechnic" from" 23-9-2024 to 27-9-2024.(English)':
    'फॅकल्टींची नियुक्ती "उद्योग प्रशिक्षण कार्यक्रम एम/स. एल अँड टी स्किल्स ट्रेनर्स अकॅडमी, मुंबई" मधील विद्युत विभागाच्या फॅकल्टीसाठी २३-०९-२०२४ ते २७-०९-२०२४.',
  'Deputation of faculties for  "Industrail Training Program at M/s. L & T Skills Trainers Academy, Mumbai for the faculties of CO Dept.of Affiliated Polytechnic" from" 23-9-2024 to 27-9-2024.(English)':
    'फॅकल्टींची नियुक्ती "उद्योग प्रशिक्षण कार्यक्रम एम/स. एल अँड टी स्किल्स ट्रेनर्स अकॅडमी, मुंबई" मधील संगणक विभागाच्या फॅकल्टीसाठी २३-०९-२०२४ ते २७-०९-२०२४.',
  'Deputation of faculties for  "Industrail Training Program at M/s. L & T Skills Trainers Academy, Mumbai for the faculties of CE Dept.of Affiliated Polytechnic" from" 23-9-2024 to 27-9-2024.(English)':
    'फॅकल्टींची नियुक्ती "उद्योग प्रशिक्षण कार्यक्रम एम/स. एल अँड टी स्किल्स ट्रेनर्स अकॅडमी, मुंबई" मधील सिव्हिल विभागाच्या फॅकल्टीसाठी २३-०९-२०२४ ते २७-०९-२०२४.',

  'Deputation of faculties for "Industrail Training Program at M/s. L & T Skills Trainers Academy, Mumbai for the faculties of CE Dept.of Affiliated Polytechnic" from 23-9-2024 to 27-9-2024.(English)':
    'फॅकल्टींची नियुक्ती "उद्योग प्रशिक्षण कार्यक्रम एम/स. एल अँड टी स्किल्स ट्रेनर्स अकॅडमी, मुंबई" मधील विद्युत विभागाच्या फॅकल्टीसाठी २३-०९-२०२४ ते २७-०९-२०२४.',
  'Deputation of faculties for "Industrail Training Program at M/s. L & T Skills Trainers Academy, Mumbai for the faculties of CO Dept.of Affiliated Polytechnic" from 23-9-2024 to 27-9-2024.(English)':
    'फॅकल्टींची नियुक्ती "उद्योग प्रशिक्षण कार्यक्रम एम/स. एल अँड टी स्किल्स ट्रेनर्स अकॅडमी, मुंबई" मधील संगणक विभागाच्या फॅकल्टीसाठी २३-०९-२०२४ ते २७-०९-२०२४.',
  'Deputation of faculties for " Industrail Training Program at M/s. L & T Skills Trainers Academy, Mumbai for the faculties of EE Dept.of Affiliated Polytechnic" from 23-9-2024 to 27-9-2024.(English)':
    'फॅकल्टींची नियुक्ती "उद्योग प्रशिक्षण कार्यक्रम एम/स. एल अँड टी स्किल्स ट्रेनर्स अकॅडमी, मुंबई" मधील सिव्हिल विभागाच्या फॅकल्टीसाठी २३-०९-२०२४ ते २७-०९-२०२४.',
  "MSBTE Sponsored State Level Technical Paper Presentation Competition 2024-25 at Tatyasaheb Kore College of Pharmacy (Diploma), Warananagar.(English)":
    "एमएसबीटीई प्रायोजित राज्यस्तरीय तांत्रिक पेपर प्रेझेंटेशन स्पर्धा २०२४-२५, तात्यासाहेब कोरे कॉलेज ऑफ फार्मसी (डिप्लोमा), वाराणानगर.",
  "MSBTE Paper Presentation competition at G.P.Karad.(English)":
    "MSBTE पेपर सादरीकरण स्पर्धा जी.पी. कराड येथे. (इंग्रजी)",
  "State level MSBTE Technical Paper Presentation Competition for 2024-25 for Diploma Student at Tatyasaheb Kore College of Pharmacy, Kolhapur (0759).(English)":
    "राज्यस्तरीय MSBTE तांत्रिक पेपर सादरीकरण स्पर्धा 2024-25 साठी डिप्लोमा विद्यार्थ्यांसाठी, तात्यासाहेब कोरे कॉलेज ऑफ फार्मसी, कोल्हापूर (0759) येथे. (इंग्रजी)",
  'Deputation of faculties for " Industrial Training Program at M/s. Central Institute of Plastics Engineering and Technology, Aurangabad for the faculties of ME Dept. of Affiliated Polytechnic" from 7-10-2024 to 11-10-2024.(English)':
    'संबंधित तंत्रनिकेतनच्या यांत्रिकी विभागाच्या शिक्षकांसाठी "औद्योगिक प्रशिक्षण कार्यक्रम, मि.सेंट्रल इन्स्टिट्यूट ऑफ प्लॅस्टिक इंजिनिअरिंग आणि टेक्नॉलॉजी, औरंगाबाद" येथे 7-10-2024 ते 11-10-2024 पर्यंत पाठवणी. (इंग्रजी)',

  'Deputation of faculties for " Industrial Training Program at M/s. L&T (STC) Ltd., Pune for the faculties of EE Dept. of Affiliated Polytechnic" from 7-10-2024 to 11-10-2024.(English)':
    'संबंधित तंत्रनिकेतनच्या विद्युत विभागाच्या शिक्षकांसाठी "औद्योगिक प्रशिक्षण कार्यक्रम, मि. एल & टी (STC) लिमिटेड, पुणे" येथे 7-10-2024 ते 11-10-2024 पर्यंत पाठवणी. (इंग्रजी)',

  "Technical Paper Presentation at Agnel Polytechnic, Navi Mumbai.(English)":
    "तांत्रिक पेपर सादरीकरण अॅग्नेल पॉलिटेक्निक, नवी मुंबई येथे. (इंग्रजी)",

  MSBTE: "MSBTE",

  'Deputation of faculties for "Training Program at Zagdusing Charitable Trust\'s Thakur Polytechnic, Kandivali for the faculties of Co Dept.of Affiliated Polytechnic" from 26/2/2024 to 01/03/2024. (English)':
    'फॅकल्टींचे प्रतिनियुक्ती " झगडुसिंग चॅरिटेबल ट्रस्टचे ठाकूर पॉलिटेक्निक, कांदिवली येथे प्रशिक्षण कार्यक्रम जोडलेले पॉलिटेक्निकच्या सीओ विभागासाठी" 26/2/2024 ते 01/03/2024 दरम्यान.(इंग्रजी)',

  'Deputation of faculties for "Training Program at Tata Consulting Engineers Ltd., Navi Mumbai for the faculties of CE Dept.of Affiliated Polytechnic" from 26/02/2024 to 01/03/2024.(English)':
    'फॅकल्टींचे प्रतिनियुक्ती " टाटा कन्सल्टिंग इंजिनिअर्स लि., नवी मुंबई येथे प्रशिक्षण कार्यक्रम जोडलेले पॉलिटेक्निकच्या सीई विभागासाठी" 26/02/2024 ते 01/03/2024 दरम्यान.(इंग्रजी)',

  'Deputation of faculties for "Training Program at Tata Consulting Engineers Ltd., Navi Mumbai for the faculties of EE,ME Dept.of Affiliated Polytechnic" from 20/2/2024 to 24/2/2024.(English)':
    'फॅकल्टींचे प्रतिनियुक्ती " टाटा कन्सल्टिंग इंजिनिअर्स लि., नवी मुंबई येथे प्रशिक्षण कार्यक्रम जोडलेले पॉलिटेक्निकच्या ईई, एमई विभागासाठी" 20/2/2024 ते 24/2/2024 दरम्यान.(इंग्रजी)',

  'Deputation of faculties for "Training Program at Government Polytechnic, Amravati for the faculties of Affiliated Polytechnic" from 13-2-2024 to 17-2-2024. (English)':
    'फॅकल्टींचे प्रतिनियुक्ती " शासकीय पॉलिटेक्निक, अमरावती येथे प्रशिक्षण कार्यक्रम जोडलेले पॉलिटेक्निकसाठी" 13-2-2024 ते 17-2-2024 दरम्यान.(इंग्रजी)',

  'Deputation of faculties for "Training Program at Abdul Razzaq Kalsekar Polytechnic, New Pnavel for the faculties of CO Dept. of Affiliated Polytechnic" from 12-2-2024 to 16-2-2024. (English)':
    'फॅकल्टींचे प्रतिनियुक्ती " अब्दुल रझाक काळसेकर पॉलिटेक्निक, नवीन पनवेल येथे प्रशिक्षण कार्यक्रम जोडलेले पॉलिटेक्निकच्या सीओ विभागासाठी" 12-2-2024 ते 16-2-2024 दरम्यान.(इंग्रजी)',

  'Deputation of faculties for "Training Program at Tata Consulting Engineers Ltd., Navi Mumbai for the faculties of CE Dept. of Affiliated Polytechnic" from 12-2-2024 to 16-2-2024. (English)':
    'फॅकल्टींचे प्रतिनियुक्ती " टाटा कन्सल्टिंग इंजिनिअर्स लि., नवी मुंबई येथे प्रशिक्षण कार्यक्रम जोडलेले पॉलिटेक्निकच्या सीई विभागासाठी" 12-2-2024 ते 16-2-2024 दरम्यान.(इंग्रजी)',

  'Deputation of faculties for "Training Program at Sou. Sushila Danchand Ghodawat Charitable Trust\\\'s Sanjay Ghodawat Polytechnic, Hatkanangle for the faculties of CO Dept.of Affiliated Polytechnic" from 5-2-2024 to 9-2-2024.(English)':
    'फॅकल्टींचे प्रतिनियुक्ती "सौ. सुशीला दांचंद घोडावत चॅरिटेबल ट्रस्टच्या संजय घोडावत पॉलिटेक्निक, हातकणंगले येथे प्रशिक्षण कार्यक्रम जोडलेले पॉलिटेक्निकच्या सीओ विभागासाठी" 5-2-2024 ते 9-2-2024 दरम्यान.(इंग्रजी)',

  'Deputation of faculties for "Training Program at Government Polytechnic, Aurangabad for the faculties of Other Dept.of Affiliated Polytechnic" from 5-2-2024 to 9-2-2024..(English)':
    'फॅकल्टींचे प्रतिनियुक्ती " शासकीय पॉलिटेक्निक, औरंगाबाद येथे प्रशिक्षण कार्यक्रम जोडलेले पॉलिटेक्निकच्या इतर विभागांसाठी" 5-2-2024 ते 9-2-2024 दरम्यान.(इंग्रजी)',

  'Deputation of faculties for " Training Program at SES R.C Patel Instt. of Pharmacy, Shirpur, Dhule for the faculties of PH Dept. of Affiliated Polytechnic" from 5-2-2024 to 9-2-2024.(English)':
    'फॅकल्टींचे प्रतिनियुक्ती " एसईएस आर.सी. पाटील फार्मसी संस्थान, शिरपूर, धुळे येथे प्रशिक्षण कार्यक्रम जोडलेले पॉलिटेक्निकच्या पीएच विभागासाठी" 5-2-2024 ते 9-2-2024 दरम्यान.(इंग्रजी)',

  'Deputation of faculties for "Training Program at Government Polytechnic, Ambad for the faculties of ME Dept. of Affiliated Polytechnic".(English)(':
    'फॅकल्टींचे प्रतिनियुक्ती " शासकीय पॉलिटेक्निक, अंबड येथे प्रशिक्षण कार्यक्रम जोडलेले पॉलिटेक्निकच्या एमई विभागासाठी".(इंग्रजी)',

  "Deputation of faculties for \"Training Program at All India Shri Shivaji Memorial Society's Polytechnic, Pune for the faculties of CO Dept. of Affiliated Polytechnic.(Marathi)":
    'फॅकल्टींचे प्रतिनियुक्ती " ऑल इंडिया श्री शिवाजी मेमोरियल सोसायटीचे पॉलिटेक्निक, पुणे येथे प्रशिक्षण कार्यक्रम जोडलेले पॉलिटेक्निकच्या सीओ विभागासाठी.(मराठी)',

  "Deputation of faculties for \"Training Program at Kasegaon Education Society's Rajarambapu Institute of Technology, Walva for the faculties of CE Dept.of Affiliated Polytechnic.(Marathi)":
    'फॅकल्टींचे प्रतिनियुक्ती " कासेगाव एज्युकेशन सोसायटीचे राजारामबापू इन्स्टिट्यूट ऑफ टेक्नॉलॉजी, वाळवा येथे प्रशिक्षण कार्यक्रम जोडलेले पॉलिटेक्निकच्या सीई विभागासाठी.(मराठी)',

  "Enrollment and Exam form schedule for Newly Admitted students for A.Y. 2023-24.( Marathi)":
    "नवीन प्रवेशित विद्यार्थ्यांसाठी ए.वाय. 2023-24 साठी नोंदणी आणि परीक्षा फॉर्म वेळापत्रक.(मराठी)",

  "MSBTE Technical Paper Presentation Competition 2022-23 (online) for RBTE, Nagpur Region on 21/04/2023 at Government polytechnic, Arvi (0132) in Electronics and Tele-communication Engineering branch.(English)":
    "एमएसबीटीई तांत्रिक पेपर सादरीकरण स्पर्धा 2022-23 (ऑनलाइन) आरबीटीई, नागपूर विभागासाठी 21/04/2023 रोजी शासकीय पॉलिटेक्निक, अर्वी (0132) येथे इलेक्ट्रॉनिक्स आणि दूरसंचार अभियांत्रिकी शाखेत.(इंग्रजी)",

  "Technical Paper Presentation Competition at Government Polytechnic Arvi.(English)":
    "शासकीय पॉलिटेक्निक अर्वी येथे तांत्रिक पेपर सादरीकरण स्पर्धा.(इंग्रजी)",

  "MSBTE State Level Technical Paper Presentation Competition (Online) for Mechanical Engg. Student (Aruangabad Region) on 18th April, 2023 organised by Shri .H.H.J.B.Polytechnic, Chandwad, Nashik.(English)":
    "एमएसबीटीई राज्यस्तरीय तांत्रिक पेपर सादरीकरण स्पर्धा (ऑनलाइन) मेकॅनिकल अभियांत्रिकी विद्यार्थ्यांसाठी (औरंगाबाद विभाग) 18 एप्रिल, 2023 रोजी श्री.एच.एच.जे.बी.पॉलिटेक्निक, चांदवड, नाशिक आयोजित.(इंग्रजी)",

  "MSBTE Sponsored State Level Technical Paper Presentation Competition (ONLINE) For Electronics Engineering Student Organised At Vidyalankar Polytechnic, Wadala, Mumbai On 21st April, 2023.(English)":
    "एमएसबीटीई प्रायोजित राज्यस्तरीय तांत्रिक पेपर सादरीकरण स्पर्धा (ऑनलाइन) इलेक्ट्रॉनिक्स अभियांत्रिकी विद्यार्थ्यांसाठी विद्यानकर पॉलिटेक्निक, वडाळा, मुंबई येथे 21 एप्रिल, 2023 रोजी आयोजित.(इंग्रजी)",

  "Final List of MSBTE Scholarship Awardees for the year 2022-23":
    "2022-23 या वर्षासाठी एमएसबीटीई शिष्यवृत्ती विजेत्यांची अंतिम यादी",

  'MSBTE Approved (Self Financed) Two Days Faculty Development Programme On " Recent Trends In Electric Vehicles And Artificial Intelligence " from 01st Mar 2023 to 02nd Mar 2023 Organized BY Electrical Engineering Department Sandip Foundation’s Sandip Polytechnic, Nashik.(English)pdf':
    'एमएसबीटीई मान्यताप्राप्त (स्वयं वित्तीय) दोन दिवसांचे शिक्षक विकास कार्यक्रम " इलेक्ट्रिक वाहने आणि कृत्रिम बुद्धिमत्तेतील अलीकडील ट्रेंड्स " 01 मार्च 2023 ते 02 मार्च 2023 दरम्यान इलेक्ट्रिकल अभियांत्रिकी विभाग संदीप फाऊंडेशनच्या संदीप पॉलिटेक्निक, नाशिक द्वारे आयोजित.(इंग्रजी)pdf',

  "Summer 2022 Re-Examination Result Declaration.":
    "समर 2022 पुनर्परीक्षेचा निकाल जाहीर.",

  'State Level Online Poster Competition on "Vacation Vibes" (Inspiration & Design Board) Organized and Conducted by Government Polytechnic Aurangabad.':
    'राज्यस्तरीय ऑनलाइन पोस्टर स्पर्धा " व्हेकेशन वाइब्स" (इन्स्पिरेशन अँड डिझाइन बोर्ड) शासकीय पॉलिटेक्निक औरंगाबाद द्वारे आयोजित आणि संचालित.',

  'State Level Project and Paper Presentation Competition "अन्वेषण-2K22" on 28th and 29th April, 2022 organized by Amrutvahini Polytechnic, Sangamner, Ahmednagar.pdf':
    'राज्यस्तरीय प्रकल्प आणि पेपर सादरीकरण स्पर्धा "अन्वेषण-2K22" 28 आणि 29 एप्रिल, 2022 रोजी अमृतवाहिनी पॉलिटेक्निक, संगमनेर, अहमदनगर आयोजित.pdf',

  "Project Competition ''TECHNOPRO'' for polytechnic Students (Final year) on 07th May, 2022 organized by Thakur Polytechnic, Mumbai.":
    "प्रकल्प स्पर्धा ''TECHNOPRO'' पॉलिटेक्निक विद्यार्थ्यांसाठी (अंतिम वर्ष) 07 मे, 2022 रोजी ठाकूर पॉलिटेक्निक, मुंबई द्वारे आयोजित.",

  "Equivalent for all subjects of 'G' Scheme (3rd & 4th Semester) in 'I' Scheme - AICTE Diversified Courses.":
    "G योजना (तिसरा आणि चौथा सेमिस्टर) मधील सर्व विषयांसाठी I योजना - AICTE विविधीकृत अभ्यासक्रमांमध्ये समतुल्य.",

  'Regarding "Spoken Tutorial Software Training Program, IIT Bombay".( English)':
    '"स्पोकन ट्यूटोरियल सॉफ्टवेअर प्रशिक्षण कार्यक्रम, IIT बॉम्बे" संबंधित.(इंग्रजी)',

  'Deputation of faculties for an "Industrial Training Program at M/s. L & T Skills Trainers Academy,Mumbai for the faculties of Mechanical, Computer, Information Technology, Electronics Dept. of Affiliated Polytechnic" from 2-9-2023 to 8-9-2023.':
    'फॅकल्टींचे प्रतिनियुक्ती " औद्योगिक प्रशिक्षण कार्यक्रम मॅसर्स. एल अँड टी स्किल्स ट्रेनर्स अकॅडमी, मुंबई येथे जोडलेले पॉलिटेक्निकच्या मेकॅनिकल, कॉम्प्युटर, माहिती तंत्रज्ञान, इलेक्ट्रॉनिक्स विभागासाठी" 2-9-2023 ते 8-9-2023 दरम्यान.',

  'MSBTE Sponsored One week faculty Development Training Program on "Machine Learning and It\'s Application" from 15th to 19th May, 2023 organised at Thakur Polytechnic, Mumbai.(English)':
    'एमएसबीटीई प्रायोजित एक आठवड्याचा शिक्षक विकास प्रशिक्षण कार्यक्रम "मशीन लर्निंग आणि त्याचे अनुप्रयोग" 15 ते 19 मे 2023 दरम्यान ठाकूर पॉलिटेक्निक, मुंबई येथे आयोजित.(इंग्रजी)',

  'MSBTE Sponsored Faculty Development Training Program on "NBA Accreditation and Reassessment Process” from 27th to 29th April, 2023 organised at Government Polytechnic Amravati.(English)':
    'एमएसबीटीई प्रायोजित शिक्षक विकास प्रशिक्षण कार्यक्रम "एनबीए मान्यता आणि पुनर्मूल्यांकन प्रक्रिया" 27 ते 29 एप्रिल 2023 दरम्यान शासकीय पॉलिटेक्निक अमरावती येथे आयोजित.(इंग्रजी)',

  'MSBTE Sponsored Three Days faculty development training program on "NBA Accreditation Process and Evaluation" from 25th to 27th April, 2023 organised at Government Polytechnic, Mumbai.(English)':
    'एमएसबीटीई प्रायोजित तीन दिवसांचे शिक्षक विकास प्रशिक्षण कार्यक्रम "एनबीए मान्यता प्रक्रिया आणि मूल्यांकन" 25 ते 27 एप्रिल 2023 दरम्यान शासकीय पॉलिटेक्निक, मुंबई येथे आयोजित.(इंग्रजी)',

  'MSBTE Sponsored Faculty Development Training Program on NBA Accreditation Process" from 25th to 27th April, 2023 organised at Government Polytechnic, Nashik.(English)':
    'एमएसबीटीई प्रायोजित शिक्षक विकास प्रशिक्षण कार्यक्रम एनबीए मान्यता प्रक्रिया" 25 ते 27 एप्रिल 2023 दरम्यान शासकीय पॉलिटेक्निक, नाशिक येथे आयोजित.(इंग्रजी)',

  'MSBTE Sponsored MSBTE Faculty Development Training Program on "Recent trends in Industry 4.0" from 17th to 21st April, 2023 organised at K. K. Wagh Polytechnic, Nashik.(English)':
    'एमएसबीटीई प्रायोजित एमएसबीटीई शिक्षक विकास प्रशिक्षण कार्यक्रम "उद्योग 4.0 मधील अलीकडील ट्रेंड" 17 ते 21 एप्रिल 2023 दरम्यान के. के. वाघ पॉलिटेक्निक, नाशिक येथे आयोजित.(इंग्रजी)',

  'MSBTE One week faculty development training program on "Electric and Hydrogen Vehiches" from 24th to 28th April, 2023 at AISSMS Polytechnic, Pune.(English)':
    'एमएसबीटीई एक आठवड्याचा शिक्षक विकास प्रशिक्षण कार्यक्रम "इलेक्ट्रिक आणि हायड्रोजन वाहने" 24 ते 28 एप्रिल 2023 दरम्यान एआयएसएसएमएस पॉलिटेक्निक, पुणे येथे आयोजित.(इंग्रजी)',

  'One week faculty development training program on "Python with Django Full stack developer boot camp" at Amrutvahini Polytechnic, Sangamner from 24th to 28th April, 2023':
    'एक आठवड्याचा शिक्षक विकास प्रशिक्षण कार्यक्रम "पायथन विथ डीजांगो फुल स्टॅक डेव्हलपर बूट कॅम्प" अमृतवाहिनी पॉलिटेक्निक, संगमनेर येथे 24 ते 28 एप्रिल 2023 दरम्यान आयोजित.',

  'Deputation of faculties for an "Industrial Training Program at M/s. KEC International Ltd., Nagpur for the faculties of ME/ EE Engg. Dept. of Affiliated Polytechnics" from 10th to 14th April, 2023.(English)':
    'फॅकल्टींचे प्रतिनियुक्ती " औद्योगिक प्रशिक्षण कार्यक्रम मॅसर्स. केईसी इंटरनॅशनल लि., नागपूर येथे जोडलेले पॉलिटेक्निकच्या एमई/ईई अभियांत्रिकी विभागासाठी" 10 ते 14 एप्रिल 2023 दरम्यान.(इंग्रजी)',

  'MSBTE One Week Faculty Development Training Program on "ROBOTICS & AI" organised by Government Polytechnic, Arvi from 17th to 21st Arpil, 2023.(English)':
    'एमएसबीटीई एक आठवड्याचा शिक्षक विकास प्रशिक्षण कार्यक्रम "रोबोटिक्स आणि एआय" शासकीय पॉलिटेक्निक, अर्वी येथे 17 ते 21 एप्रिल 2023 दरम्यान आयोजित.(इंग्रजी)',

  "Deputation of Faculties Names for an Industrial Training program at L&T(STC) Ltd. for the faculties from 3-4-2023 to 7-4-2023(English)":
    'फॅकल्टींच्या नावे प्रतिनियुक्ती " औद्योगिक प्रशिक्षण कार्यक्रम एल अँड टी (एसटीसी) लि." 3-4-2023 ते 7-4-2023 दरम्यान.(इंग्रजी)',

  'MSBTE One Week Faculty Development Training Program on " Recent Trends In Wireless And Optical Fiber Communication" from 10th April 2023 to 15th April 2023 organised at Vivekanand Education Society\'s Polytechnic, Chembur, Mumbai.(English)':
    'एमएसबीटीई एक आठवड्याचा शिक्षक विकास प्रशिक्षण कार्यक्रम " वायरलेस आणि ऑप्टिकल फायबर कम्युनिकेशन मधील अलीकडील ट्रेंड" 10 एप्रिल 2023 ते 15 एप्रिल 2023 दरम्यान विद्यानकर शिक्षण सोसायटीच्या पॉलिटेक्निक, चेंबूर, मुंबई येथे आयोजित.(इंग्रजी)',

  "Deputation of Faculties Names for an Industrial Training program at M/s. FESTO India Pvt. Ltd.,Mumbai for the faculties from Mechanical & Production Engineering Dept. from 27-03-2023 to 31-03-2023.(English)":
    'फॅकल्टींच्या नावे प्रतिनियुक्ती " औद्योगिक प्रशिक्षण कार्यक्रम मॅसर्स. फेस्टो इंडिया प्रा. लि., मुंबई येथे मेकॅनिकल आणि उत्पादन अभियांत्रिकी विभागाच्या फॅकल्टींसाठी" 27-03-2023 ते 31-03-2023 दरम्यान.(इंग्रजी)',

  'MSBTE One Week Faculty Development Training Program on" Outcome Based Education & NBA Perspective" organised at Government Polytechnic, Nagpur from 17th to 21st April, 2023.(English)':
    'एमएसबीटीई एक आठवड्याचा शिक्षक विकास प्रशिक्षण कार्यक्रम " आउटकम बेस्ड एज्युकेशन आणि एनबीए दृष्टिकोन" शासकीय पॉलिटेक्निक, नागपूर येथे 17 ते 21 एप्रिल 2023 दरम्यान आयोजित.(इंग्रजी)',

  'MSBTE Faculty Development Training Program (FDTP) on "NBA Accreditation Process" from 24th to 26th March, 2023 at Government Polytechnic, Aurangabad.(1644)(English)':
    'एमएसबीटीई शिक्षक विकास प्रशिक्षण कार्यक्रम (एफडीटीपी) "एनबीए मान्यता प्रक्रिया" 24 ते 26 मार्च 2023 दरम्यान शासकीय पॉलिटेक्निक, औरंगाबाद येथे.(1644)(इंग्रजी)',

  'Three Days Faculty Development Training Program on "Recent Advance Manufacturing Trends" organized by DKTE Societys Yashwantrao Chavan Polytechnic, Kolhapur from 15th to 17th March, 2023.(1644)(English)':
    'तीन दिवसांचे शिक्षक विकास प्रशिक्षण कार्यक्रम "अलीकडील प्रगत उत्पादन ट्रेंड्स" डीकेटीई सोसायटीच्या यशवंतराव चव्हाण पॉलिटेक्निक, कोल्हापूर येथे 15 ते 17 मार्च 2023 दरम्यान आयोजित.(1644)(इंग्रजी)',

  'Three Days Short Term Training Program (STTP) on "Electric Vehicles" organized by DKTE Societys Yashwantrao Chavan Polytechnic, Kolhapur from 23rd to 25th March, 2023.(4,334)(English)':
    'तीन दिवसांचे लघुकाळ प्रशिक्षण कार्यक्रम (एसटीटीपी) "इलेक्ट्रिक वाहने" डीकेटीई सोसायटीच्या यशवंतराव चव्हाण पॉलिटेक्निक, कोल्हापूर येथे 23 ते 25 मार्च 2023 दरम्यान आयोजित.(4,334)(इंग्रजी)',

  'Three Days Short Term Training Program (STTP) on "Introduction to IoT and Implementation using Raspberry Pi in Python Language" organized by DKTE Societys Yashwantrao Chavan Polytechnic, Kolhapur from 16th to 18th March, 2023.(230)(English)':
    'तीन दिवसांचे लघुकाळ प्रशिक्षण कार्यक्रम (एसटीटीपी) "आयओटी ची ओळख आणि रास्पबेरी पायमध्ये पायथन भाषेचा वापर करून अंमलबजावणी" डीकेटीई सोसायटीच्या यशवंतराव चव्हाण पॉलिटेक्निक, कोल्हापूर येथे 16 ते 18 मार्च 2023 दरम्यान आयोजित.(230)(इंग्रजी)',

  'Two days Faculty Development Training program on "CNC Programming and Operating(For non teaching faculty)" from 16th to 17th February, 2023 at Sandip Polytechnic, Nashik.':
    'दोन दिवसांचे शिक्षक विकास प्रशिक्षण कार्यक्रम "सीएनसी प्रोग्रामिंग आणि ऑपरेटिंग (गैर शिक्षण फॅकल्टीसाठी)" 16 ते 17 फेब्रुवारी 2023 दरम्यान संदीप पॉलिटेक्निक, नाशिक येथे आयोजित.',

  "One Week faculty Development Training program (Online mode) on “ Advances in Internet of Things and its Applications” from 21st to 25th November, 2022 organized by Government Polytechnic, Awasari (Khurd), Pune.":
    'एक आठवड्याचा शिक्षक विकास प्रशिक्षण कार्यक्रम (ऑनलाइन मोड) " इंटरनेट ऑफ थिंग्ज आणि त्याच्या अनुप्रयोगांतील प्रगती" 21 ते 25 नोव्हेंबर 2022 दरम्यान शासकीय पॉलिटेक्निक, अवसरी (खुर्द), पुणे येथे आयोजित.',

  'State Level One week Faculty Development Training Program on "ADVANCEMENTS IN ROBOTICS" organised by V.E.S. Polytechnic, Chembur, Mumbai from 20.06.2022 to 24.06.2022.':
    'राज्यस्तरीय एक आठवड्याचा शिक्षक विकास प्रशिक्षण कार्यक्रम "रोबोटिक्समधील प्रगती" 20.06.2022 ते 24.06.2022 दरम्यान विद्यानकर पॉलिटेक्निक, चेंबूर, मुंबई येथे आयोजित.',
};

export default HomePageTranslation;
