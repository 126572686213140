import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const s3Path = process.env.REACT_APP_S3_PATH;
function SliderFooter() {
  return (
    <section className="sliderwidth">
      <div className="slider container">
        <div id="logos-home-page">
          <div className="slider">
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlay
              autoPlaySpeed={4000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 8,
                  partialVisibilityGutter: 40,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 700,
                  },
                  items: 6,
                  partialVisibilityGutter: 30,
                },
                mobile: {
                  breakpoint: {
                    max: 600,
                    min: 414,
                  },
                  items: 3,
                  partialVisibilityGutter: 20,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={2}
              swipeable
            >
              <div>
                <a
                  href="https://www.maharashtra.gov.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={s3Path + "/Images1/mahasahan.png"}
                    alt="mahasahan"
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://htedu.maharashtra.gov.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={s3Path + "/Images1/h&tecnicaleducation.png"}
                    alt="h&t"
                  />
                </a>
              </div>
              <div>
                <a
                  href="http://www.dtemaharashtra.gov.in/index.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={s3Path + "/Images1/carousal.png"} alt="dte" />
                </a>
              </div>
              <div>
                <a href="https://mhrd.gov.in/" target="_blank" rel="noreferrer">
                  <img src={s3Path + "/Images1/image.png"} alt="mhrd" />
                </a>
              </div>
              <div>
                <a
                  href="https://accounts.google.com/v3/signin/identifier?dsh=S-1526263976%3A1678091645316360&amp%3Bhd=msbte.com&amp%3Bsacu=1&amp%3Bservice=mail&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&flowName=GlifWebSignIn&flowEntry=AddSession#identifier"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={s3Path + "/Images1/email.jpg"} alt="google" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.aicte-india.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={s3Path + "/Images1/aicte.png"} alt="aicte" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.coa.gov.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={s3Path + "/Images1/COA.jpeg"} alt="COA" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.pci.nic.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={s3Path + "/Images1/PCI.png"} alt="PCI" />
                </a>
              </div>

              <div>
                <a
                  href="http://goidirectory.nic.in/index.php"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={s3Path + "/Images1/webdirectory.png"} alt="goi" />
                </a>
              </div>
              <div>
                <a
                  href="http://apprentice-engineer.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={s3Path + "/Images1/boat.png"} alt="apprentice" />
                </a>
              </div>
              <div>
                <a
                  href="https://mahaeschol.maharashtra.gov.in/MahaEschol/Scholarships/Account/Login.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={s3Path + "/Images1/scholership.png"}
                    alt="mahaeschol"
                  />
                </a>
              </div>
              <div>
                <a href="https://nptel.ac.in/" target="_blank" rel="noreferrer">
                  <img src={s3Path + "/Images1/Nptel.png"} alt="nptel" />
                </a>
              </div>
              <div>
                <a
                  href="https://infyspringboard.onwingspan.com/web/en/login"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={s3Path + "/Images1/springboard.png"}
                    alt="springboard"
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://www.vlab.co.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={s3Path + "/Images1/virtuallab.png"} alt="vlab" />
                </a>
              </div>
              <div>
                <a
                  href="https://swayam.gov.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={s3Path + "/Images1/swayam.png"} alt="swayam" />
                </a>
              </div>
              <div>
                <a href="https://msbteffc.in/" target="_blank" rel="noreferrer">
                  <img src={s3Path + "/Images1/fcc.png"} alt="FCC" />
                </a>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}
export default SliderFooter;
